import React, { Component } from 'react';
import './Approvals.css';
import '../App.css';
import Header from "../Header/Header";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Jumbotron, Toast } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { MdRefresh, MdClose } from "react-icons/md";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import axios from "axios";
import { Redirect } from "react-router-dom";
import ThemeService from '../../services/ThemeService';
import Utils from '../../services/Utils';
import EmptyDocsPanel from '../EmptyDocsPanel/EmptyDocsPanel';
import SearchBar from './SearchBar/SearchBar';
import SigningProcessModal from '../Modals/SigningProcessModal';
import LoadingCircle from '../LoadingCircle';
import documentRenderService from '../../services/documentRender/documentRenderService';
import { getVidCloudMgmtUrl, getVidCloudPrivUrl } from '../../services/environment/environmentService';
import TagsList from './TagsList/TagsList';
import CustomToast from './CustomToast/CustomToast';
import ApprovalPreview from '../ApprovalPreview/ApprovalPreview';

import warning from '../../assets/icons/warning-jumbotron.png';
import { connect } from 'react-redux';
import ApprovalsActionTypes from '../../redux/approvals/approvals.types';

const ipLocation = require("iplocation");
const publicIp = require('public-ip');
class Approvals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
            language: localStorage.getItem('i18nextLng'),
            userToken: sessionStorage.getItem('userToken'),
            urlReturn: sessionStorage.getItem('urlReturn'),
            approvalsSelectedGUIDString: sessionStorage.getItem('approvalsSelectedGUIDString'),
            approvalsSelectedGUID: [],
            viewApproval: false,
            loading: true,
            sessionExpired: false,
            redirect: false,
            time: 0,
            selectedApproval: {},
            renderedApproval: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
            rejectedDoc: false,
            signedDoc: false,
            redirecting: false,
            tokenExpired: false,
            docsSelected: [],
            disable: false,
            loadingApprovalProcess: false,
            showError: false,
            errorData: '',
            errorType: '',
            docsApproved: 0,
            userIsLoggedByAuthCerts: false,
            showJumbotron: true,
            documentInfo: {},
            documentSigners: [],
            documentAttatchmentsList: [],
            docsAttachedRendered: [],
            clientIp: '',
            ipData: '',
            currentPageTable: 1,
            currentSizePerPageTable: 10,
            currentSort: {
                order: "asc",
                field: "FileName"
            },
            loadingMorePages: false, 
            previouslyRejectedDoc: false,
            showAdvancedSearch: true,
            nextDocument: {},
        };
        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
        this.viewApprovalHandler = this.viewApprovalHandler.bind(this)
        this.showRejectedDocHandler = this.showRejectedDocHandler.bind(this)
        this.showApprovedDocHandler = this.showApprovedDocHandler.bind(this)
        this.seeDocument = this.seeDocument.bind(this)
        this.textFormatter = this.textFormatter.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onSizePerPageChange = this.onSizePerPageChange.bind(this)
        this.renderDocument = this.renderDocument.bind(this)
        this.shouldHideTagColumn = this.shouldHideTagColumn.bind(this)
        this.handlePreviouslyRejectedDocError = this.handlePreviouslyRejectedDocError.bind(this)
        this.handleSessionExpiredError = this.handleSessionExpiredError.bind(this);
        this.handleGenericError = this.handleGenericError.bind(this);
        this.seeNextApproval = this.seeNextApproval.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.getPendingApprovals = this.getPendingApprovals.bind(this);
    }


    viewApprovalHandler() {
        this.clearVariablesToRenderListProperly();
        this.setPendingSignaturesSavedInSession();
        this.setState({
            viewApproval: false
        });
    }

    handleReturnToDocumentsList() {
        const { selectedApproval } = this.state;
        let docGuid = selectedApproval.DocGuid;
        const { dispatch } = this.props;

        dispatch({
            type: ApprovalsActionTypes.DELETE_APPROVAL_FROM_LIST,
            payload: docGuid,
        });
        this.clearVariablesToRenderListProperly();
        this.setPendingSignaturesSavedInSession();
    }

    showRejectedDocHandler() {
        this.handleReturnToDocumentsList();
        this.setState({
            rejectedDoc: true,
            viewApproval: false
        });
    }

    showApprovedDocHandler() {
        this.handleReturnToDocumentsList();
        this.setState({
            signedDoc: true,
            viewApproval: false
        });
    }

    doLogOut() {
        var _this = this;
        const { userToken, urlReturn } = this.state;
        if (typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== "") {
            _this.setState({
                redirecting: true
            });
            setTimeout(() => {
                window.location.href = atob(urlReturn);
            }, 3000);
        }
        else {
            this.setState({ redirect: true })
        }
        sessionStorage.clear();
    }

    startTimer() {
        this.timer = setInterval(() => this.setState({
            time: this.state.time + 1
        }), 60000);
    }

    stopTimer() {
        clearInterval(this.timer)
    }

    isSelectAllHidden = () => {
        const { pendingApprovalsBackup } = this.props;

        if (pendingApprovalsBackup === [])
            return true;
        
        return false;
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            return this.selectDocument(row);
        }

        this.unselectDocuments([row]);
    };

    handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            rows.map((row, i) => this.selectDocument(row, i === (rows.length - 1))
            );
            return;
        }

        this.unselectDocuments(rows);
    }

    selectDocument(row, isLastDocumentSelected=false) {
        const { docsSelected, approvalsSelectedGUID, currentSizePerPageTable } = this.state;
        let newDocumentState = {};

        if (approvalsSelectedGUID.indexOf(row.DocGuid) === -1) {
            approvalsSelectedGUID.push(row.DocGuid);
        }
        if (docsSelected.find(doc => doc.DocGuid === row.DocGuid) === undefined) {
            docsSelected.push(row);
        }

        if (approvalsSelectedGUID.length > 0) {
            newDocumentState.currentPageTable = this.getCurrentTablePage(this.props.pendingApprovals, row.DocGuid, currentSizePerPageTable, isLastDocumentSelected, docsSelected);
        }

        newDocumentState.approvalsSelectedGUID = approvalsSelectedGUID;
        newDocumentState.docsSelected = docsSelected;

        sessionStorage.setItem('approvalsSelectedGUIDString', approvalsSelectedGUID.toString());
        this.setState(newDocumentState);

        return true;
    }

    getCurrentTablePage(pendingApprovals, docGuid, currentSizePerPageTable, isLastDocumentSelected, docsSelected) {
        // Sort table by FileName asc
        const lastAddedDocGuidIndex = pendingApprovals.findIndex(doc => doc.DocGuid === docGuid);

        let firstAddedDocGuidIndex;
        // This only runs when I select multiple docs and this is the last one
        if (isLastDocumentSelected) {
            const firstDocSelected = docsSelected[0];
            firstAddedDocGuidIndex = pendingApprovals.findIndex(doc => doc.DocGuid === firstDocSelected.DocGuid);
        }

        const docIndexToBeFound = isLastDocumentSelected ? firstAddedDocGuidIndex : lastAddedDocGuidIndex;

        const module = docIndexToBeFound % currentSizePerPageTable;
        const page = Math.ceil(docIndexToBeFound / currentSizePerPageTable);

        return module === 0 ? page + 1 : page;
    }

    unselectDocuments(rows) {
        let { docsSelected, approvalsSelectedGUID } = this.state;

        rows.forEach(function (row) {
            docsSelected = docsSelected.filter(function (doc) {
                return doc.DocGuid !== row.DocGuid;
            });
            approvalsSelectedGUID = approvalsSelectedGUID.filter(function (docGuid) {
                return docGuid !== row.DocGuid;
            });
        })

        sessionStorage.setItem('approvalsSelectedGUIDString', approvalsSelectedGUID.toString());
        this.setState({ approvalsSelectedGUID: approvalsSelectedGUID, docsSelected: docsSelected });
    }

    loadViDMobileUserCertificateList() {
        const _this = this;

        this.loadUserData()
            .then(res => {
                if (res) {
                    _this.setState({ userData: res });
                } else {
                    console.log('no user data')
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    focusInput(input) {
      /**The stimeout is necesary to wait until the DOM is loaded */
      if(input) setTimeout(function(){ input.focus(); }, 1);
    }

    loadUserData() {
        this.setState({ loading: true });
        const _this = this;
        const { oauthTokenB64 } = this.state;


        var config = {
            headers: {
                'Authorization': 'Bearer ' + oauthTokenB64
            }
        };
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    _this.handleGenericError(error);
                    reject(error);
                });
        });
    }

    handleSessionExpiredError(status) {
        if (status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            this.setState({ sessionExpired: true });
            this.startTimer();
        }
    }

    handleGenericError(error) {
        this.handleSessionExpiredError(error.response.status);

        if (error.response.status !== 401) {
            this.setState({
                errorType: error.response.status,
                errorData: error.response.data,
                showError: true
            });
        }
    }

    getViDMobileUserGUIDFromOauthToken() {
        const { oauthTokenB64 } = this.state;
        var tokenParts = oauthTokenB64.split(".");
        var jsonTokenInfo = atob(tokenParts[1]);
        var tokenInfo = JSON.parse(jsonTokenInfo);
        return tokenInfo.sub;
    }

    

    sortList (list, field = "FileName", order="asc") {
        const sorted = [].concat(list).sort((a, b) => {
            return ('' + a[field]).localeCompare(b[field]);
        });
        return order === "asc" ? sorted : sorted.reverse(); 
    
    }
    
    getClientData() {
        const _this = this;
        (async () => {
            const clientIp = await publicIp.v4();
            const ipData = await ipLocation(clientIp);
            _this.setState({
                clientIp: clientIp,
                ipData: ipData
            });
        })();
    }

    seeDocument(e, row) {
        e.stopPropagation();
        this.setState({ viewApproval: true, selectedApproval: row, loading: true }, () => this.renderDocument())
    }

    seeNextApproval(e) {
        var { nextDocument } = this.state;
        this.setState({
            renderedApproval: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
        });
        e.stopPropagation();
        this.setState({ viewApproval: true, selectedApproval: nextDocument, loading: true }, () => this.renderDocument());
    }

    handlePreviouslyRejectedDocError(error, isSigning = false) {
        if (error.response?.status !== 409) return;
        if (isSigning) {
            setTimeout(() => {
                this.setState({
                    viewApproval: false,
                    selectedApproval: {}
                });
                this.getPendingApprovals();
            }, 3000);
            return;
        } 
        this.setState({ previouslyRejectedDoc: true, selectedApproval: {} });
    }

    getPendingApprovals() {
        this.clearVariablesToRenderListProperly();
        var { userToken, urlReturn } = this.state;

        this.setState({ loading: true })

        var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };

        const _this = this;
        const body = {
            Terms: {vidmobileuser: this.getViDMobileUserGUIDFromOauthToken()}
        };

        axios.post(getVidCloudPrivUrl() + '/approvaldocuments/searches/', body, config)
            .then(res => {
                if (res) {
                    const pendingApprovals = res.data;
                    this.props.dispatch({
                        type: ApprovalsActionTypes.ADD_APPROVALS_TO_LIST,
                        payload: _this.sortList(pendingApprovals)
                    });
                    if (pendingApprovals.length === 1) {
                        _this.setState({ viewApproval: true, selectedApproval: pendingApprovals[0] });
                        this.renderDocument();
                        return;
                    }
                    _this.setPendingSignaturesSavedInSession()
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                    if (typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== "") {
                        _this.setState({
                            tokenExpired: true
                        });
                        setTimeout(() => {
                            window.location.href = atob(urlReturn);
                        }, 3000);
                    }
                    else {
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    }
                }
                if (error.response.status === 404) {
                    if (typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== "") {
                        _this.setState({
                            redirecting: true
                        });
                        setTimeout(() => {
                            window.location.href = atob(urlReturn);
                        }, 3000);
                        sessionStorage.clear();
                    }
                }

                _this.setState({ loading: false });
                _this.clearVariablesToRenderListProperly();
            });
    }

    clearVariablesToRenderListProperly() {
        this.setState({
            renderedApproval: {
                pages: [],
                totalPages: 0,
                nextPage: 1
            },
            selectedApproval: {},
            docsSelected: [],
            approvalsSelectedGUID: []
        });
    }

    setPendingSignaturesSavedInSession() {
        const { pendingApprovalsBackup } = this.props;

        let docsSelectedGuiDdString = sessionStorage.getItem('approvalsSelectedGUIDString');
        const enabledDocsIteration = docsSelectedGuiDdString !== null && docsSelectedGuiDdString !== "";
        /**Si entra en el if es que hay documentos guardado en sesión en formato string y debe pasarse a
             * array para que la tabla de docs lo reconozca y pueda marcarlos */

        if (enabledDocsIteration) {
            const vapprovalsSelectedGUID = docsSelectedGuiDdString.split(",");
            const filteredPendinSignatures = pendingApprovalsBackup.filter(doc => vapprovalsSelectedGUID.includes(doc.DocGuid));

            filteredPendinSignatures.forEach(doc => {
                this.selectDocument(doc);
            })
        }
        let newDocumentState = { loading: false };

        this.setState(newDocumentState);
    }

    renderDocument() {
        const _this = this;
        const { selectedApproval, renderedApproval, loadingMorePages } = this.state;
        const { pendingApprovals } = this.props;
        const { pages, nextPage } = renderedApproval;
        var documentsList = pendingApprovals;

        if (loadingMorePages || nextPage === -1) return;

        var docGuid = selectedApproval.DocGuid;
        let { ApproverGuid } = selectedApproval;
    
        if (pendingApprovals.length === 1) {
            docGuid = pendingApprovals[0].DocGuid;
            ApproverGuid = pendingApprovals[0].ApproverGuid;
        }
        const index = documentsList.findIndex((doc) => doc.DocGuid === docGuid);
        if (index < documentsList.length - 1) _this.setState({ nextDocument: documentsList[index + 1] });

        if (pages.length > 0) this.setState({ loadingMorePages: true });

        documentRenderService.renderApprovalDocumentPages(ApproverGuid, nextPage)
            .then(response => {
                const data = response.data;
                const pagesData = pages.concat(data.DocPageRendered);
                const totalPdfPagesNumber = data.NumberOfPages;
                const nextPageToRender = data.NextPage;
                
                _this.setState({
                    loadingMorePages: false,
                    loading: false,
                    renderedApproval: {
                        pages: pagesData,
                        nextPage: nextPageToRender,
                        totalPages: totalPdfPagesNumber
                    }
                });
            })
            .catch(function (error) {
                this.handleSessionExpiredError(error?.response?.status);
                _this.setState({ loadingMorePages: false, loading: false });
            });

        this.getDocumentInfoAndAttachments();
    }

    appendZero(value) {
        return value >= 10 ? value : `0${value}`;
    };

    formatDate(date) {
        let hours = date.getHours();

        const moment = hours >= 12 ? "PM" : "AM";
        hours = hours > 12 ? hours - 12 : hours;

        const formattedDate = [
            this.appendZero(date.getMonth() + 1),
            this.appendZero(date.getDate()),
            date.getFullYear()].join('/') + ' ' +
            [this.appendZero(hours),
            this.appendZero(date.getMinutes()),
            this.appendZero(date.getSeconds())].join(':') + ' ' + moment;

        return formattedDate;
    }

    getDocumentInfoAndAttachments() {
        const _this = this;

        _this.getDocumentInfo()
            .then(res => {
                if (res) {
                    var documentInfo = res;
                    var documentSigners = documentInfo.Signers;
                    for (var i = 0; i < documentSigners.length; i++) {
                        const fullUTCUploadTime = documentInfo.UploadTime + ' UTC';
                        const localeUploadTime = new Date(fullUTCUploadTime);
                        documentSigners[i].UploadTime = this.formatDate(localeUploadTime);
                        if (documentSigners[i].OperationTime) {
                            const fullUTCOperationTime = documentSigners[i].OperationTime + ' UTC';
                            const localeOperationTime = new Date(fullUTCOperationTime);
                            documentSigners[i].OperationTime = this.formatDate(localeOperationTime);
                        }
                    }
                    _this.setState({ documentSigners });
                } else {
                    console.log('no user data');
                }
            })
            .catch(function (error) {
                _this.handleSessionExpiredError(error.response.status);
                _this.handlePreviouslyRejectedDocError(error);
                _this.setState({ loading: false });
            });
    }

    getDocumentInfo() {
        const _this = this;
        const { oauthTokenB64, selectedApproval } = this.state;

        const { pendingApprovals } = this.props;
        
        var config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };
        var approverGuid = selectedApproval?.ApproverGuid;
        if (pendingApprovals.length === 1) {
            approverGuid = pendingApprovals[0].ApproverGuid
        }
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudPrivUrl() + '/approvaldocuments/' + approverGuid + '/documentinfo', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    _this.handleGenericError(error);
                    reject(error);
                });
        });

    }

    getDocumentAttatchmentsList() {
        const _this = this;
        const { oauthTokenB64, selectedApproval } = this.state;
        var config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };
        var SignerGUI = selectedApproval.SignerDTO.SignerGUI


        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudPrivUrl() + '/signatures/' + SignerGUI + '/attachments/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 403) {
                        return resolve([]);
                    }
                    _this.handleGenericError(error);
                    reject(error);
                });
        });
    }

    textFormatter() {
        return (
            <MdRefresh className="refresh-button" onClick={() => this.getPendingApprovals()} />
        );
    }

    onPageChange = (page, sizePerPage) => {
        this.setState({ currentPageTable: page })
    }

    onSizePerPageChange = (sizePerPage, page) => {
        this.setState({ currentSizePerPageTable: sizePerPage, currentPageTable: 1 })
    }

    shouldHideTagColumn = () => {
        let hiddenColumnTag = true;
        this.props.pendingApprovalsBackup.forEach((doc) => {
            if (doc.Tag !== "") {
                hiddenColumnTag = false;
                return;
            }
        });

        return hiddenColumnTag;
    }

    handleBatchApproval = () => {
        const { docsSelected, oauthTokenB64 } = this.state;
        const { pendingApprovals } = this.props;

        this.setState({
            loadingApprovalProcess: true,
        });

        let totalSigned = 0;
        docsSelected.forEach(async (document) => {
            console.log({ pendingApprovals })
            const { ApproverGuid, DocGuid } = document;

            const config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };

            await axios.post(getVidCloudPrivUrl() + '/approvaldocuments/' + ApproverGuid + '/accepted/', {}, config);

            totalSigned = totalSigned + 1;

            this.setState({
                docsApproved: totalSigned
            });

            this.props.dispatch({
                type: ApprovalsActionTypes.DELETE_APPROVAL_FROM_LIST,
                payload: DocGuid,
            });

            if (totalSigned === docsSelected.length) {
                this.setState({
                    loadingApprovalProcess: false,
                    docsSelected: [],
                    approvalsSelectedGUIDString: [],
                    signedDoc: true,
                    currentPageTable: 1,
                });
            }
        });
    };

    render() {
        const { t } = this.props;
        const { viewApproval, loading, sessionExpired, redirect, time, language, renderedApproval,
            selectedApproval, rejectedDoc, signedDoc, redirecting, tokenExpired,
             loadingApprovalProcess, approvalsSelectedGUID,
            showError, errorType, errorData, userIsLoggedByAuthCerts, showJumbotron, documentInfo,
            documentSigners, documentAttatchmentsList, attDocArray, docsAttachedRendered, docsApproved, docsSelected, currentPageTable,
            loadingMorePages, previouslyRejectedDoc, showAdvancedSearch } = this.state;
        const { pages, totalPages } = renderedApproval;
        const { pendingApprovals } = this.props;

        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        if (userIsLoggedByAuthCerts) {
            return <Redirect to={{
                pathname: '/authcerts'
            }}
            />;
        }

        /**selectRow: opciones de la columna de checkbox*/
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: this.isSelectAllHidden(),
            clickToSelect: true,
            selectColumnStyle: { verticalAlign: "middle" },
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            selected: approvalsSelectedGUID
        };

        const defaultSorted = [{
            dataField: 'FileName',
            order: 'asc'
        }];

        const columns = [
            {
                dataField: 'FileName',
                text: t('documents.document'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass1',
                style: { verticalAlign: 'middle' },
                onSort: (field, order) => {
                    this.setState({
                        currentSort: { order, field }
                    })
                }
            },
            {
                dataField: 'Date',
                text: t('documents.sent'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass2',
                style: { verticalAlign: 'middle'},
                formatter: (cellContent, row) => {
                    const datetime = row?.UploadTime;
                    let date = '';
                    if (datetime ) date = datetime.split(" ")[0];

                    return date;
                }
            },
            {
                dataField: 'Tag',
                text: t('documents.tag'),
                align: 'left',
                sort: true,
                headerAlign: 'left',
                headerClasses: 'documents-headerClass2',
                style: { verticalAlign: 'middle'},
                hidden: this.shouldHideTagColumn(),
                formatter: (cellContent, row) => <TagsList tagString={row?.Tag}/>
            },
            {
                dataField: 'ActionButton',
                text: "",
                headerFormatter: this.textFormatter,
                align: 'center',
                headerAlign: 'left',
                isDummyField: true,
                style: { verticalAlign: 'middle' },
                headerClasses: 'documents-headerClass3',
                formatter: (cellContent, row, rowIndex) => {
                    return (
                        <Button
                            type="button"
                            id={row?.DocGuid}
                            className="btn btn-primary btn-table"
                            onClick={(e) => this.seeDocument(e, row)}
                        >
                            {t('documents.see')}
                        </Button>
                    );
                }
            }
        ];

        const paginationOptions = {
            custom: true,
            totalSize: this.props.pendingApprovals ? this.props.pendingApprovals.length : 0,
            page: currentPageTable,
            paginationSize: 3,
            pageStartIndex: 1,
            firstPageText: t('general.first'),
            prePageText: t('general.pre'),
            nextPageText: t('general.next'),
            lastPageText: t('general.last'),
            nextPageTitle: t('general.first-page'),
            prePageTitle: t('general.pre-page'),
            firstPageTitle: t('general.next-page'),
            lastPageTitle: t('general.last-page'),
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '30', value: 30
            }, {
                text: '50', value: 50
            }, {
                text: t('general.all'),
                value: this.props.pendingApprovals ? this.props.pendingApprovals.length : 0,
            }],
            onPageChange: this.onPageChange,
            onSizePerPageChange: this.onSizePerPageChange
        };

        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <ToolkitProvider
                    keyField='DocGuid'
                    data={this.props.pendingApprovals}
                    columns={columns}
                    search={false}
                    bootstrap4
                >
                    {
                        toolkitprops => (
                            <div>
                                <BootstrapTable
                                    striped
                                    hover
                                    condensed
                                    bordered={false}
                                    selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
                <PaginationListStandalone
                    {...paginationProps}
                />
                <SizePerPageDropdownStandalone
                    {...paginationProps}
                    className={'page-dropdown'}
                />
            </div>
        );

        var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';

        return (
            <div className="documents-container">
                {rejectedDoc &&
                    <Toast
                        onClose={() => this.setState({ rejectedDoc: false })}
                        show={rejectedDoc}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.rejected')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.rejected-text')}</Toast.Body>
                    </Toast>
                }
                {signedDoc &&
                    <Toast
                        onClose={() => this.setState({ signedDoc: false })}
                        show={signedDoc}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('approvals.approved-documents')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('approvals.approved-success')}</Toast.Body>
                    </Toast>
                }
                {redirecting &&
                    <Toast
                        onClose={() => this.setState({ redirecting: false })}
                        show={redirecting}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.exit')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.exit-text')}</Toast.Body>
                    </Toast>
                }
                {tokenExpired &&
                    <Toast
                        onClose={() => this.setState({ tokenExpired: false })}
                        show={tokenExpired}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.exit')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.exit-text2')}</Toast.Body>
                    </Toast>
                }
                {showError &&
                    <Toast
                        onClose={() => this.setState({ showError: false })}
                        show={showError}
                        animation={true}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 15
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                }
                {previouslyRejectedDoc &&
                    <CustomToast handleClose={() => {
                            this.getPendingApprovals();
                            this.setState({
                                previouslyRejectedDoc: false,
                                viewApproval: false
                            })}
                        }
                            show={previouslyRejectedDoc}
                            title={t('general.error')}
                            message={t('general.rejected')}
                            delay={null} />
                }
                {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                                <small>{timeText}</small>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.doLogOut()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                }
                <Header />
                {(!viewApproval && showJumbotron) &&
                    <Jumbotron>
                        <MdClose className="close-jumbotron" onClick={() => this.setState({ showJumbotron: !showJumbotron })} />
                        <div className="jumbotron-content">
                            <img src={warning} alt="Warning icon"/>
                            <p>
                                {t('approvals.pending')}. {t('approvals.check')}
                            </p>
                        </div>
                    </Jumbotron>
                }
                {
                    loadingMorePages && 
                        <div className="loading-more-container">
                            <LoadingCircle/>
                        </div>
                }
                {(!loading && !viewApproval && this.props.pendingApprovalsBackup.length > 0) &&
                    <div className="documents-table-container">
                        <SigningProcessModal
                            showModal={loadingApprovalProcess}
                            showProcess={true}
                            signedDocs={docsApproved}
                            selectedDocs={docsSelected.length}
                            isApprovals
                            />

                        <SearchBar
                            updateState={(newState) => this.setState(newState)}
                            showTag={!this.shouldHideTagColumn()}
                            approvalsSelectedGUID={approvalsSelectedGUID}
                            showAdvancedSearch={showAdvancedSearch}
                            setShowAdvancedSearch={(showAdvancedSearch) => this.setState({showAdvancedSearch})}
                        />

                        <PaginationProvider
                            pagination={paginationFactory(paginationOptions)}>
                            {contentTable}
                        </PaginationProvider>

                        <div className='button-container'>
                            <Button
                                type="button"
                                disabled={approvalsSelectedGUID.length === 0 || null}
                                className={approvalsSelectedGUID.length === 0 || null ? "btn btn-primary button-disabled" : "btn btn-primary"}
                                id="sign-all-docs"
                                onClick={() => this.handleBatchApproval()}
                            >
                               {t('approvals.approve-selected')}
                            </Button>
                        </div>

                        
                    </div>
                }
                {(!loading && !viewApproval && this.props.pendingApprovalsBackup.length === 0) &&
                    <EmptyDocsPanel isApprovals onClickGetPendingDocuments={this.getPendingApprovals} />
                }
                {loading &&
                    <LoadingCircle centerVertically={true} />
                }
                {(viewApproval && pages.length > 0) &&
                    <ApprovalPreview
                        renderedApproval={pages}
                        selectedApproval={selectedApproval}
                        pendingApprovals={pendingApprovals}
                        documentInfo={documentInfo}
                        documentSigners={documentSigners}
                        documentAttatchmentsList={documentAttatchmentsList}
                        attDocArray={attDocArray}
                        docsAttachedRendered={docsAttachedRendered}
                        viewApprovalHandler={this.viewApprovalHandler}
                        showRejectedDocHandler={this.showRejectedDocHandler}
                        showApprovedDocHandler={this.showApprovedDocHandler}
                        totalPages={totalPages}
                        renderDocument={this.renderDocument}
                        handlePreviouslyRejectedDocError={this.handlePreviouslyRejectedDocError}
                        seeNextApproval={this.seeNextApproval}
                        checkedApprovals={approvalsSelectedGUID}
                        updateSelectedApprovals={this.handleOnSelect}
                    />
                }
            </div>
        );
    }

    componentDidMount() {
        Utils.appendScript("/assets/js/autoscript.js");
        Utils.appendScript("/assets/js/forge.min.js");
        this.getClientData();

        ThemeService.setTheme();

        var authCerts = sessionStorage.getItem('authCerts');
        if (authCerts !== null) {
            this.setState({ userIsLoggedByAuthCerts: true });
        }
        this.setState({ oauthTokenB64: sessionStorage.getItem('oauthTokenB64') });
        this.getPendingApprovals();
        // this.loadViDMobileUserCertificateList();
    }
}

const mapStateToProps = (state) => {
    return { 
        pendingApprovals: state.approvals.filteredApprovals,
        pendingApprovalsBackup: state.approvals.approvals
    };
};

export default connect(mapStateToProps)(withTranslation()(Approvals));
