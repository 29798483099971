import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import LoadingCircle from '../../LoadingCircle';
import PropTypes from 'prop-types'

const SigningProcessModal = ({ showModal, showProcess, signedDocs, selectedDocs, isApprovals }) => {
    const { t } = useTranslation('translation');

    return (
        <Modal data-testid="modal-open" show={showModal}>
            <Modal.Header>
                <Modal.Title data-testid="modal-title">
                    {
                        isApprovals ? t('approvals.approving') : t('general.sign-process')
                    }
                    {showProcess &&
                        <span>{signedDocs + 1}/{selectedDocs}</span>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingCircle />
            </Modal.Body>
        </Modal>
    )
}

const validatePropType = function (props, propName) {
    if ((props['showProcess'] === true && typeof (props[propName]) != 'number')) {
        return new Error(`${propName} is required when showProcess is true`);
    }
};

SigningProcessModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    showProcess: PropTypes.bool.isRequired,
    isApprovals: PropTypes.bool,
    signedDocs: validatePropType,
    selectedDocs: validatePropType
}

export default SigningProcessModal;
