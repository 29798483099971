/**
 * These variables should be the same as the DB default config, 
 * if we don´t want this hardcoded values we should call a service to get the DB config
 */
const PRIMARY_COLOR = "#00bf71";
const PRIMARY_TEXT_COLOR = "#FFFFFF";
const SECONDARY_COLOR = "#00bf71";
const SECONDARY_TEXT_COLOR = "#FFFFFF";
const LOGO = "/logo_vidsigner_white.png";

const ThemeService = {
    setTheme: function() {
        if (!(window.CSS && window.CSS.supports )) {
            // CSS custom properties not supported
            return false;  
        }

        setValues();
    }
};

function setValues(){
    var appearanceConfDTO = getAppearanceConfDTO();

    //It's null when you logged out so set the default Validated ID styles to do not break the login view
    if(appearanceConfDTO == null){
        appearanceConfDTO = getDefaultValues();
    }

    var DOMstyle = document.documentElement.style;
        DOMstyle.setProperty("--custom-primary-background-color", appearanceConfDTO.PrimaryColor);
        DOMstyle.setProperty("--custom-primary-text-color", appearanceConfDTO.PrimaryTextColor);
        DOMstyle.setProperty("--custom-secondary-background-color", appearanceConfDTO.SecondaryColor);
        DOMstyle.setProperty("--custom-secondary-text-color", appearanceConfDTO.SecondaryTextColor);
        DOMstyle.setProperty("--custom-secondary-background-color-hover", adjustColor(appearanceConfDTO.SecondaryColor, -40));
        DOMstyle.setProperty("--custom-logo", 'url("' + appearanceConfDTO.Logo + '")');
}

function getDefaultValues(){
    return  {
                PrimaryColor: PRIMARY_COLOR,
                PrimaryTextColor: PRIMARY_TEXT_COLOR,
                SecondaryColor: SECONDARY_COLOR,
                SecondaryTextColor: SECONDARY_TEXT_COLOR,
                Logo: LOGO
            };
}

function adjustColor(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
};

function getAppearanceConfDTO(){
    var appearanceConfBase64 = sessionStorage.getItem('appearanceConf');
    var appearanceConf = null;

    if(appearanceConfBase64){
        var appearanceConfJson = atob(appearanceConfBase64);
        appearanceConf = JSON.parse(appearanceConfJson);    
    }
    
    return appearanceConf;
};

export default ThemeService;