import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomModal } from "../../../components/CustomModal/CustomModal"
import CustomToast from '../../../components/Documents/CustomToast/CustomToast';
import { getVidCloudMgmtUrl } from '../../../services/environment/environmentService';
import { CLIENT_ID, CLIENT_SECRET } from '../../../services/vid-config';
import { AdvancedOptionsSelect } from '../AdvancedOptionsSelect/AdvancedOptionsSelect';

import './ForgotPassword.css';

export const ForgotPassword = ({ wantsToResetPassword, setWantsToResetPassword }) => {
  const [user, setUser] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recoverySuccess, setRecoverySuccess] = useState(false);
  const { t } = useTranslation();

  const getPasswordRecoveryToken = () => {
    return new Promise(function (resolve, reject) {
      var vidCloudMgmtUrl = getVidCloudMgmtUrl();
      var authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);
      axios({
        method: 'post',
        url: vidCloudMgmtUrl + '/oauth/token',
        data: {
          username: user,
          password: "",
          grant_type: "password",
          scope: "vidmobileuser\\recoverpass"
        },
        async: false,
        dataType: "json",
        contentType: 'application/json',
        crossDomain: true,
        headers: { 'Authorization': 'Basic ' + authorizationHeader }
      })
        .then(res => {
          resolve(res.data.access_token);
        })
        .catch(function (error) {
          reject(error);
        })
    });
  }

  const handleConfirm = async () => {
    if (!user) return;

    const vidCloudMgmtUrl = getVidCloudMgmtUrl();
    setLoading(true);
    setError(null);
    try {
      const tokenResponse = await getPasswordRecoveryToken();
      const tokenParts = tokenResponse.split(".");
      const jsonTokenInfo = atob(tokenParts[1]);
      const tokenInfo = JSON.parse(jsonTokenInfo);
      const userGUID = tokenInfo.sub;
      const i18nextLng = localStorage.getItem('i18nextLng');
      const lang = i18nextLng.split("-");

      const recoveryPasswordResponse = axios({
        method: 'put',
        url: vidCloudMgmtUrl + '/vidmobileusers/' + userGUID + '/recoverpass',
        data: {
          language: lang[0]
        },
        async: false,
        dataType: "json",
        contentType: 'application/json',
        crossDomain: true,
        headers: { 'Authorization': 'Bearer ' + tokenResponse }
      });

      if (recoveryPasswordResponse) {
        setLoading(false);
        setRecoverySuccess(true);
        setWantsToResetPassword(false);
      }
    } catch (e) {
      setLoading(false);
      setError(t("vid-chain-login.inexistent-user"));
    }
  };


  return (
    <>
      <CustomToast
        delay={3000}
        show={error}
        handleClose={() => setError(null)}
        title={t('general.error')}
        message={error} />
      <CustomToast
        show={recoverySuccess}
        handleClose={() => setRecoverySuccess(null)}
        title={t('login.recovery-success-title')}
        message={`${t('login.recovery-success-text')}. ${t('login.recovery-success-subtext')}`} />
      <CustomModal
        show={wantsToResetPassword}
        handleHide={() => setWantsToResetPassword(false)}
        handleConfirm={handleConfirm}
        title={t('login.password-forgot')}
        noIcon
        disabledButton={!user || loading}
      >
        <p>{t('login.text-forgot')}</p>
        <div className="reset-password-form">
          <input
            type="text"
            name="user"
            placeholder={t("vid-chain-login.user")}
            value={user}
            onChange={e => setUser(e.target.value)}
          />
          <AdvancedOptionsSelect />
        </div>
      </CustomModal>
    </>
  );
};