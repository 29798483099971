import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PRE, PRO } from '../../../assets/constants';
import './AdvancedOptionsSwitch.css';

export const AdvancedOptionsSwitch = ({ shouldHideContent }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [environment, setEnvironment] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const url = new URL(urlString);
    const userToken = url.searchParams.get("usertoken");
    const envParam = url.searchParams.get("env");

    if (userToken && envParam) return;
    
    const { host } = window.location;

    const isPRE = host.includes("pre");
    const env = isPRE ? PRE : PRO;

    sessionStorage.setItem("environment", env);
    setEnvironment(env);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, e) => {
    sessionStorage.setItem("environment", value);
    setEnvironment(value);
    e.preventDefault();
  }


  const urlString = window.location.href;
  const url = new URL(urlString);
  const env = url.searchParams.get("env");

  if (env) return <></>;

  return (
    <div>
      <div className={`advanced-switch ${showOptions ? null : "with-margin-bottom"}`}>
        <label className="switch">
          <input type="checkbox" checked={showOptions } /> 
          <span className="slider round" onClick={() => setShowOptions(!showOptions)}></span>
        </label>
        <span>{t("vid-chain-login.advanced-options")}</span>
      </div>
      {
        showOptions &&
        <div className={`environment-container`}>
          <div className="options-container">
            <button 
              className={`${environment === PRE ? "is-environment-selected" : null}`} 
              onClick={(e) => handleChange("PRE", e)}>PRE</button>
            <button 
              className={`${environment === PRO ? "is-environment-selected" : null}`} 
              onClick={(e) => handleChange("PRO", e)}>PRO</button>
          </div>
        </div>
      }
    </div>
  );
}