import React, { useEffect, useState } from "react";
import { getVidCloudPrivUrl } from "../../services/environment/environmentService";

// import axios from 'axios';
// import { useTranslation } from "react-i18next";
// import { CLIENT_ID, CLIENT_SECRET } from '../../services/vid-config';
import { PRE, PRO } from "../../assets/constants";
import axios from "axios";

export const SAML = () => {
  const [error, setError] = useState(null);

  // const { t } = useTranslation();

  useEffect(() => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);
    const token = querystring.get("token");
    const error = querystring.get("error");

    if (error) {
      setError(error);
      return;
    }

    const { host } = window.location;
    const isPRE = host.includes("pre") ;
    const env = isPRE ? PRE : PRO;

    sessionStorage.setItem("environment", env);

    const vidCloudPrivUrl = getVidCloudPrivUrl(env);

    sessionStorage.setItem("oauthTokenB64", token);
    const tokenParts = token.split(".");
    const jsonTokenInfo = atob(tokenParts[1]);
    const tokenInfo = JSON.parse(jsonTokenInfo);

    const userGUID = tokenInfo.sub;

    const appearanceReq = `${vidCloudPrivUrl}/vidmobileuser/${userGUID}/appearance`;

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios.get(appearanceReq, config).then((resAppearance) => {
      sessionStorage.setItem("AccessMethod", "SAML");

      sessionStorage.setItem(
        "appearanceConf",
        btoa(JSON.stringify(resAppearance.data))
      );
      window.location.href = "/documents";
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.href = "/login-ad";
      }, 3000);
    }
  }, [error]);

  return <>{error && error}</>;
};
