import React, { useState, useEffect } from 'react';
import './AdvancedSearch.css';
import { useTranslation } from "react-i18next";
import FilterCertificateMsg from "../../FilterCertificateMsg/FilterCertificateMsg";

import { MdSearch } from "react-icons/md";

import { useDispatch, useSelector } from 'react-redux';
import ApprovalsActionTypes from '../../../../redux/approvals/approvals.types';

const INITIAL_FILTERS = {
  tag: "",
  from: "",
  to: "",
  text: ""
};


export const AdvancedSearch = ({ updateState, showTag, approvalsSelectedGUID }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const { tag, from, to, text } = filters;
  const [isFirstRender, setIsFirstRender] = useState(true);

  const emptyFilters = tag.length === 0 && from.length === 0 && to.length === 0 && text.length === 0;

  const dispatch = useDispatch();
  const signatures = useSelector(state => state.approvals.approvals);

  const resetTableState = () => {
    const isAdvancedSearching = tag.length || from.length || to.length;

    let newTableState = {
      currentPageTable: 1,
      inputSearch: "",
      isAdvancedSearching,
    };

    updateState(newTableState);
  };

  // Reset table status on component init
  useEffect(() => {
    //Load session saved filters
    const savedFilters = sessionStorage.getItem("advancedFiltersApprovals");

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);

      setFilters({
        ...parsedFilters,
      });
      setIsFirstRender(false);
      return;
    }

    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleClear = () => {
    setFilters(INITIAL_FILTERS);
    resetTableState(true);
  };

  const getTagsList = () => {
    const tagsList = [];
    signatures.forEach((doc) => {
      if (doc.Tag !== "") {
        const splittedTags = doc.Tag.split(",").filter((el) => {
          return !tagsList.includes(el);
        });
        tagsList.push(...splittedTags);
      }
    });
    return tagsList;
  }

  const tags = getTagsList();

  const performSearch = () => {
    resetTableState();
    let advancedFilteredDocs = signatures;

    // Filter by text if selected in filters
    advancedFilteredDocs = advancedFilteredDocs.filter((document) => {
      const { FileName } = document;
      const inputContainsName = FileName.toLowerCase().includes(text.toLowerCase());

      return inputContainsName;
    });

    // Filter by tag if selected in filters
    if (tag) {
      advancedFilteredDocs = advancedFilteredDocs.filter((doc) => {
        //If filtering by Unspecified and doc doesn't have tags, include it
        if (tag === "Unspecified" && doc.Tag === "") return true;

        const docTags = doc.Tag.split(",");

        const matchesTag = docTags.includes(tag);
        return matchesTag;
      });
    }

    // Filter by start date if selected in filters
    if (from) {
      const startDate = new Date(from);

      advancedFilteredDocs = advancedFilteredDocs.filter((doc) => {
        const currentDate = new Date(doc.UploadTime.split(" ")[0]);

        return +currentDate >= +startDate;
      });
    }

    // Filter by start date if selected in filters
    if (to) {
      const endDate = new Date(to);

      advancedFilteredDocs = advancedFilteredDocs.filter((doc) => {
        const currentDate = new Date(doc.UploadTime.split(" ")[0]);

        return +currentDate <= +endDate;
      });
    }

    const previouslySelected = signatures.filter((doc) => {
      return approvalsSelectedGUID.includes(doc.DocGUID);
    });

    const newSignatures = new Set([...advancedFilteredDocs, ...previouslySelected])

    updateState({
      currentPageTable: 1,
      pendingSignatures: [...newSignatures],
    });

    dispatch({
      type: ApprovalsActionTypes.FILTER_APPROVALS,
      payload: [...newSignatures],
    });
  }

  useEffect(() => {
    performSearch();
    if (!isFirstRender) sessionStorage.setItem("advancedFiltersApprovals", JSON.stringify(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const today = new Date();
  const maxDate = today.toISOString().split('T')[0];

  let sixtyFiveDaysBefore = new Date();
  sixtyFiveDaysBefore.setDate(today.getDate() - 65);
  const minDate = sixtyFiveDaysBefore.toISOString().split('T')[0];

  return (
    <div className="advanced-search-container">
      <div className="search-container">
        <MdSearch className="search-icon" />
        <input onChange={handleChange} name="text" type="text" placeholder={t('documents.search')} value={text} />
      </div>
      <div className="advanced-form">
        <div className="inputs-container">
          {showTag && <div className="input-container">
            <label htmlFor="tag" className="input-label">{t('documents.tag')}:</label>
            <select value={tag} name="tag" onChange={handleChange}>
              <option value="">{t('documents.select-one')}</option>
              <option value="Unspecified">{t('documents.document-cert-undef')}</option>
              {
                tags.map((tag, i) => {
                  return <option value={tag} key={i}>{tag}</option>;
                })
              }
            </select>
          </div>}
          <div className="input-container">
            <label htmlFor="from">{t('documents.from')}:</label>
            <input type="date" name="from" min={minDate} max={to === "" ? maxDate : to} onChange={handleChange} value={from} />
          </div>
          <div className="input-container">
            <label htmlFor="to">{t('documents.to')}:</label>
            <input type="date" name="to" min={from === "" ? minDate : from} max={maxDate} onChange={handleChange} value={to} />
          </div>
        </div>
      </div>
      <div className="search-buttons-container">
        <button onClick={handleClear}>{t('documents.clear')}</button>
      </div>
      {
        !emptyFilters && <>
          <hr />
          <div className="filter-list-container">
            {
              text.length > 0 && <FilterCertificateMsg close={() => {
                setFilters({
                  ...filters,
                  text: ""
                });
              }} filter={t('documents.text') + ": " + text} />
            }
            {
              tag.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                tag: ""
              })} filter={`${t('documents.tag')}: ${tag === "Unspecified" ? t('documents.document-cert-undef') : tag}`} />
            }
            {
              from.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                from: ""
              })} filter={t('documents.from') + ": " + from} />
            }
            {
              to.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                to: ""
              })} filter={t('documents.to') + ": " + to} />
            }
          </div>
        </>
      }

    </div>
  )
};
