import React, { Fragment } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingCircle from '../LoadingCircle';

const SwisscomSignature = ({ parentState, onClose, onSuccessSignDocSelection, onSuccess }) => {
    const { t } = useTranslation();
    const { 
        showSwisscomModal, 
        showSwisscomConfirmationModal,
        loadingSwisscomRequest,
        swisscomError,
        loadingSwisscomCheck } = parentState;
        
    return (
        <Fragment>            
            <Modal show={showSwisscomModal} onHide={onClose} >
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.swisscom-title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('documents.swisscom-text')}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="reject" onClick={onClose}>
                        {t('general.cancel')}
                    </Button>
                    <Button variant="primary" onClick={onSuccessSignDocSelection}>
                        {t('general.confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSwisscomConfirmationModal} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.swisscom-title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ marginBottom: 0 }}>{t('documents.swisscom-confirmation-text')}</p>
                    <p style={{ fontWeight: 'bold', color: 'red', marginBottom: 0 }}>{swisscomError}</p>
                </Modal.Body>
                <Modal.Footer>
                    {loadingSwisscomRequest &&
                        <LoadingCircle />
                    }
                    {!loadingSwisscomRequest &&
                        <div>
                            {loadingSwisscomCheck &&
                                <LoadingCircle />
                            }
                            {!loadingSwisscomCheck &&
                                <div>
                                    <Button className="reject" onClick={onClose}>
                                        {t('general.cancel')}
                                    </Button>
                                    <Button variant="primary" style={{ marginLeft: '1rem' }} 
                                            onClick={onSuccess}>
                                        {t('general.continue')}
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default SwisscomSignature