import React, { useEffect, useState } from 'react';
import { getVidCloudMgmtUrl, getVidCloudPrivUrl } from "../../services/environment/environmentService";

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { CLIENT_ID, CLIENT_SECRET } from '../../services/vid-config';
import { PRE, PRO } from '../../assets/constants';
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';

export const SSI = () => {
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);
    const code = querystring.get("code");

    if (!code) {
      setError(t("vid-chain-login.not-found-code") + ". URL: " + window.location.href);
      return;
    }

    const { host } = window.location;
    const isPRE = host.includes("pre");
    const env = isPRE ? PRE : PRO;

    sessionStorage.setItem("environment", env);

    const vidCloudMgmtUrl = getVidCloudMgmtUrl(env);
    const vidCloudPrivUrl = getVidCloudPrivUrl(env);
    const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

    axios.post(`${vidCloudMgmtUrl}/ssi/code`, { Code: code }, {
      headers: { 'Authorization': 'Basic ' + authorizationHeader }
    })
      .then((response) => {
        const token = response?.data.Token;
        sessionStorage.setItem("oauthTokenB64", token);
        const tokenParts = token.split(".");
        const jsonTokenInfo = atob(tokenParts[1]);
        const tokenInfo = JSON.parse(jsonTokenInfo);

        const userGUID = tokenInfo.sub;

        const appearanceReq = `${vidCloudPrivUrl}/vidmobileuser/${userGUID}/appearance`;

        const config = {
          headers: {
            Authorization: "Bearer " + token
          }
        }

        axios.get(appearanceReq, config)
          .then(resAppearance => {
            sessionStorage.setItem("appearanceConf", btoa(JSON.stringify(resAppearance.data)));
            window.location.href = '/documents';
          })
      })
      .catch((e) => {
        const statusCode = e?.response?.status;
        if (statusCode === 400) {
          setError(t("vid-chain-login.invalid-code"));
          return;
        }
        if (statusCode === 401) {
          setError(t("vid-chain-login.inexistent-user"));
          return;
        }
        setError(t("vid-chain-login.uncontrolled-error"));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  }, [error]);

  return <>
    {error ? error : <LoadingCircle centerVertically />}
  </>;
}