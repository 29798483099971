import React, { useState } from 'react'

import { Button, Form, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

const RedTrustCertificates = ({ parentState, authorizeRedTrustCertificate, unauthorizeRedTrustCertificate, synchronizeRedTrustCertificates }) => {
  const [RTuser, setRTuser] = useState('');
  const [RTpassword, setRTpassword] = useState('');
  const [RTdomain, setRTdomain] = useState('');
  
  const { t } = useTranslation();
  const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;

  const RedTrustColumns = [
    {
        dataField: 'certalias',
        text: t('certificates.certificate'),
        align: 'left',
        sort: true,
        headerAlign: 'left',
        headerClasses: 'headerClass1',
        style: { verticalAlign: 'middle' }
    },
    {
        dataField: 'ExpirationDate',
        text: t('certificates.certificate-date'),
        align: 'left',
        sort: true,
        headerAlign: 'left',
        headerClasses: 'headerClass2',
        style: { verticalAlign: 'middle' }
    }
];

const {
  RedTrustIsAuthorized,
  certificatesOrdered: { RedTrust }
} = parentState;

  return (
    <div className="table-container">
      {!RedTrustIsAuthorized &&
        <div>
          {certsNotAvailable}
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="red-trust-user">{t('general.user')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="text"
              aria-label="Default"
              aria-describedby="red-trust-user"
              value={RTuser}
              onChange={e => setRTuser(e.target.value)} 
              />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="red-trust-domain">{t('general.domain')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="text"
              aria-label="Default"
              aria-describedby="red-trust-domain"
              value={RTdomain}
              onChange={e => setRTdomain(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="red-trust-password">{t('general.password')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="password"
              aria-label="Default"
              aria-describedby="red-trust-password"
              value={RTpassword}
              onChange={e => setRTpassword(e.target.value)}
            />
          </InputGroup>
          <Button
            type="button"
            id="authorizeRT"
            className="btn btn-primary signature-image"
            onClick={() => authorizeRedTrustCertificate({ RTuser, RTpassword, RTdomain })}
          >
            {t('general.authorize')}
          </Button>
        </div>
      }
      {RedTrustIsAuthorized &&
        <div>
          <ToolkitProvider
            keyField="GUID"
            data={RedTrust}
            columns={RedTrustColumns}
          >
            {
              props => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                  />
                </div>
              )
            }
          </ToolkitProvider>
          <div className="rt-buttons-container">
            <Button
              type="button"
              id="unauthorizeRT"
              className="btn signature-image reject"
              onClick={unauthorizeRedTrustCertificate}
            >
              {t('general.unauthorize')}
            </Button>
            <Button
              type="button"
              id="synchronizeRedTrust"
              className="btn btn-primary signature-image"
              onClick={() => synchronizeRedTrustCertificates({ RTuser, RTpassword, RTdomain })}
              style={{ marginLeft: 10 }}
            >
              {t('general.synchronize')}
            </Button>
          </div>
        </div>
      }
    </div>
  )
};

export default RedTrustCertificates;