import React, {Component} from 'react';
import './App.css';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import Documents from '../components/Documents/Documents'
import Profile from '../components/Profile/Profile'
import Certificates from '../components/Certificates/Certificates'
import AuthCerts from '../components/AuthCerts/AuthCerts'
import Recovery from '../components/Recovery/Recovery'
import {Col, Container, Row} from "react-bootstrap";
import { VIDChainLoginPage } from '../pages/VIDChainLogin/VIDChainLogin';
import { ChangePasswordPage } from '../pages/ChangePassword/ChangePassword';
import { Auth } from '../pages/Auth/Auth';
import { SSI } from '../pages/SSI/SSI';
import Approvals from './Approvals/Approvals';
import { SAML } from '../pages/SAML/SAML';
import { LoginMethods } from '../pages/LoginMethods/LoginMethods';

class App extends Component {
    render() {

        const PrivateRoute = ({ component: Component, path: Path,  ...rest }) => (
            <Route {...rest} render={(props) => (
                sessionStorage.getItem('oauthTokenB64') !== null
                    ? <Component {...props} />
                    : <Redirect to='/' />
            )} />
        );

        return (
            <div className="App">
                <Container>
                    <Row>
                        <Col>
                            <BrowserRouter>
                                <Switch>
                                    <Route
                                        exact path={["/", "/signhash/cms"]}
                                        component={VIDChainLoginPage}
                                    />
                                    <Route
                                        exact path='/recovery'
                                        component={Recovery}
                                    />
                                    <Route
                                        exact path='/auth'
                                        component={Auth}
                                    />
                                    <Route
                                        exact path='/ssi'
                                        component={SSI}
                                    />
                                    <Route
                                        exact path='/new-login'
                                        component={LoginMethods}
                                    />
                                    <Route
                                        exact path='/saml'
                                        component={SAML}
                                    />
                                    <PrivateRoute path='/documents' component={Documents} />
                                    <PrivateRoute path='/approvals' component={Approvals} />
                                    <PrivateRoute path='/profile' component={Profile} />
                                    <PrivateRoute path='/certificates' component={Certificates} />
                                    <PrivateRoute path='/authcerts' component={AuthCerts} />
                                    <PrivateRoute path='/changePassword' component={ChangePasswordPage} />
                                </Switch>
                            </BrowserRouter>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default App;
