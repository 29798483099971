import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import translationEN from '../assets/i18n/en';
import translationES from '../assets/i18n/es';
import translationCA from '../assets/i18n/ca';
import translationDE from '../assets/i18n/de';
import translationFR from '../assets/i18n/fr';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    ca: {
        translation: translationCA
    },
    de: {
        translation: translationDE
    },
    fr: {
        translation: translationFR
    }
};

const langaugeSelected = localStorage.getItem('i18nextLng');

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: langaugeSelected,
        fallbackLng: "en", // use en if detected lng is not available
        //keySeparator: false, // no nested
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        // react-i18next options
        react: {
            wait: true
        }
    });

export default i18n;