import axios from "axios";
import {getVidCloudMgmtUrl, getVidCloudPrivUrl } from './../environment/environmentService';
import { FIRMAREMOTA_URI, FIRMAREMOTA_URI_SESSION, PUT_HTTP_METHOD, DELETE_HTTP_METHOD, SWISSCOM_URI_BASE, UANATACA_URI, SWISSCOM_URI_SIGNREQUEST, 
    SWISSCOM_URI_COMPLETE_SIGNATURE, REDTRUST_URI, KEYVAULT_URI, KEYVAULT_SEND_OTP_URI_BASE, KEYVAULT_SEND_OTP_URI_OTPSMS, NEBULA_URI, 
    SOFTWARE_URI, VIDMOBILEUSER_URI, NEBULA_URI_AUTHEND, NEBULA_URI_AUTHMIDDLE, NEBULA_URI_AUTH, POST_HTTP_METHOD, AUTOFIRMA_URI_BASE, 
    AUTOFIRMA_URI_SIGNREQUEST, AUTOFIRMA_URI_PRESIGNATURE, GET_HTTP_METHOD, FIRMAREMOTA_URI_SHORT, SMS_URI, FIRMAREMOTA_URI_AUTH, 
    FIRMAREMOTA_URI_STATUS, REDTRUST_URI_AUTH, CERTS, SESSION, REDTRUST_URI_AUTH_STATUS, DELETE_CERTIFICATE, UANATACA_URI_AUTH, SWISSCOM_URI_SIGNREQUESTHASH, SWISSCOM_URI_COMPLETESIGNATUREHASH, OAUTHCODE_URI, UANATACA_SEND_OTP, VALIDATEDID_URI_AUTH, VALIDATEDID_SEND_OTP, VALIDATEDID_URI, UANATACA_URI_STATUS, VALIDATEDID_URI_STATUS } from "./constants";

const requestSignature = (httpMethod, urlBase, url, dataJson, oauthTokenB64) => {
    return axios({
        method: httpMethod,
        url: urlBase + url,
        data: dataJson,
        async: true,
        dataType: "json",
        contentType: 'application/json',
        crossDomain: true,
        headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
    });   
}
export const getManagementSignature = (url, dataJson, oauthTokenB64) => {
    return requestSignature(GET_HTTP_METHOD, getVidCloudMgmtUrl(), url, null, oauthTokenB64);    
};

export const postPrivSignature = (url, dataJson, oauthTokenB64) => {
    return requestSignature(POST_HTTP_METHOD, getVidCloudPrivUrl(), url, dataJson, oauthTokenB64);    
};

export const getPrivSignature = (url, oauthTokenB64) => {
    return requestSignature(GET_HTTP_METHOD, getVidCloudPrivUrl(), url, null, oauthTokenB64);    
};

export const postManagementSignature = (url, dataJson, oauthTokenB64) => {
    return requestSignature(POST_HTTP_METHOD, getVidCloudMgmtUrl(), url, dataJson, oauthTokenB64);    
};

export const putManagementSignature = (url, dataJson, oauthTokenB64) => {
    return requestSignature(PUT_HTTP_METHOD, getVidCloudMgmtUrl(), url, dataJson, oauthTokenB64);    
};

export const putPrivSignature = (url, dataJson, oauthTokenB64) => {
    return requestSignature(PUT_HTTP_METHOD, getVidCloudPrivUrl(), url, dataJson, oauthTokenB64);    
};

export const deleteManagementSignature = (url, dataJson, oauthTokenB64) => {
    return requestSignature(DELETE_HTTP_METHOD, getVidCloudMgmtUrl(), url, dataJson, oauthTokenB64);    
};

export const generateUanatacaUri = (signerGuid) => {
    return `${UANATACA_URI}${signerGuid}`;
};

export const generateValidatedIdUri = (signerGuid) => {
    return `${VALIDATEDID_URI}${signerGuid}`;
};

export const generateUanatacaOtpUri = (signerGuid) => {
  return `${VIDMOBILEUSER_URI}${signerGuid}${UANATACA_SEND_OTP}`;
};

export const generateValidatedIdOtpUri = (signerGuid) => {
    return `${VIDMOBILEUSER_URI}${signerGuid}${VALIDATEDID_SEND_OTP}`;
};

export const generateUanatacaAuthUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${UANATACA_URI_AUTH}`;
};

export const generateValidatedIdAuthUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${VALIDATEDID_URI_AUTH}`;
};

export const generateSwisscomUri = (signerGuid, completeSignature) => {
    const uriBase = `${SWISSCOM_URI_BASE}${signerGuid}`;

    if(completeSignature)
        return `${uriBase}${SWISSCOM_URI_COMPLETE_SIGNATURE}${completeSignature}`;
        
    return `${uriBase}${SWISSCOM_URI_SIGNREQUEST}`;
};

export const generateSwisscomSignHashUri = () => {
    return `${SWISSCOM_URI_SIGNREQUESTHASH}`;
};

export const generateSwisscomCompleteSignatureHashUri = (requestId) => {
    return `${SWISSCOM_URI_COMPLETESIGNATUREHASH}${requestId}`;
};

export const generateRedTrustUri = (signerGuid) => {
    return `${REDTRUST_URI}${signerGuid}`;
};

export const generateRedTrustAuthUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${REDTRUST_URI_AUTH}`;
};

export const generateRedTrustAuthStatusUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${REDTRUST_URI_AUTH_STATUS}`;
};

export const generateNebulaUri = (signerGuid) => {
    return `${NEBULA_URI}${signerGuid}`;
};

export const generateNebulaAuthUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${NEBULA_URI_AUTH}`;
};

export const generateNebulaAuthMiddleUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${NEBULA_URI_AUTHMIDDLE}`;
};

export const generateNebulaAuthEndUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${NEBULA_URI_AUTHEND}`;
};

export const generateSoftwareUri = (signerGuid) => {
    return `${SOFTWARE_URI}${signerGuid}`;
};

export const generateDeleteSoftwareCertificateUri = (legalid, certificateGuid) => {
  return `${DELETE_CERTIFICATE}${legalid}${CERTS}${certificateGuid}`;
};

export const generateKeyVaultUri = (signerGuid) => {
    return `${KEYVAULT_URI}${signerGuid}`;
};

export const generateDeleteKeyVaultCertificateUri = (legalid, certificateGuid) => {
  return `${DELETE_CERTIFICATE}${legalid}${CERTS}${certificateGuid}`;
};

export const generateuUploadCertificateKeyVaultUri = (legalid) => {
  return `${DELETE_CERTIFICATE}${legalid}${CERTS}`;
};

export const generateKeyVaultSendOTPUri = (certificateGuid) => {
    return `${KEYVAULT_SEND_OTP_URI_BASE}${certificateGuid}${KEYVAULT_SEND_OTP_URI_OTPSMS}`;    
};

export const generateKeyVaultOpenSessionUri = (certificateGuid) => {
  return `${KEYVAULT_SEND_OTP_URI_BASE}${certificateGuid}${SESSION}`;
};

export const generateAutofirmaCertificateUri = (signerGuid) => {
    return `${AUTOFIRMA_URI_BASE}${signerGuid}${AUTOFIRMA_URI_SIGNREQUEST}`;    
};

export const generateAutofirmaUri = (signerGuid) => {
    return `${AUTOFIRMA_URI_BASE}${signerGuid}`;    
};

export const generateAutofirmaPreSignatureUri = (signerGuid) => {
    return `${AUTOFIRMA_URI_PRESIGNATURE}${signerGuid}`;    
};

export const generateFirmaRemotaUri = (signerGuid) => {
    return `${FIRMAREMOTA_URI}${signerGuid}`;
};

export const generateFirmaRemotaSessionUri = () => {
    return `${FIRMAREMOTA_URI_SESSION}`;
};

export const generateFirmaRemotaOTPUri = (userGuid) => {
    return `${FIRMAREMOTA_URI_SHORT}${userGuid}${SMS_URI}`;
};

export const generateFirmaRemotaAuthUri = () => {
    return `${FIRMAREMOTA_URI_AUTH}`;
};

export const generateFirmaRemotaStatusUri = () => {
    return `${FIRMAREMOTA_URI_STATUS}`;
};

export const generateOauthCodeUri = () => {
    return `${OAUTHCODE_URI}`;
};

export const generateUanatacaStatusUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${UANATACA_URI_STATUS}`;
};

export const generateValidatedIdStatusUri = (userGuid) => {
    return `${VIDMOBILEUSER_URI}${userGuid}${VALIDATEDID_URI_STATUS}`;
};