import React, {Component} from 'react';
import './Recovery.css';
import {withTranslation} from 'react-i18next';
import i18n from '../../config/i18n';
import {Button, Form, InputGroup, Jumbotron, Modal, Navbar, Toast} from 'react-bootstrap';
import axios from "axios";
import {Redirect} from "react-router-dom";
import {MdClose} from "react-icons/md";
import LoadingCircle from '../LoadingCircle';
import { getVidCloudMgmtUrl } from '../../services/environment/environmentService';
import { SecurityValidation } from './../SecurityValidation/SecurityValidation';
import { PRE, PRO } from '../../assets/constants';

class Recovery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: '',
            password: '',
            passwordConfirmation: '',
            showError: '',
            errorType: '',
            errorData: '',
            sessionExpired: false,
            redirect: false,
            loadingChangePasswordProcess: false,
            language: localStorage.getItem('i18nextLng'),
            time: 0,
            dataUpdated: false,
            showJumbotron: true,
            isPasswordSecure: false
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
    }

    changeLanguageHandler(event) {
        i18n.changeLanguage(event);
    }

    doLogOut(){
        sessionStorage.clear();
        this.stopTimer();
        this.setState({redirect: true})
    }

    startTimer() {
        this.timer = setInterval(() => this.setState({
            time: this.state.time + 1
        }), 60000);
    }

    stopTimer() {
        clearInterval(this.timer)
    }

    getOauthToken(){
        var url_string = window.location.href;
        var url = new URL(url_string);
        var oauthTokenB64 = url.searchParams.get("t");
        this.setState({
            oauthTokenB64: oauthTokenB64
        });
    }

    getViDMobileUserGUIDFromOauthToken() {
        const {oauthTokenB64} = this.state;
        if (oauthTokenB64 !== null){
            var tokenParts = oauthTokenB64.split(".");
            var jsonTokenInfo = atob(tokenParts[1]);
            var tokenInfo = JSON.parse(jsonTokenInfo);
            return tokenInfo.sub;
        }
    }

    getEnvironmentFromOauthToken() {
        const {oauthTokenB64} = this.state;
        if (oauthTokenB64 !== null){
            var tokenParts = oauthTokenB64.split(".");
            var jsonTokenInfo = atob(tokenParts[1]);
            var tokenInfo = JSON.parse(jsonTokenInfo);
            return tokenInfo.env;
        }
    }

    handlePasswordChange() {
        const {password, passwordConfirmation} = this.state;
        const {t} = this.props;
        this.setState({loadingChangePasswordProcess: true});
        if (password !== passwordConfirmation) {
            this.setState({newPasswordErrorText: t('profile.password-error'), newPasswordError: true, loadingChangePasswordProcess: false});
        } else {
            this.updateUserPassword(password);
        }
    }

    updateUserPassword(password) {
        var _this = this;
        const {oauthTokenB64} = this.state;
        var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
        var ViDMobileUserEnvironment = this.getEnvironmentFromOauthToken();
        
        const env = ViDMobileUserEnvironment === "PREPRODUCTION" ? PRE : PRO;

        var vidCloudMgmtUrl = getVidCloudMgmtUrl(env);

        axios({
            method: 'put',
            url: vidCloudMgmtUrl + '/vidmobileusers/' + ViDMobileUserGUID + '/password',
            data: {"password": password},
            async: true,
            dataType: "json",
            contentType: 'application/json',
            crossDomain: true,
            headers: {'Authorization': 'Bearer ' + oauthTokenB64}
        })
            .then(res => {
                _this.setState({
                    dataUpdated: true,
                    newPasswordError: false,
                    loadingChangePasswordProcess: false,
                    password: '',
                    passwordConfirmation: '',
                });

                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                    _this.setState({sessionExpired: true, loadingChangePasswordProcess: false});
                    _this.startTimer();
                } else {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        loadingChangePasswordProcess: false
                    });
                }
            });
    }

    render() {
        const {t} = this.props;
        const {showError, errorType, errorData, sessionExpired, redirect, loadingChangePasswordProcess, language,
            time, dataUpdated, newPasswordError, newPasswordErrorText, showJumbotron} = this.state;
        var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';

        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        return (
            <div>
                <header className="App-header">
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
                        <Navbar.Brand>
                            <img
                                src={require('../../assets/images/logo_vidsigner_white.png')}
                                height="30"
                                width="auto"
                                alt="Validated ID logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    </Navbar>
                </header>
                {dataUpdated &&
                <div className="toast-container">
                    <Toast
                        onClose={() => this.setState({dataUpdated: false})}
                        show={dataUpdated}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('profile.data-updated')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('profile.data-updated-text')}</Toast.Body>
                    </Toast>
                </div>
                }
                {sessionExpired &&
                <div className="toast-container">
                    <Toast
                        show={sessionExpired}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.notification')}</strong>
                            <small>{timeText}</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p>{t('general.session-expired')}</p>
                            <Button
                                type="button"
                                className="btn btn-primary"
                                id="sign-all-docs"
                                onClick={() => this.doLogOut()}
                            >
                                {t('general.accept')}
                            </Button>
                        </Toast.Body>
                    </Toast>
                </div>
                }
                {showError &&
                <Toast
                    onClose={() => this.setState({showError: false})}
                    show={showError}
                    animation={true}
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        margin: '0 auto',
                        backgroundColor: 'rgba(255,255,255,1)',
                        border: 'none',
                        zIndex: 15
                    }}
                >
                    <Toast.Header
                        closeButton={true}
                    >
                        <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                    </Toast.Header>
                    <Toast.Body>{errorData}</Toast.Body>
                </Toast>
                }
                <Modal show={loadingChangePasswordProcess} onHide={() => this.handleClose()}>
                    <Modal.Header>
                        <Modal.Title>{t('profile.update-process')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingCircle />
                    </Modal.Body>
                </Modal>
                {showJumbotron &&
                <Jumbotron>
                    <MdClose className="close-jumbotron" onClick={() => this.setState({showJumbotron: !showJumbotron})}/>
                    <div className="jumbotron-content">
                        <h1>{t('recovery.recovery-title')}</h1>
                        <p>
                            {t('recovery.recovery-text')}
                        </p>
                    </div>
                </Jumbotron>
                }
                <div className="recovery-container">
                    {(newPasswordError) &&
                    <div className="error-container">
                        {newPasswordErrorText}
                    </div>
                    }
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="inputGroup-sizing-default">{t('recovery.recovery-new-password')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="password"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.password || ''}
                            onChange={e => this.setState({password: e.target.value, newPasswordError: false})}

                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="inputGroup-sizing-default">{t('recovery.recovery-new-password-confirmation')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="password"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.passwordConfirmation || ''}
                            onChange={e => this.setState({passwordConfirmation: e.target.value, newPasswordError: false})}
                        />
                    </InputGroup>
                    <SecurityValidation password={this.state.password} updateIsPasswordSecure={(isSecure) => {
                        this.setState({
                        isPasswordSecure: isSecure
                    })}}/>
                    <Button
                        disabled={!this.state.isPasswordSecure || this.state.password !== this.state.passwordConfirmation}
                        type="button"
                        id="handlePasswordChange"
                        className="btn btn-primary"
                        onClick={() => this.handlePasswordChange()}
                    >
                        {t('general.confirm')}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getOauthToken();
        var i18nextLng = localStorage.getItem('i18nextLng');
        if (i18nextLng !== null) {
            var lang = i18nextLng.split("-");
            this.changeLanguageHandler(lang[0]);
        }
        else {
            this.changeLanguageHandler('es');
        }

    }

}

export default withTranslation()(Recovery);