export const GET_HTTP_METHOD = "GET";
export const POST_HTTP_METHOD = "POST";
export const PUT_HTTP_METHOD = "PUT";
export const DELETE_HTTP_METHOD = "DELETE";

export const VIDMOBILEUSER_URI = '/vidmobileuser/';
export const SMS_URI = '/sms';
export const OAUTHCODE_URI = '/oauth/code';
export const OTP = 'OTP';

export const UANATACA_URI = '/vidmobileuser/signature/uanataca/';
export const UANATACA_URI_AUTH = '/uanatacaauth';
export const UANATACA = 'uanataca';
export const UANATACA_CERTIFICATE_PIN_KEY = "certificateUanatacaPin";
export const UANATACA_SHOW_MODAL_KEY_OTP = 'showUanatacaModalOtp';
export const UANATACA_SHOW_MODAL_KEY_PIN = 'showUanatacaModalPin';
export const UANATACA_LOADING_AUTHORIZE_KEY = 'loadingUTcert';
export const UANATACA_LOADING_UNAUTHORIZE_KEY = 'loadingUTUnAuth';
export const UANATACA_SEND_OTP = '/uanataca/otp';

export const SWISSCOM_URI_BASE = '/vidmobileuser/signature/swisscom/';
export const SWISSCOM_URI_SIGNREQUEST = '/signrequest';
export const SWISSCOM_URI_COMPLETE_SIGNATURE = '/completesignature/';
export const SWISSCOM = 'swisscom';
export const SWISSCOM_URI_SIGNREQUESTHASH ='/vidmobileuser/signature/swisscom/signrequesthash';
export const SWISSCOM_URI_COMPLETESIGNATUREHASH ='/vidmobileuser/signature/swisscom/completesignaturehash/';

export const REDTRUST_URI = '/vidmobileuser/signature/redtrust/';
export const REDTRUST_URI_AUTH = '/redtrustauth';
export const REDTRUST_URI_AUTH_STATUS = '/redtrustauth/status';
export const REDTRUST = 'redtrust';
export const REDTRUST_CERTIFICATE_PIN_KEY = "certificateRedTrustPin";
export const REDTRUST_SHOW_MODAL_KEY = 'showRedTrustModal';
export const REDTRUST_LOADING_AUTHORIZE_KEY = 'loadingRTcert';
export const REDTRUST_LOADING_UNAUTHORIZE_KEY = 'loadingRTUnAuth';
export const REDTRUST_LOADING_SYNCHRONIZE_KEY = 'loadingRTsync';

export const KEYVAULT_URI = '/vidmobileuser/signature/keyvault/';
export const KEYVAULT = 'keyvault';
export const KEYVAULT_CERTIFICATE_PIN_KEY = "certificateKeyVaultPin";
export const KEYVAULT_SEND_OTP_URI_BASE = '/vidmobilecertificate/';
export const KEYVAULT_SEND_OTP_URI_OTPSMS = '/otpsms';
export const KEYVAULT_SHOW_MODAL_KEY = 'showKeyVaultModal';
export const KEYVAULT_LOADING_DELETING_KEY = 'loadingDeletingProcess';
export const KEYVAULT_LOADING_UPLOAD_KEY = 'loadingKVcert';

export const NEBULA_URI = '/vidmobileuser/signature/nebula/';
export const NEBULA = 'nebula';
export const NEBULA_CERTIFICATE_PIN_KEY = "certificateNebulaPin";
export const NEBULA_URI_AUTH = '/nebulaauth';
export const NEBULA_URI_AUTHMIDDLE = '/nebulaauthmiddle';
export const NEBULA_URI_AUTHEND = '/nebulaauthend';
export const NEBULA_LOADING_KEY = 'loadingNB';

export const SOFTWARE_URI = '/vidmobileuser/signature/';
export const SOFTWARE = 'software';
export const SOFTWARE_CERTIFICATE_PIN_KEY = "certificateSoftwarePin";
export const SOFTWARE_SHOW_MODAL_KEY = "showSoftwareModal";

export const AUTOFIRMA_URI_BASE = '/localsignature/';
export const AUTOFIRMA_URI_SIGNREQUEST = '/certificate';
export const AUTOFIRMA_URI_PRESIGNATURE = '/presignature?SignatureId=';

export const FIRMAREMOTA_URI = '/vidmobileuser/signature/firmaremota/';
export const FIRMAREMOTA_URI_SHORT = '/vidmobileuser/firmaremota/';
export const FIRMAREMOTA = 'firmaremota';
export const FIRMAREMOTA_CERTIFICATE_PIN_KEY = "certificateFirmaRemotaPin";
export const FIRMAREMOTA_URI_AUTH = "/vidmobileuser/firmaremotaauth";
export const FIRMAREMOTA_URI_SESSION = "/vidmobileuser/firmaremotaauth/session/";
export const FIRMAREMOTA_SHOW_MODAL_KEY = 'showFirmaRemotaModal';
export const FIRMAREMOTA_URI_STATUS = "/vidmobileuser/firmaremotaauth/status";
export const UANATACA_URI_STATUS = "/uanatacaauth/status";
export const VALIDATEDID_URI_STATUS = "/validatedidauth/status";
export const FIRMAREMOTA_LOADING_AUTHORIZE_KEY = "loadingFRcert";
export const FIRMAREMOTA_LOADING_UNAUTHORIZE_KEY = "loadingFRUnAuth";


export const VALIDATEDID_URI = '/vidmobileuser/signature/validatedid/';
export const VALIDATEDID_URI_AUTH = '/validatedidauth';
export const VALIDATEDID = 'validatedid';
export const VALIDATEDID_CERTIFICATE_PIN_KEY = "certificateValidatedIdPin";
export const VALIDATEDID_SHOW_MODAL_KEY_OTP = 'showValidatedIdModalOtp';
export const VALIDATEDID_SHOW_MODAL_KEY_PIN = 'showValidatedIdModalPin';
export const VALIDATEDID_LOADING_AUTHORIZE_KEY = 'loadingVIcert';
export const VALIDATEDID_LOADING_UNAUTHORIZE_KEY = 'loadingVIUnAuth';
export const VALIDATEDID_SEND_OTP = '/validatedid/otp';

export const CERTS = '/certs/';
export const SESSION = '/session/';
export const DELETE_CERTIFICATE = '/vidmobileusers/';

export const LOADING_DELETING_PROCESS_KEY = 'loadingDeletingProcess';

export const PREVIEW = "PREVIEW";
export const DOCUMENTS_LIST = "DOCUMENTS_LIST";
