import React, { useState, Fragment } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { VALIDATEDID, VALIDATEDID_CERTIFICATE_PIN_KEY, VALIDATEDID_SHOW_MODAL_KEY_OTP, VALIDATEDID_SHOW_MODAL_KEY_PIN } from '../../services/signatureMethods/constants';

const ValidatedIdSignature = ({ showModalPin, showModalOtp, onClose, onSuccessOtp, onSuccess}) => {
    const { t } = useTranslation();
    const [certificatePin, setCertificatePin] = useState('');
    const [certificateOtp, setCertificateOtp] = useState('');

    return (
        <Fragment>
            <Modal show={showModalPin} onHide={() => {onClose(); setCertificatePin('')}}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.sw-pin')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="inputGroup-sizing-default">{t('general.pin')}:
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="password"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            autoComplete="new-password"
                            onChange={e => setCertificatePin(e.target.value)}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="reject" onClick={() => {onClose(); setCertificatePin('')}}>
                        {t('general.cancel')}
                    </Button>
                    <Button variant="primary" disabled={!certificatePin}
                        onClick={() => {onSuccess(VALIDATEDID, VALIDATEDID_SHOW_MODAL_KEY_PIN, VALIDATEDID_CERTIFICATE_PIN_KEY, certificatePin); setCertificatePin('')}}>
                        {t('general.confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalOtp} onHide={() => {onClose(); setCertificateOtp('')}}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.kv-otp')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="inputGroup-sizing-default">{t('general.otp')}:
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="password"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            autoComplete="new-password"
                            onChange={e => setCertificateOtp(e.target.value)}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="reject" onClick={() => {onClose(); setCertificateOtp('')}}>
                        {t('general.cancel')}
                    </Button>
                    <Button variant="primary" disabled={!certificateOtp}
                        onClick={() => {onSuccessOtp(VALIDATEDID, VALIDATEDID_SHOW_MODAL_KEY_PIN, VALIDATEDID_SHOW_MODAL_KEY_OTP, VALIDATEDID_CERTIFICATE_PIN_KEY, certificatePin, certificateOtp); setCertificateOtp('')}}>
                        {t('general.confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default ValidatedIdSignature