export const LOCAL = "LOCAL";
export const PRE = "PRE";

export const PRO = "PRO";

export const REJECTION_REASON_MIN_CHARACTERS = 1;

export const SPANISH = "Español";
export const CATALAN = "Català";
export const ENGLISH = "English";
export const GERMAN = "Deutsch";
export const FRENCH = "Français";

export const VIDMOBILEUSERID = "vidmobileuserid";