import React, { useState } from "react";
import "./ChangePassword.css";
import { Navbar } from "react-bootstrap";
import LanguageSwitch from "../../components/LanguageSwitch/LanguageSwitch";
import securityIcon from '../../assets/icons/security.svg';
import logoVidSigner from '../../assets/images/logo_vidsigner_white.png';
import { SecurityValidation } from "../../components/SecurityValidation/SecurityValidation";
import { getVidCloudMgmtUrl } from "../../services/environment/environmentService";
import axios from "axios";
import * as oauthService from '../../services/oauth/oauthService';
import { useTranslation } from "react-i18next";

export const ChangePasswordPage = (props) => {

  const { t } = useTranslation();
  const { location } = props;
  const [formData, setFormData] = useState({
    newPassword: '',
    newPasswordConfirm: '',
  });

  const [loading, setLoading] = useState(false);

  const { newPassword, newPasswordConfirm } = formData;

  const inputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const changePassword = (newPassword) => {
    setLoading(true);
    var oauthTokenB64 = oauthService.oauthHeaders();
    var viDMobileUser = getViDMobileUserGUIDFromOauthToken(oauthTokenB64.headers.Authorization);
    var vidCloudMgmtUrl = getVidCloudMgmtUrl();

    axios({
      method: 'put',
      url: vidCloudMgmtUrl + '/vidmobileusers/' + viDMobileUser + '/password',
      data: { "password": newPassword },
      async: true,
      dataType: "json",
      contentType: 'application/json',
      crossDomain: true,
      headers: {'Authorization': oauthTokenB64.headers.Authorization}
    })
      .then(res => {
        setLoading(false);
        window.location.href = '/documents';
      })
      .catch(function (error) {
        setLoading(false);
      })
  }

  const getViDMobileUserGUIDFromOauthToken = (oauthTokenB64) => {
    if (oauthTokenB64 !== null) {
      var cleanToken = oauthTokenB64.replace("Bearer ", "");
      var tokenParts = cleanToken.split(".");
      var jsonTokenInfo = atob(tokenParts[1]);
      var tokenInfo = JSON.parse(jsonTokenInfo);
      return tokenInfo.sub;
    }
  }

  const [isPasswordSecure, setIsSecure] = useState(false);

  return (
    <>
      <header className="App-header change-password-header">
        <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
          <Navbar.Brand>
            <img
              src={logoVidSigner}
              height="30"
              width="auto"
              alt="Validated ID logo"
            />
          </Navbar.Brand>
          <LanguageSwitch />
        </Navbar>
      </header>
      <div className="change-password-block">

        <div className="header">
          <img src={securityIcon} className="login-icon" alt="Welcome!" />
          <div className="title-container">
            <h3 className="main-title">{t('change-password.hello')}, {location.state.username}</h3>
          </div>
        </div>
        <div className="sub-header">
          <div className="title-container">
            <h6 className="subtitle">{t('change-password.subtitle')}</h6>
          </div>
        </div>
        <div className="form-container">
          <input
            type="password"
            name="newPassword"
            placeholder={t('change-password.new-password')}
            className="form-control"
            value={newPassword}
            onChange={inputChange}
          />
          <input
            type="password"
            name="newPasswordConfirm"
            placeholder={t('change-password.repeat-new-password')}
            className="form-control"
            value={newPasswordConfirm}
            onChange={inputChange}
          />
          <SecurityValidation password={newPassword} updateIsPasswordSecure={(isSecure) => {
            setIsSecure(isSecure)
          }} />
          <button
            disabled={!isPasswordSecure || newPassword !== newPasswordConfirm || loading}
            className="btn btn-primary"
            onClick={() => changePassword(newPassword)}
          >
            {t('change-password.enter')}
          </button>
        </div>
      </div>
    </>
  );
}