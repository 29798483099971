import axios from 'axios';
import * as environmentService from '../environment/environmentService';
import * as oauthService from '../oauth/oauthService';

const documentRenderService = {
    renderDocumentPages: (docGuid, nextPage) => {
        return axios.get(`${environmentService.getVidCloudPrivUrl()}/documentrendered/${docGuid}/remote/pages/${nextPage}`, oauthService.oauthHeaders());
    },
    renderApprovalDocumentPages: (approverGuid, nextPage) => {
        return axios.get(`${environmentService.getVidCloudPrivUrl()}/approvaldocuments/${approverGuid}/documentrendered/pages/${nextPage}`, oauthService.oauthHeaders());
    },
}

export default documentRenderService;