import React, { useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { KEYVAULT, KEYVAULT_CERTIFICATE_PIN_KEY, KEYVAULT_SHOW_MODAL_KEY } from '../../services/signatureMethods/constants';

const KeyVaultSignature = ({ show, onClose, onSuccess, certificateSelected }) => {
  const { t } = useTranslation();
  const [certificatePin, setCertificatePin] = useState('');

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {certificateSelected.AuthenticationType === "PIN" &&
          <Modal.Title>{t('documents.kv-pin')}</Modal.Title>}
        {certificateSelected.AuthenticationType === "SMS" &&
          <Modal.Title>{t('documents.kv-otp')}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            {certificateSelected.AuthenticationType === "PIN" &&
              <InputGroup.Text id="inputGroup-sizing-default">{t('general.pin')}: </InputGroup.Text>}
            {certificateSelected.AuthenticationType === "SMS" &&
              <InputGroup.Text id="inputGroup-sizing-default">{t('general.otp')}: </InputGroup.Text>}
          </InputGroup.Prepend>
          <Form.Control
            required
            type="password"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            autoComplete="new-password"
            value={certificatePin}
            onChange={e => setCertificatePin(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button className="reject" onClick={onClose}>
          {t('general.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onSuccess(KEYVAULT, KEYVAULT_SHOW_MODAL_KEY, KEYVAULT_CERTIFICATE_PIN_KEY, certificatePin)}>
          {t('general.sign')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default KeyVaultSignature