import React from 'react'
import { css } from "@emotion/core";
import {FadeLoader} from "react-spinners";
import PropTypes from 'prop-types'
import './LoadingCircle.css'

const override = css`
    display: block;
    margin: 0 auto;
`;

const LoadingCircle = ({centerVertically}) => {
    return (
        <div className={"custom-loading " + (centerVertically ? "center-vertically" : "")}>
            <FadeLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={'#00bf71'}
                loading={true}
            />
        </div>
    )
}

LoadingCircle.propTypes = {
    centerVertically: PropTypes.bool
}

export default LoadingCircle
