import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import LoadingCircle from '../../LoadingCircle'

function RejectingProcessModal({ show }) {
    const { t } = useTranslation('translation');

    return (
        <Modal data-testid="modal-open" show={show} >
            <Modal.Header>
                <Modal.Title>{t('general.reject-process')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingCircle />
            </Modal.Body>
        </Modal>
    )
}

RejectingProcessModal.propTypes = {
    show: PropTypes.bool.isRequired
}

export default RejectingProcessModal

