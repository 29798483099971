import React from 'react';
import { withTranslation } from 'react-i18next';

import { AdvancedSearch } from './AdvancedSearch/AdvancedSearch';

import './SearchBar.css';

import minus from './../../../assets/icons/minus.svg';
import plus from './../../../assets/icons/plus.svg';

const SearchBar = ({
  updateState,
  t,
  selectedCert,
  showTag,
  showCertificate,
  showSenderNameColumn,
  docsSelectedGUI,
  showAdvancedSearch,
  setShowAdvancedSearch
}) => {
  const handleClick = () => {
    sessionStorage.setItem('showAdvancedSearch', !showAdvancedSearch);
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  return (
    <>
      <div className="bar-container">
        <button className="advanced-search" onClick={handleClick}>
          <span>{t('documents.advanced-search')}</span>
          <div>
            {showAdvancedSearch ? <img src={minus} alt="Hide"/> : <img src={plus} alt="Show"/>}
          </div>
        </button>
      </div>
      {
        showAdvancedSearch ? <AdvancedSearch
          updateState={updateState}
          previousCertSelected={selectedCert}
          showTag={showTag}
          showSenderNameColumn={showSenderNameColumn}
          showCertificate={showCertificate}
          docsSelectedGUI={docsSelectedGUI}
        /> : null
      }
    </>
  );
}

export default withTranslation()(SearchBar);