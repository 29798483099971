import React from 'react'
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

const SoftwareCertificates = ({ parentState, onCertificateDelete}) => {
    const { t } = useTranslation();
    const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;
    const {
        certificatesOrdered: { Software }
    } = parentState;

    const softwareColumns = [
        {
            dataField: 'certalias',
            text: t('certificates.certificate'),
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerClass1',
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'ExpirationDate',
            text: t('certificates.certificate-date'),
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerClass2',
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'ActionButton',
            text: '',
            align: 'center',
            headerAlign: 'left',
            isDummyField: true,
            style: { verticalAlign: 'middle' },
            headerClasses: 'headerClass3',
            formatter: (cellContent, row) => {
                return (
                    <Button
                        type="button"
                        className="btn reject"
                        onClick={() => onCertificateDelete(row)}
                    >
                        {t('certificates.certificate-delete')}
                    </Button>
                );
            }
        }
    ];

    return (
        <div className="table-container">
            {Software.length === 0 &&
                certsNotAvailable
            }
            {Software.length > 0 &&
                <ToolkitProvider
                    keyField="GUID"
                    data={Software}
                    columns={softwareColumns}
                >
                    {
                        props => (
                            <div>
                                <BootstrapTable
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            }
        </div>
    )
}

export default SoftwareCertificates