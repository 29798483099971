import React, { Component } from 'react';
import './ApprovalPreview.css';
import { withTranslation } from 'react-i18next';
import { Button, InputGroup, ListGroup, Modal, Toast, FormControl, Tabs, Tab, Col, Nav, Row } from "react-bootstrap";
import iconAttachedDocs from '../../assets/icons/attachedDocs.svg';
import iconBackButton from '../../assets/icons/backButton.svg';
import iconNextButton from '../../assets/icons/nextButton.svg';
import squareEyeBox from '../../assets/icons/square-eye.svg';
import squareDocBox from '../../assets/icons/square-doc.svg';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Utils from '../../services/Utils';
import SigningProcessModal from '../Modals/SigningProcessModal';
import RejectingProcessModal from '../Modals/RejectingProcessModal';
import LoadingCircle from '../LoadingCircle';
import { getVidCloudPrivUrl, getVidCloudMgmtUrl } from '../../services/environment/environmentService';
import { REJECTION_REASON_MIN_CHARACTERS } from '../../assets/constants';

const ipLocation = require("iplocation");
const publicIp = require('public-ip');

class ApprovalPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
      environment: sessionStorage.getItem('environment'),
      language: localStorage.getItem('i18nextLng'),
      showSignModal: false,
      showRejectModal: false,
      time: 0,
      loadingApprovalProcess: false,
      loadingRejectingProcess: false,
      sessionOpened: false,
      showError: false,
      errorData: '',
      errorType: '',
      rejectReason: '',
      actualPage: 1,
      actualAttachedPage: 1,
      sideMenuOption: 1,
      tabKey: 0,
      approvedDoc: false,
      clientIp: '',
      ipData: '',
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.actualPage = this.actualPage.bind(this);
    this.actualAttachedPage = this.actualAttachedPage.bind(this);
    this.handleGenericError = this.handleGenericError.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSign = this.handleSign.bind(this);
  }

  focusInput(input) {
    /**The stimeout is necesary to wait until the DOM is loaded */
    if (input) setTimeout(function () { input.focus(); }, 1);
  };

  handleClose() {
    this.setState({
      showSignModal: false,
      showRejectModal: false,
      showAdditionalSignaturesDisclaimer: false,
      showChallengeError: false,
      textAdditionalSignaturesDisclaimer: '',
      titleAdditionalSignaturesDisclaimer: ''
    })
  }

  doLogOut() {
    sessionStorage.clear();
    this.stopTimer();
    this.setState({ redirect: true })
  }

  startTimer() {
    this.timer = setInterval(() => this.setState({
      time: this.state.time + 1
    }), 60000);
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  loadUserData() {
    this.setState({ loading: true });
    const _this = this;
    const { oauthTokenB64 } = this.state;


    var config = {
      headers: {
        'Authorization': 'Bearer ' + oauthTokenB64
      }
    };
    return new Promise(function (resolve, reject) {
      axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
        .then(res => {
          resolve(res.data);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            _this.setState({ sessionExpired: true });
            _this.startTimer();
          } else {
            _this.setState({
              errorType: error.response.status,
              errorData: error.response.data,
              showError: true
            });
          }
          reject(error);
        });
    });
  }

  rejectDoc() {
    const _this = this;
    const { oauthTokenB64, rejectReason } = this.state;
    const { ApproverGuid, DocGuid } = this.props.selectedApproval;
    this.setState({
      loadingRejectingProcess: true,
      showRejectModal: false,
    });

    axios({
      method: 'post',
      url: getVidCloudPrivUrl() + '/approvaldocuments/' + ApproverGuid + '/rejected',
      data: {
        RejectedReason: rejectReason
      },
      async: true,
      dataType: "json",
      contentType: 'application/json',
      crossDomain: true,
      headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
    })
      .then(res => {
        if (res.status === 200) {
          Utils.removeItemFromSelectedDocsInSessionByGuid(DocGuid);
          _this.setState({ loadingRejectingProcess: false });
          _this.props.showRejectedDocHandler();
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
          _this.setState({ sessionExpired: true });
          _this.startTimer();
        } else {
          _this.setState({
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true,
            loadingRejectingProcess: false
          });
          _this.props.handlePreviouslyRejectedDocError(error, true);
        }
      });
  };

  handleSessionExpiredError(status) {
    if (status === 401) {
      /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
      this.setState({ sessionExpired: true });
      this.startTimer();
    }
  }

  handleGenericError(error) {
    this.handleSessionExpiredError(error.response.status);

    if (error.response.status !== 401) {
      this.setState({
        errorType: error.response.status,
        errorData: error.response.data,
        showError: true
      });
    }
  }

  refreshView() {
    this.props.showApprovedDocHandler();
  }

  getClientData() {
    const _this = this;
    (async () => {
      const clientIp = await publicIp.v4();
      const ipData = await ipLocation(clientIp);
      _this.setState({
        clientIp: clientIp,
        ipData: ipData
      });
    })();
  }

  actualPage() {
    const { renderedApproval, renderDocument } = this.props;

    const renderedPages = renderedApproval.length;

    var container_height = document.getElementsByClassName("pdf-container")[0].offsetHeight;
    var image_height = document.getElementsByClassName("pdf-images")[0].offsetHeight;
    var scroll_height = image_height * renderedPages;

    /**Scroll position*/
    var scroll_position = document.getElementsByClassName("pdf-container")[0].scrollTop;
    var scroll_max_value = scroll_height - container_height;
    var scroll_percentage = Math.abs((scroll_position / scroll_max_value) * 100);

    var page_ratio = 100 / renderedPages; /**6,25  */
    var actual_page = scroll_percentage / page_ratio; /**Por cada 6,25 saldrá 1 */
    var max_page_ratio = 100 / page_ratio; /**Número de páginas en total */

    /**Sumamos 1 de base para no mostrar el valor 0 */
    var actual_page_absolute = 1 + Math.floor(actual_page)

    /**Comprobamos que no llegue al máximo para no mostrar el máximo de páginas +1 y le adjudicamos el valor máximo */
    if (actual_page_absolute >= max_page_ratio) {
      actual_page_absolute = renderedPages;
    }

    if (actual_page_absolute >= renderedPages - 1) renderDocument();

    this.setState({ actualPage: actual_page_absolute })
  }

  actualAttachedPage() {
    /**REVISAR SI SE PUEDE USAR EL MISMO MÉTODO DE ACTUAL PAGE */
    const { docsAttachedRendered } = this.props;

    var container_height = document.getElementsByClassName("pdf-container-2")[0].offsetHeight;
    var image_height = document.getElementsByClassName("attached-pdf-images")[0].offsetHeight;
    var scroll_height = image_height * docsAttachedRendered.length;

    /**Scroll position*/
    var scroll_position = document.getElementsByClassName("pdf-container-2")[0].scrollTop;
    var scroll_max_value = scroll_height - container_height;
    var scroll_percentage = Math.abs((scroll_position / scroll_max_value) * 100);

    var page_ratio = 100 / docsAttachedRendered.length; /**6,25  */
    var actual_page = scroll_percentage / page_ratio; /**Por cada 6,25 saldrá 1 */
    var max_page_ratio = 100 / page_ratio; /**Número de páginas en total */

    /**Sumamos 1 de base para no mostrar el valor 0 */
    var actual_page_absolute = 1 + Math.floor(actual_page)

    /**Comprobamos que no llegue al máximo para no mostrar el máximo de páginas +1 y le adjudicamos el valor máximo */
    if (actual_page_absolute >= max_page_ratio) {
      actual_page_absolute = docsAttachedRendered.length;
    }
    this.setState({ actualAttachedPage: actual_page_absolute })
  }

  changeAttachedTab(k) {
    this.setState({
      tabKey: k,
      actualAttachedPage: 1
    });
    document.getElementsByClassName("pdf-container-2")[0].scrollTop = 0;
  }

  handleSign() {
    const _this = this;
    const { oauthTokenB64 } = this.state;
    const { ApproverGuid, DocGuid } = this.props.selectedApproval;
    this.setState({
      loadingApprovalProcess: true,
    });

    axios({
      method: 'post',
      url: getVidCloudPrivUrl() + '/approvaldocuments/' + ApproverGuid + '/accepted',
      async: true,
      dataType: "json",
      contentType: 'application/json',
      crossDomain: true,
      headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
    })
      .then(res => {
        if (res.status === 200) {
          Utils.removeItemFromSelectedDocsInSessionByGuid(DocGuid);
          _this.setState({ loadingApprovalProcess: false, approvedDoc: true, });
          _this.props.showApprovedDocHandler();
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
          _this.setState({ sessionExpired: true });
          _this.startTimer();
        } else {
          _this.setState({
            errorType: error.response.status,
            errorData: error.response.data,
            showError: true,
            loadingApprovalProcess: false
          });
          _this.props.handlePreviouslyRejectedDocError(error, true);
        }
    })
  }

  render() { 
    const { t, renderedApproval, selectedApproval, pendingApprovals, documentSigners, documentAttatchmentsList, attDocArray, docsAttachedRendered, totalPages, checkedApprovals, updateSelectedApprovals } = this.props;
    const {
      showSignModal, showRejectModal, sessionExpired, language, time, certificatesList, loadingApprovalProcess, loadingRejectingProcess,
      showError, errorType, errorData, actualPage, tabKey, actualAttachedPage, rejectReason, approvedDoc,
    } = this.state;
    const isApprovalSelected = checkedApprovals?.includes(selectedApproval.DocGuid);

    let images = renderedApproval.map((renderedImage, index) => {
      return <img
        key={index}
        alt="renderedImage"
        src={`data:image/jpeg;base64,${renderedImage}`}
        className="pdf-images"
        id={`pdf-page-${index + 1}`}
      />
    });
    let attachedImages = docsAttachedRendered.map((renderedImage, index) => {
      return <img
        key={index}
        alt="attachedRenderedImage"
        src={`data:image/jpeg;base64,${renderedImage}`}
        className="attached-pdf-images"
      />
    });

    const onCheck = () => {
      updateSelectedApprovals(selectedApproval, !isApprovalSelected);
    };

    var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';

    const columns = [{
      dataField: 'SignerName',
      text: t('documents.document-signers-table-name'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'UploadTime',
      text: t('documents.document-signers-table-delivery-date'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'OperationTime',
      text: t('documents.document-signers-table-signature-date'),
      align: 'left',
      headerAlign: 'left'
    }, {
      dataField: 'SignatureStatus',
      text: t('documents.document-signers-table-status'),
      headerAlign: 'center',
      formatter: (cellContent, row, rowIndex) => {
        if (cellContent !== null) {
          if (cellContent === 'Signed') {
            return <div className="signedCellbox">{cellContent}</div>
          }
          if (cellContent === 'Unsigned') {
            return <div className="unsignedCellbox">{cellContent}</div>
          }
          if (cellContent === 'Rejected') {
            return <div className="rejectedCellbox">{cellContent}</div>
          }
        }
        else {
          return <div className="undefinedCellbox">Undefined</div>
        }
      }
    }];

    const isInvalidRejectionReason = rejectReason.length < REJECTION_REASON_MIN_CHARACTERS;

    const docIndex = pendingApprovals.findIndex((doc) => doc.DocGuid === selectedApproval.DocGuid);
    const isLastIndex = docIndex === pendingApprovals.length - 1;

    return (
      <div>
        <Toast
          onClose={() => this.setState({ approvedDoc: false })}
          show={approvedDoc}
          delay={3000}
          autohide
          style={{
              position: 'absolute',
              left: 0,
              right: 0,
              margin: '0 auto',
              backgroundColor: 'rgba(255,255,255,1)',
              border: 'none',
              zIndex: 10
          }}
      >
          <Toast.Header
              closeButton={false}
          >
              <strong className="mr-auto">Documento aprobado</strong>
          </Toast.Header>
          <Toast.Body>Documento aprobado con éxito</Toast.Body>
      </Toast>
        {showError &&
          <Toast
            onClose={() => this.setState({ showError: false })}
            show={showError}
            animation={true}

            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              margin: '0 auto',
              backgroundColor: 'rgba(255,255,255,1)',
              border: 'none',
              zIndex: 10
            }}
          >
            <Toast.Header
              closeButton={true}
            >
              <strong className="mr-auto">{t('general.error')} {errorType}</strong>
            </Toast.Header>
            <Toast.Body>{errorData}</Toast.Body>
          </Toast>
        }
        {sessionExpired &&
          <div className="toast-container">
            <Toast
              show={sessionExpired}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: '0 auto',
                backgroundColor: 'rgba(255,255,255,1)',
                border: 'none',
                zIndex: 10
              }}
            >
              <Toast.Header
                closeButton={false}
              >
                <strong className="mr-auto">{t('general.notification')}</strong>
                <small>{timeText}</small>
              </Toast.Header>
              <Toast.Body>
                <p>{t('general.session-expired')}</p>
                <Button
                  type="button"
                  className="btn btn-primary"
                  id="sign-all-docs"
                  onClick={() => this.doLogOut()}
                >
                  {t('general.accept')}
                </Button>
              </Toast.Body>
            </Toast>
          </div>
        }
        {(renderedApproval.length === 0) &&
          <LoadingCircle centerVertically={true} />
        }
        {(renderedApproval.length > 0) &&
          <div>
              <div className="back-container" onClick={this.props.viewApprovalHandler}>
                <span><img src={iconBackButton} alt="back button" className="back-button" /> <span className="back-button-text">{t('documents.document-back-button')}</span></span>
              </div>
            {!isLastIndex &&
              <div className="next-container" onClick={this.props.seeNextApproval}>
                <span className="back-button-text"> {t('documents.document-next-button')} </span>&nbsp;&nbsp;<span><img src={iconNextButton} alt="back button" className="back-button" /> </span>
              </div>
            }
            <Tabs defaultActiveKey="document" id="uncontrolled-tab-example">
              <Tab eventKey="document" title={<span>{t('approvals.approve-document')}</span>}>
              <div className="document-preview-header">
                <div className="data-group">
                  <img src={squareEyeBox} alt="Eye" className="" />
                  <label className="document-preview-title">{t('approvals.approver-read')}</label>
                </div>
                <div className="data-group">
                  <img src={squareDocBox} alt="Doc" className="" />
                  <label className="document-preview-name">{selectedApproval.FileName}</label>
                </div>
                <label className="check-container">{t('approvals.select')}
                  <input onChange={onCheck} type="checkbox" checked={isApprovalSelected}/>
                  <div className='checkmark'></div>
                </label>
                <span className="document-preview-count">{actualPage}/{totalPages}</span>
              </div>
                <div className="document-preview-container">
                  <Modal show={showSignModal} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('documents.cert-type-selection')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ListGroup defaultActiveKey="#link1">
                        {certificatesList}
                      </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="reject"
                        onClick={() => this.handleClose()}
                        style={{ margin: 'auto' }}
                      >
                        {t('general.cancel')}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showRejectModal} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('documents.reject-document')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{t('documents.reject-document-question')}</p>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>{t('documents.reject-reason')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          as="textarea"
                          maxLength="450"
                          placeholder={t('documents.reject-reason-placeholder')}
                          value={rejectReason || ''}
                          onChange={e => this.setState({ rejectReason: e.target.value })}
                          className="reject-reason"
                        />
                      </InputGroup>
                      {
                        isInvalidRejectionReason && <span className="rejection-error">
                          {t("documents.rejection-reason-validation")}
                        </span>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="centered-buttons">
                        <Button
                          className="reject"
                          onClick={() => this.handleClose()}
                        >
                          {t('general.cancel')}
                        </Button>
                        <Button
                          disabled={isInvalidRejectionReason}
                          variant="primary"
                          onClick={() => this.rejectDoc()}
                          style={{ marginLeft: 20 }}
                        >
                          {t('general.confirm')}
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                    
                  <SigningProcessModal
                    showModal={loadingApprovalProcess}
                    showProcess={true}
                    signedDocs={0}
                    selectedDocs={1}
                    isApprovals={true}
                    />

                  <RejectingProcessModal
                    show={loadingRejectingProcess} />

                 
                  <div className="pdf-container">
                    {images}
                  </div>
                </div>

               
                  <div className="buttons-container">
                    <Button
                      type="button"
                      id="rejectModalbutton"
                      className="action-button reject"
                      onClick={() => this.setState({ showRejectModal: true })}
                    >
                      {t('general.reject')}
                    </Button>
                    <Button
                      type="button"
                      id="signModalButton"
                      className="btn btn-primary action-button capitalize"
                      onClick={this.handleSign}
                    >
                      {t('approvals.approve')}
                    </Button>
                  </div>
              </Tab>

              <Tab eventKey="signers" title={<span>{t('documents.document-tab-signers')}</span>}>
                <div className="signers-table-container">
                  <ToolkitProvider
                    keyField="GUID"
                    data={documentSigners}
                    columns={columns}
                    bootstrap4
                  >
                    {
                      props => (
                        <div>
                          <BootstrapTable
                            hover
                            striped
                            bordered={false}
                            {...props.baseProps}
                          />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </Tab>

              <Tab eventKey="attachments" title={<span><img src={iconAttachedDocs} alt="" className="tab-icons" /> {t('documents.document-tab-attachments')}</span>} tabClassName={documentAttatchmentsList.length === 0 ? 'tab-hide' : ''}>
                <Tab.Container
                  defaultActiveKey={tabKey}
                  onSelect={k => this.changeAttachedTab(k)}
                >
                  <Row bsPrefix="attachments-row row">
                    <Col sm={3} bsPrefix="left-side-menu-col col">
                      <Nav variant="pills" className="flex-column">
                        {attDocArray}
                      </Nav>
                    </Col>
                    <Col sm={9} bsPrefix="right-side-menu-col col">
                      <Tab.Content bsPrefix="tab-content tab-content3">
                        <Tab.Pane eventKey={tabKey}>
                          {documentAttatchmentsList.length > 0 &&
                            <div className="attachment-container">
                              <div className="document-preview-container">
                                <div className="document-preview-header-2">
                                  <h1 className="document-preview-title">{t('documents.document-attachment-preview-title')}</h1>
                                  <div className="document-preview-subcontainer">
                                    <h2 className="document-preview-name">{documentAttatchmentsList[tabKey].FileName}</h2>
                                    <span
                                      className="document-preview-count">{actualAttachedPage}/{attachedImages.length}</span>
                                  </div>
                                </div>
                                <div className="pdf-container-2" id="pdf-container-2-id">
                                  {attachedImages}
                                </div>
                              </div>
                            </div>
                          }
                          {documentAttatchmentsList.length === 0 &&
                            <div className="document-preview-container">
                              <p>No hay documentos adjuntos</p>
                            </div>
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Tab>
            </Tabs>
          </div>
        }
      </div>
    );
  }

  componentWillUnmount() {
    document.getElementsByClassName('pdf-container')[0].removeEventListener("scroll", this.actualPage);
    if (this.props.documentAttatchmentsList.length > 0) {
      document.getElementsByClassName('pdf-container-2')[0].removeEventListener("scroll", this.actualAttachedPage);
    }
  }

  componentDidMount() {
    Utils.appendScript("/assets/js/autoscript.js");
    Utils.appendScript("/assets/js/forge.min.js");
    this.getClientData();
    document.getElementsByClassName('pdf-container')[0].addEventListener("scroll", this.actualPage);
    if (this.props.documentAttatchmentsList.length > 0) {
      document.getElementsByClassName('pdf-container-2')[0].addEventListener("scroll", this.actualAttachedPage);
    }
  }

}

export default withTranslation()(ApprovalPreview);