import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PRE, PRO } from '../../../assets/constants';
import './AdvancedOptionsSelect.css';

export const AdvancedOptionsSelect = ({ shouldHideContent }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [environment, setEnvironment] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const url = new URL(urlString);
    const userToken = url.searchParams.get("usertoken");
    const envParam = url.searchParams.get("env");

    if (userToken && envParam) return;
    
    const { host } = window.location;

    const isPRE = host.includes("pre");
    const env = isPRE ? PRE : PRO;

    sessionStorage.setItem("environment", env);
    setEnvironment(env);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = value => {
    sessionStorage.setItem("environment", value);
    setEnvironment(value);
  }

  const urlString = window.location.href;
  const url = new URL(urlString);
  const env = url.searchParams.get("env");

  if (env) return <></>;

  return (
    <>
      <div className={`advanced ${showOptions ? null : "with-margin-bottom"} ${shouldHideContent ? "mobile-hideable" : "mobile-show"}`} onClick={() => setShowOptions(!showOptions)}>
        <span>{t("vid-chain-login.advanced-options")}</span>
        <span>{!showOptions ? <>&#9656;</> : <>&#9662;</>}</span>
      </div>
      {
        showOptions &&
        <div className={`environment-container  ${shouldHideContent ? "mobile-hideable" : "mobile-show"}`}>
          <label htmlFor="environment">{t('login.environment')}</label>
          <div className="options-container">
            <button 
              className={`${environment === PRE ? "is-environment-selected" : null}`} 
              onClick={() => handleChange("PRE")}>PRE</button>
            <button 
              className={`${environment === PRO ? "is-environment-selected" : null}`} 
              onClick={() => handleChange("PRO")}>PRO</button>
          </div>
        </div>
      }
    </>
  );
}