import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import CustomToast from '../../../components/Documents/CustomToast/CustomToast';
import { CLIENT_ID, CLIENT_SECRET } from '../../../services/vid-config';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';
import whiteThunder from './../../../assets/icons/white-thunder.png';
import background from './../../../assets/images/background-new-login.png';
import microsoft from './../../../assets/icons/microsoft.png';
import vidwallet from './../../../assets/icons/vidwallet.png';
import google from './../../../assets/icons/google.png';
import vidLogo from './../../../assets/images/new-vid-logo-white.png';
import vidWalletImg from './../../../assets/images/new-login.png';
import { getTargetName, getVidCloudMgmtUrl, getVidCloudPrivUrl } from './../../../services/environment/environmentService';

import "./RegularLogin.css";
import { DEFAULT_APPEARANCE_CONFIG } from '../../../config/appearance';
import LanguageSwitch from '../../../components/LanguageSwitch/LanguageSwitch';
import { AdvancedOptionsSwitch } from '../AdvancedOptionsSwitch/AdvancedOptionsSwitch';

export const RegularLogin = () => {
  const [formData, setFormData] = useState({
    user: "",
    password: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loginIsInvalid, setLoginIsInvalid] = useState(false);
  const [userAuth, setUserAuth] = useState(false);
  const [token, setToken] = useState(null);
  const [resetPasswordRedirect, setResetPasswordRedirect] = useState(false);
  const [resetPasswordUsername, setResetPasswordUsername] = useState(null);
  const [documentsRedirect, setDocumentsRedirect] = useState(false);
  const [authCertsRedirect, setAuthCertsRedirect] = useState(false);
  const [wantsToResetPassword, setWantsToResetPassword] = useState(false);
  const { user, password } = formData;
  const [samlLogin, setSamlLogin] = useState(false);
  const [regularLogin, setRegularLogin] = useState(true);
  const [userSaml, setUserSaml] = useState('');
  const [environment, setEnvironment] = useState('');

  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const getEnvironmentFromOauthToken = (userToken) => {
    const tokenParts = userToken.split(".");
    const jsonTokenInfo = atob(tokenParts[1]);
    const tokenInfo = JSON.parse(jsonTokenInfo);
    return tokenInfo.env;
  }

  const getParams = () => {
    // const { authCerts } = this.state;
    const authCerts = null;

    const urlString = window.location.href;
    const url = new URL(urlString);
    const userToken = url.searchParams.get("usertoken");
    const urlReturn = url.searchParams.get("urlreturn");
    let environment = url.searchParams.get("env");

    if (environment !== null) {
      environment = environment.toUpperCase();
    }

    let environmentAPP = process.env.REACT_APP_ENVIRONMENT;
    if (environment === "PRE")  environmentAPP = "PRE";
    setEnvironment(environmentAPP);
    sessionStorage.setItem("environment", environmentAPP);
    const responseType = url.searchParams.get("response_type");
    const clientId = url.searchParams.get("client_id");
    const redirectUri = url.searchParams.get("redirect_uri");
    const scope = url.searchParams.get("scope");
    const lang = url.searchParams.get("lang");
    const state = url.searchParams.get("state");

    const hash = url.searchParams.get("hash");
    const hashalgo = url.searchParams.get("hashalgo");
    const token = url.searchParams.get("token");

    sessionStorage.setItem('urlReturn', urlReturn);
    sessionStorage.setItem('responseType', responseType);

    if (responseType !== null || authCerts !== "true") {
      sessionStorage.setItem('responseType', responseType);
      sessionStorage.setItem('clientId', clientId);
      sessionStorage.setItem('redirectUri', redirectUri);
      sessionStorage.setItem('scope', scope);
      sessionStorage.setItem('lang', lang);
      sessionStorage.setItem('state', state);
      sessionStorage.setItem('hash', hash);
      sessionStorage.setItem('hashalgo', hashalgo);
      sessionStorage.setItem('token', token);
    }

    if (userToken && authCerts !== "true") {
      sessionStorage.setItem("AccessMethod", "SSO");
      sessionStorage.setItem('userToken', "true");
      const ViDMobileUserEnvironment = getEnvironmentFromOauthToken(userToken);
      const userEnvironment = ViDMobileUserEnvironment === "PREPRODUCTION" ? 'PRE' : 'PRO';
      setToken(userToken);
    }

    if (responseType !== null || authCerts === "true") {
      sessionStorage.setItem('userAuth', "true");
      setUserAuth(true);
      if (lang !== null) {
        i18n.changeLanguage(lang);
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  const getParamsIE = () => {
    if (window.location.search !== null && window.location.search !== "" && window.location.search.length > 1) {
      var params = window.location.search.substr(1).split("&");
      if (params.length > 0) {
        var genericParamArray = params[0].split("=");
        if (genericParamArray[0] === 'usertoken') { //login by token
          var userTokenArray = params[0].split("=");
          var environmentArray1 = params[1].split("=");
          if (userTokenArray !== null && userTokenArray.length === 2) {
            if (userTokenArray[0] === 'usertoken') {
              var oauthTokenB64 = userTokenArray[1];
            }
          }
          if (environmentArray1 !== null && environmentArray1.length === 2) {
            if (environmentArray1[0] === 'env') {
              var environment1 = environmentArray1[1].toUpperCase();
            }
          }
          if (params.length > 2) {
            var urlReturnArray = params[2].split("=");
            if (urlReturnArray !== null && urlReturnArray.length > 2) {
              if (urlReturnArray[0] === 'urlreturn') {
                var urlReturn = urlReturnArray[1];
              }
            }
          }
          setToken(oauthTokenB64);
          sessionStorage.setItem('userToken', "true");
          sessionStorage.setItem('urlReturn', urlReturn);
          sessionStorage.setItem('environment', environment1);
        }
        if (genericParamArray[0] === 'response_type') { //auth login
          var responseTypeArray = params[0].split("=");
          var clientIdArray = params[1].split("=");
          var redirectUriArray = params[2].split("=");
          var scopeArray = params[3].split("=");
          var langArray = params[4].split("=");
          var stateArray = params[5].split("=");
          var environmentArray2 = params[5].split("=");
          var hashArray = params[6].split("=");
          var hashalgoArray = params[6].split("=");
          var tokenArray = params[6].split("=");

          if (responseTypeArray !== null && responseTypeArray.length === 2) {
            if (responseTypeArray[0] === 'response_type') {
              var responseType = responseTypeArray[1];
            }
          }
          if (clientIdArray !== null && clientIdArray.length === 2) {
            if (clientIdArray[0] === 'client_id') {
              var clientId = clientIdArray[1];
            }
          }
          if (redirectUriArray !== null && redirectUriArray.length === 2) {
            if (redirectUriArray[0] === 'redirect_uri') {
              var redirectUri = redirectUriArray[1];
            }
          }
          if (scopeArray !== null && scopeArray.length === 2) {
            if (scopeArray[0] === 'scope') {
              var scope = scopeArray[1];
            }
          }
          if (langArray !== null && langArray.length === 2) {
            if (langArray[0] === 'lang') {
              var lang = langArray[1];
            }
          }
          if (stateArray !== null && stateArray.length === 2) {
            if (stateArray[0] === 'state') {
              var state = stateArray[1];
            }
          }
          if (environmentArray2 !== null && environmentArray2.length === 2) {
            if (environmentArray2[0] === 'env') {
              var environment2 = environmentArray2[1].toUpperCase();
            }
          }
          if (hashArray !== null && hashArray.length === 2) {
            if (hashArray[0] === 'hash') {
              var hash = hashArray[1];
            }
          }
          if (hashalgoArray !== null && hashalgoArray.length === 2) {
            if (hashalgoArray[0] === 'hashalgo') {
              var hashalgo = hashalgoArray[1];
            }
          }
          if (tokenArray !== null && tokenArray.length === 2) {
            if (tokenArray[0] === 'token') {
              var token = tokenArray[1];
            }
          }
          setUserAuth(true);
          sessionStorage.setItem('userAuth', "true");
          sessionStorage.setItem('responseType', responseType);
          sessionStorage.setItem('clientId', clientId);
          sessionStorage.setItem('redirectUri', redirectUri);
          sessionStorage.setItem('scope', scope);
          sessionStorage.setItem('lang', lang);
          sessionStorage.setItem('state', state);
          sessionStorage.setItem('environment', environment2);
          sessionStorage.setItem('hash', hash);
          sessionStorage.setItem('hashalgo', hashalgo);
          sessionStorage.setItem('token', token);

          if (lang !== null) {
            i18n.changeLanguage(lang);
          } else {
            i18n.changeLanguage('en');
          }
        }
      }
    }
  };

  // On Init
  useEffect(() => {
    checkForStoredCredentials();
    const oauthTokenB64 = sessionStorage.getItem('oauthTokenB64');
    if (oauthTokenB64 !== null) {
      setDocumentsRedirect(true);
    }

    const { userAgent } = window.navigator;
    const isIE = /MSIE|Trident/.test(userAgent);
    if (isIE) {
      getParamsIE();
      return;
    }
    getParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const getOauthViDMobileToken = () => {
    const vidCloudMgmtUrl = getVidCloudMgmtUrl();
    return new Promise(function (resolve, reject) {
      if (token) {
        var config = { headers: { 'Authorization': 'Bearer ' + token } };
        sessionStorage.setItem('oauthTokenB64', token);
        resolve(config);
        return;
      }
      const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

      axios({
        method: 'post',
        url: vidCloudMgmtUrl + '/oauth/token',
        data: {
          username: user,
          password: password,
          grant_type: "password",
          scope: "vidmobileuser"
        },
        async: false,
        dataType: "json",
        contentType: 'application/json',
        crossDomain: true,
        headers: { 'Authorization': 'Basic ' + authorizationHeader }
      })
        .then(res => {
          sessionStorage.setItem("AccessMethod", "USER_PASS");
          const accessToken = res.data.access_token;
          sessionStorage.setItem('oauthTokenB64', accessToken);
          const config = { headers: { 'Authorization': 'Bearer ' + accessToken } };
          resolve(config);
        })
        .catch((error) => {
          if (error?.response?.status === 401 || error.message.includes('401')) {
            setError(true);
            setLoginIsInvalid(true);
            setLoading(false);
            // If error 409, apply default appearance config
          }
        });
    });
  }

  const successCallback = (resetpasswordResponse) => {
    const hasToResetPassword = resetpasswordResponse.data.ResetPassword;
    setLoading(false);
    sessionStorage.setItem("loginSSI", "true");
    if (userAuth) {
      setAuthCertsRedirect(true);
    } else {
      setResetPasswordUsername(resetpasswordResponse.data.UserName);
      setResetPasswordRedirect(hasToResetPassword);
      setDocumentsRedirect(!hasToResetPassword);
    }
  }

  const handleLogin = async () => {
    const vidCloudMgmtUrl = getVidCloudMgmtUrl();
    const vidCloudPrivUrl = getVidCloudPrivUrl();

    sessionStorage.setItem('user', user);
    setLoading(true);
    setLoginIsInvalid(false);
    const result = await getOauthViDMobileToken();
    const tokenParts = result.headers.Authorization.split(".");
    const jsonTokenInfo = atob(tokenParts[1]);
    const tokenInfo = JSON.parse(jsonTokenInfo);
    const userGUID = tokenInfo.sub;
    try {
      const appearanceReq = `${vidCloudPrivUrl}/vidmobileuser/${userGUID}/appearance`;
      const resetPasswordReq = `${vidCloudMgmtUrl}/vidmobileuser/${userGUID}/resetpassword`;

      sessionStorage.setItem('showApprovals', true);

      const resAppearance = await axios.get(appearanceReq, result);
      sessionStorage.setItem("appearanceConf", btoa(JSON.stringify(resAppearance.data)));

      const response = await axios.get(resetPasswordReq, result);
      successCallback(response);
    } catch (error) {
      if (error?.response?.status === 401 || error.message.includes('401')) {
        setError(true);
        setLoginIsInvalid(true);
        setLoading(false);
        // If error 409, apply default appearance config
      } else if (error.response.status === 409 && error.response.data.includes("is assigned to multiple subscriptions with different appearance configuration")) {
        sessionStorage.setItem("appearanceConf", btoa(JSON.stringify(DEFAULT_APPEARANCE_CONFIG)));
        window.location.href = '/documents';

        const resetPasswordReq = `${vidCloudMgmtUrl}/vidmobileuser/${userGUID}/resetpassword`;
        const response = await axios.get(resetPasswordReq, result);
        successCallback(response);
      }
      else {
        setError(true);
      }
      setLoading(false);
    }
  }

  if (resetPasswordRedirect && resetPasswordUsername) {
    return <Redirect to={{
      pathname: '/changePassword',
      state: { username: resetPasswordUsername }
    }} />;
  }

  if (documentsRedirect) {
    return <Redirect to={{
      pathname: '/documents'
    }} />;
  }

  if (authCertsRedirect) {
    return <Redirect to={{
      pathname: '/authcerts',
      state: { password: password }
    }}
    />;
  }

  const checkForStoredCredentials = () => {
    const user = localStorage.getItem('user');

    if (user) {
      setFormData({
        ...formData,
        user
      });
    }
  };

  const handleSignInWith = (targetName) => {
    if (loading) return;

    const vidCloudMgmtUrl = getVidCloudMgmtUrl();

    const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

    setLoading(true);

    axios.get(`${vidCloudMgmtUrl}/auth/url/${targetName}`, {
      headers: { 'Authorization': 'Basic ' + authorizationHeader }
    })
      .then((response) => {
        const url = response?.data?.Url;

        window.location.href = url;
      })
      .catch((error) => {
        setError(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const handleSignInWithAD = () => {
    const vidCloudMgmtUrl = getVidCloudMgmtUrl();

    const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

    setLoading(true);

    const body = {
      Email: userSaml
    };

    axios.post(`${vidCloudMgmtUrl}/saml/url`, body, {
      headers: { 'Authorization': 'Basic ' + authorizationHeader }
    })
      .then((response) => {
        const url = response?.data?.Url;

        window.location.href = url;
      })
      .catch((error) => {
        setError(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const handleInputSamlChange = (e) => {
    const { value } = e.target;

    setUserSaml(value);
  }

  return (
    <>
      <CustomToast
        handleClose={() => setError(false)}
        show={error}
        title={t("general.error")}
        message={t("general.try-again")}
        delay={3000} />
      <div className="new-login-container">
        <div className="hero-container" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}>
          <img src={vidLogo} alt="Validated ID" className="logo" />
          <img src={vidWalletImg} alt="VIDwallet" className="phone-image" />
          <div className="info-panel panel-1">
            <div className="thunder-container white-thunder-container-panel-1">
              <img src={whiteThunder} alt="Thunder" />
            </div>
            <span>{t("new-login.info-panel-1")}</span>
          </div>
          <div className="info-panel panel-2">
            <div className="thunder-container white-thunder-container-panel-2">
              <img src={whiteThunder} alt="Thunder" />
            </div>
            <span>{t("new-login.secure")}</span>
          </div>
          <div className="info-panel panel-3">
            <div className="thunder-container white-thunder-container-panel-3">
              <img src={whiteThunder} alt="Thunder" />
            </div>
            <span>{t("new-login.info-panel-3")}</span>
          </div>
          <p className="copyright-new-login">{t("new-login.copyright", { year: currentYear })}</p>
        </div>
        <div className="methods-container">
          <header className="methods-header">
            <p></p>
            <LanguageSwitch />
          </header>
          <div className="methods-content">
            {regularLogin && !samlLogin &&
              <>
                <h1 className="welcome">{t("new-login.welcome1")}</h1>
                <h1 className="welcome">{t("new-login.welcome2")}</h1>
                <p className="access">{t("new-login.log-in-account")}</p>
                <div className="methods-grid">
                  <button disabled={loading} onClick={() => handleSignInWith(getTargetName('ssi'))} className="method-card">
                    <img src={vidwallet} alt="VIDwallet" />
                    <span>VIDwallet</span>
                  </button>
                  <button disabled={loading} onClick={() => handleSignInWith(getTargetName('ms'))} className="method-card">
                    <img src={microsoft} alt="Microsoft" />
                    <span>Microsoft</span>
                  </button>
                  <button disabled={loading} onClick={() => handleSignInWith(getTargetName('google'))} className="method-card">
                    <img src={google} alt="Google" />
                    <span>Google</span>
                  </button>
                </div>
                <div className="continue-with-container">
                  <hr />
                  <span>{t("new-login.continue-with")}</span>
                  <hr />
                </div>
                <form className="login-form">
                  <input
                    type="text"
                    placeholder={t("general.user")}
                    className="login-input"
                    name="user"
                    value={user}
                    onChange={handleInputChange}
                  />
                  <input
                    type="password"
                    placeholder={t("general.password")}
                    className="login-input"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                  />
                  {loginIsInvalid && <div className="invalid-login-wrapper">{t('login.invalid-login')}</div>}
                  <div className="advanced-options-container" style={{ marginBottom: environment === "PRE" ? '-3%' : '0%' }} >
                    {
                      environment === "PRE" &&
                      <AdvancedOptionsSwitch />
                    }
                    <div className="forgot-password-option" onClick={() => setWantsToResetPassword(true)}>{t('login.password-forgot')}</div>
                  </div>
                </form>
                {
                  loading ?
                    <Spinner animation="border" role="status" className="spinner-centered">
                      <span className="sr-only">{t('login.loading')}</span>
                    </Spinner> :
                    <button
                      onClick={handleLogin}
                      disabled={!user || !password || loading}
                      className="ad-sign-in-button"
                      style={{ marginTop: '2%' }}>
                      {t("new-login.regular-login")}
                    </button>
                }
                <div className="saml-sso" onClick={() => { setSamlLogin(true); setRegularLogin(false) }}>{t("new-login.saml-login")}</div>
              </>
            }
            {samlLogin &&
              <>
                <h1 className="welcome">{t("new-login.welcome3")}</h1>
                <h1 className="welcome">{t("new-login.welcome4")}</h1>
                <form className="login-form" style={{ marginBottom: '5%', marginTop: '10%' }}>
                  <input
                    type="text"
                    placeholder={t("profile.user-email")}
                    className="login-input"
                    name="userSaml"
                    value={userSaml}
                    onChange={handleInputSamlChange}
                  />
                </form>
                <button
                  onClick={handleSignInWithAD}
                  disabled={loading || !userSaml}
                  className="ad-sign-in-button">
                  {t("new-login.saml-login")}
                </button>
                <div className="saml-sso" onClick={() => { setSamlLogin(false); setRegularLogin(true) }}> {t("new-login.user-pass-login")} </div>
              </>
            }
            <p className='disclaimer-text'>
              {t("new-login.this-method")}
              <a
                href="mailto:soporte@vidsigner.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                soporte@vidsigner.net
              </a>
            </p>
          </div>
        </div>
      </div>
      <ForgotPassword wantsToResetPassword={wantsToResetPassword} setWantsToResetPassword={setWantsToResetPassword} />
    </>
  );
}