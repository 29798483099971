import React, {} from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

const KeyVaultCertificates = ({ parentState, onHandleFileSelection, onUploadCertificateKeyVault, onDeleteKeyVault, onFileInput, onHandleKeyVaultChange }) => {
  const { t } = useTranslation();
  const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;
  const {
    certificatesOrdered: { KeyVault },
    KVpwIsInvalid,
    errorMessageKVpw,
    fileName,
    KValias,
    KVpassword,
    KVpasswordConfirmation,
  } = parentState;

  const KeyVaultColumns = [
    {
      dataField: 'certalias',
      text: t('certificates.certificate'),
      align: 'left',
      sort: true,
      headerAlign: 'left',
      headerClasses: 'headerClass1',
      style: { verticalAlign: 'middle' }
    },
    {
      dataField: 'ExpirationDate',
      text: t('certificates.certificate-date'),
      align: 'left',
      sort: true,
      headerAlign: 'left',
      headerClasses: 'headerClass2',
      style: { verticalAlign: 'middle' }
    },
    {
      dataField: 'ActionButton',
      text: '',
      align: 'center',
      headerAlign: 'left',
      isDummyField: true,
      style: { verticalAlign: 'middle' },
      headerClasses: 'headerClass3',
      formatter: (cellContent, row) => {
        return (
          <Button
            type="button"
            className="reject"
            onClick={() => onDeleteKeyVault(true, row)}
          >
            {t('certificates.certificate-delete')}
          </Button>
        );
      }
    }
  ];

  return (
    <div className="table-container">
      {KeyVault.length === 0 &&
        <div>
          {certsNotAvailable}
          <div className="keyvault-form-container">
            <div className="kv-header">
              <h1 style={{ marginBottom: 0 }}>{t('certificates.create-certificate')}</h1>
            </div>
            {(KVpwIsInvalid) &&
              <div className="error-container">
                {KVpwIsInvalid ? errorMessageKVpw : ''}
              </div>
            }
            <div className="kv-body">
              <Form.Group
                controlId="formFile"
                style={{ marginBottom: 15 }}
              >
                <Form.File
                  accept=".p12, .pfx"
                  onChange={(e) => onHandleFileSelection(e)}
                  ref={onFileInput}
                  label={fileName || t('general.form-file-label')}
                  data-browse={t('general.form-file-browse')}
                  custom
                />
                <Form.Text className="text-muted">
                  {t('certificates.certificate-select')}
                </Form.Text>
              </Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="key-vault-alias">{t('general.alias')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="text"
                  aria-label="Default"
                  aria-describedby="key-vault-alias"
                  value={KValias}
                  onChange={e => onHandleKeyVaultChange("KValias", e.target.value, null)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="key-vault-pin">{t('general.pin')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="password"
                  aria-label="Default"
                  aria-describedby="key-vault-pin"
                  value={KVpassword}
                  onChange={e => onHandleKeyVaultChange("KVpassword", e.target.value, false)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="key-vault-repeat-pin">{t('certificates.certificate-repeat-pin')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="password"
                  aria-label="Default"
                  aria-describedby="key-vault-repeat-pin"
                  value={KVpasswordConfirmation}
                  onChange={e => onHandleKeyVaultChange("KVpasswordConfirmation", e.target.value, false)}
                />
              </InputGroup>
              <Button
                type="button"
                id="uploadKeyVault"
                className="btn btn-primary signature-image"
                onClick={onUploadCertificateKeyVault}
              >
                {t('certificates.certificate-upload')}
              </Button>
            </div>
          </div>
        </div>
      }
      {KeyVault.length > 0 &&
        <div>
          <ToolkitProvider
            keyField="GUID"
            data={KeyVault}
            columns={KeyVaultColumns}
          >
            {
              props => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                  />
                </div>
              )
            }
          </ToolkitProvider>
          <div className="keyvault-form-container">
            <div className="kv-header">
              <h1 style={{ marginBottom: 0 }}>{t('certificates.create-certificate')}</h1>
            </div>
            {(KVpwIsInvalid) &&
              <div className="error-container">
                {KVpwIsInvalid ? errorMessageKVpw : ''}
              </div>
            }
            <div className="kv-body">
              <Form.Group
                controlId="formFile"
                style={{ marginBottom: 15 }}
              >
                <Form.File
                  accept=".p12, .pfx"
                  onChange={(e) => onHandleFileSelection(e)}
                  ref={onFileInput}
                  label={fileName || t('general.form-file-label')}
                  data-browse={t('general.form-file-browse')}
                  custom
                />
                <Form.Text className="text-muted">
                  {t('certificates.certificate-select')}
                </Form.Text>
              </Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="key-vault-cert-alias">{t('general.alias')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="text"
                  aria-label="Default"
                  aria-describedby="key-vault-cert-alias"
                  value={KValias}
                  onChange={e => onHandleKeyVaultChange("KValias", e.target.value, null)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="key-vault-cert-pin">{t('general.pin')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="password"
                  aria-label="Default"
                  aria-describedby="key-vault-cert-pin"
                  value={KVpassword}
                  onChange={e => onHandleKeyVaultChange("KVpassword", e.target.value, false)}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="key-vault-cert-repeat-pin">{t('certificates.certificate-repeat-pin')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="password"
                  aria-label="Default"
                  aria-describedby="key-vault-cert-repeat-pin"
                  value={KVpasswordConfirmation}
                  onChange={e => onHandleKeyVaultChange("KVpasswordConfirmation", e.target.value, false)}
                />
              </InputGroup>
              <Button
                type="button"
                id="uploadKeyVault"
                className="btn btn-primary signature-image"
                onClick={onUploadCertificateKeyVault}
              >
                {t('certificates.certificate-upload')}
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default KeyVaultCertificates