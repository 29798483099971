import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './FilterCertificateMsg.css';
import { MdClear } from "react-icons/md";

class FilterCertificateMsg extends Component {
    render() {
        const { filter, close } = this.props;

        return (
            <div className="rectangle">
                <span><b>{filter}</b></span>
                <MdClear className="clear-icon" size={25} onClick={close} />
            </div>
        )
    }
}

export default withTranslation()(FilterCertificateMsg);