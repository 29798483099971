import React, { Fragment } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingCircle from '../LoadingCircle';

const NebulaSignature = ({ parentState, onClose, onChangeFormControl, onSendAuthenticator, authenticators, onChallengeChange, onSendChallenge, onFocusInput, onCertificatePinChange, onSignDoc }) => {
    const { t } = useTranslation();
    const {
        showNebulaModal,
        nebulaStep1,
        NBauthenticator,
        nebulaStep2,
        nebulaStep3,
        NBChallenge,
        loadingNebula,
        showChallengeError,
        nebulaPinRequired,
        certificateNebulaPin } = parentState;

    return (
        <Fragment>
            <Modal show={showNebulaModal && nebulaStep1} onHide={onClose}>
                { }
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.nebula-authenticator')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select" value={NBauthenticator} onChange={e => onChangeFormControl(e) }>
                                <option value="">{t('documents.select-one')}</option>
                                {
                                    authenticators.map((auth, i) => {
                                        return <option value={auth} key={i}>{auth}</option>;
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="centered-buttons">
                        <Button
                            className="reject"
                            onClick={onClose}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            disabled={!NBauthenticator}
                            variant="primary"
                            onClick={onSendAuthenticator}
                            style={{ marginLeft: 20 }}
                        >
                            {t('general.confirm')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={showNebulaModal && nebulaStep2} onHide={onClose}>
                { }
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.nebula-otp')} {NBauthenticator}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="nebula-user">{t('general.otp')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="text"
                            aria-label="Default"
                            aria-describedby="nebula-challenge"
                            value={NBChallenge}
                            onChange={onChallengeChange}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <div className="centered-buttons">
                        <Button
                            className="reject"
                            onClick={onClose}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            disabled={!NBChallenge}
                            variant="primary"
                            onClick={onSendChallenge}
                            style={{ marginLeft: 20 }}
                        >
                            {t('general.confirm')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={showNebulaModal && showChallengeError} onHide={onClose}>
                { }
                <Modal.Header closeButton>
                    <Modal.Title>{t('general.error')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('documents.error-challenge')}
                </Modal.Body>
                <Modal.Footer>
                    <div className="centered-buttons">
                        <Button
                            className="reject"
                            onClick={onClose}
                        >
                            {t('general.accept')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showNebulaModal && nebulaStep3 && nebulaPinRequired} onHide={onClose}>
                { }
                <Modal.Header closeButton>
                    <Modal.Title>{t('documents.sw-pin')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="inputGroup-sizing-default">{t('general.pin')}: </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="password"
                            ref={onFocusInput}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            autoComplete="new-password"
                            value={certificateNebulaPin}
                            onChange={onCertificatePinChange}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <div className="centered-buttons">
                        <Button
                            className="reject"
                            onClick={onClose}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            disabled={!certificateNebulaPin}
                            variant="primary"
                            onClick={onSignDoc}
                            style={{ marginLeft: 20 }}
                        >
                            {t('general.confirm')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={loadingNebula}
                onHide={onClose}>
                <Modal.Header>
                    {loadingNebula && <Modal.Title>{t('login.loading')}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <LoadingCircle />
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default NebulaSignature