import React, { useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FIRMAREMOTA, FIRMAREMOTA_CERTIFICATE_PIN_KEY, FIRMAREMOTA_SHOW_MODAL_KEY } from '../../services/signatureMethods/constants';

const FirmaRemota = ({ show, onClose, onSuccess, certificateSelected }) => {
  const { t } = useTranslation();
  const [certificatePin, setCertificatePin] = useState('');

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {certificateSelected.AuthenticationType === "APP" &&
          <Modal.Title>{t('documents.fr-app')}</Modal.Title>}
        {certificateSelected.AuthenticationType === "SMS" &&
          <Modal.Title>{t('documents.fr-sms')}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-default">{t('general.otp')}: </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            type="password"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            autoComplete="new-password"
            value={certificatePin}
            onChange={e => setCertificatePin(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button className="reject" onClick={onClose}>
          {t('general.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onSuccess(FIRMAREMOTA, FIRMAREMOTA_SHOW_MODAL_KEY, FIRMAREMOTA_CERTIFICATE_PIN_KEY, certificatePin)}>
          {t('general.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FirmaRemota