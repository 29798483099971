import React, { useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { REDTRUST, REDTRUST_CERTIFICATE_PIN_KEY, REDTRUST_SHOW_MODAL_KEY } from '../../services/signatureMethods/constants';

const RedTrustSignature = ({show, onClose, onSuccess}) => {
    const { t } = useTranslation();
    const [certificatePin, setCertificatePin] = useState('');
    
  return (
    <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{t('documents.rt-pin')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-default">{t('general.pin')}: </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    required
                    type="password"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    autoComplete="new-password"
                    onChange={e => setCertificatePin(e.target.value) }
                />
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button className="reject" onClick={onClose}>
                {t('general.cancel')}
            </Button>
            <Button variant="primary" 
                onClick={() => onSuccess(REDTRUST, REDTRUST_SHOW_MODAL_KEY, REDTRUST_CERTIFICATE_PIN_KEY, certificatePin) }>
                {t('general.confirm')}
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default RedTrustSignature