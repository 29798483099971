import DocumentsActionTypes from './documents.types';

export const INITIAL_FILTERS = {
  From: "",
  To: "",
  FileName: "",
  CertificateGuid: "",
  Sender: "",
  Tag: "",
}

const INITIAL_STATE = {
  filteredDocuments: [],
  documents: [],
  filters: INITIAL_FILTERS,
  paginationMeta: {
    PageNumber: 1,
    PageSize: 10
  },
  ordering: {
    OrderBy: "FileName",
    OrderType: "asc"
  }
};

const documentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DocumentsActionTypes.ADD_DOCUMENTS_TO_LIST:
      if (!action.payload) return state;
      return {
        ...state,
        documents: action.payload,
        filteredDocuments: action.payload,
      };
    case DocumentsActionTypes.RESET_FILTERS:
      return {
        ...state,
        filteredDocuments: state.documents,
      };
    case DocumentsActionTypes.MODIFY_PAGINATION:
      return {
        ...state,
        paginationMeta: action.payload,
      };
    case DocumentsActionTypes.MODIFY_SORTING:
      return {
        ...state,
        ordering: action.payload,
      };
    case DocumentsActionTypes.MODIFY_FILTERS:
      return {
        ...state,
        paginationMeta: {
          ...state.paginationMeta,
          PageNumber: 1
        },
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default documentsReducer;
