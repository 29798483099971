import React from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { CATALAN, ENGLISH, FRENCH, GERMAN, SPANISH } from '../../assets/constants';
import i18n from '../../config/i18n';

const LanguageSwitch = ({ t }) => {
  const changeLanguageHandler = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    if (window.location.pathname === "/documents" || window.location.pathname === "/approvals") {
      window.location.reload();
    }
  }

  return (
    <Form.Group
      onChange={e => changeLanguageHandler(e.target.value)}
    >
      <Form.Control
        as="select"
        id="languageSelection"
        size="sm"
        defaultValue={i18n.languages[0]}
      >
        <option value="es">{SPANISH}</option>
        <option value="ca">{CATALAN}</option>
        <option value="en">{ENGLISH}</option>
        <option value="de">{GERMAN}</option>
        <option value="fr">{FRENCH}</option>
      </Form.Control>
    </Form.Group>
  );
};

export default withTranslation()(LanguageSwitch);