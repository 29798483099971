import React from "react";

import { RegularLogin } from "./RegularLogin/RegularLogin";
import "./VIDChainLogin.css";

export const VIDChainLoginPage = () => {

  return (
    <RegularLogin />
  );
}