import React from 'react';
import { withTranslation } from 'react-i18next';
import "./TagsList.css";

const TagsList = ({ tagString, t }) => {
  let tag = tagString;

  if (!tag) return (
    <p>{t('documents.document-cert-undef')}</p>
  );

  let tagsArray = tag.split(",");

  const tagsList = tagsArray.map((tagEl, i) => {
    return (
      <p className="tag" key={i}>{tagEl}</p>
      );
  });

  return <div className="tags-container">{tagsList}</div>
}

export default withTranslation()(TagsList);