import React, { useState, useEffect } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import Utils from '../../../services/Utils';
import { generateValidatedIdStatusUri, getManagementSignature } from '../../../services/signatureMethods/customServiceSignature';

const ValidatedIdCertificates = ({ parentState, onAuthorizeValidatedIdCertificate, onUnauthorizeValidatedIdCertificate }) => {
  const { t } = useTranslation();
  const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;
  const {
    certificatesOrdered: { ValidatedID },
  } = parentState;

  let validatedIDColumns = [
    {
      dataField: 'certalias',
      text: t('certificates.certificate'),
      align: 'left',
      sort: true,
      headerAlign: 'left',
      headerClasses: 'headerClass1',
      style: { verticalAlign: 'middle' }
    },
    {
      dataField: 'ExpirationDate',
      text: t('certificates.certificate-date'),
      align: 'left',
      sort: true,
      headerAlign: 'left',
      headerClasses: 'headerClass2',
      style: { verticalAlign: 'middle' }
    }
  ];

  if (ValidatedID[0]?.DN) {
    validatedIDColumns[0] = {
      dataField: 'DN',
      text: t('certificates.certificate'),
      align: 'left',
      sort: true,
      headerAlign: 'left',
      headerClasses: 'headerClass1',
      style: { verticalAlign: 'middle' }
    }
  }

  const [UTuser, setUTuser] = useState('');
  const [UTpassword, setUTpassword] = useState('');

  const [isAuthorized, setIsAuthorized] = useState(ValidatedID.length);

  const getValidatedIdStatus = () => {
    const oauthTokenB64 = sessionStorage.getItem('oauthTokenB64');
    const vidmobileUserGuid = Utils.getViDMobileUserGUIDFromOauthToken(oauthTokenB64);

    getManagementSignature(generateValidatedIdStatusUri(vidmobileUserGuid), "", oauthTokenB64)
    .then((response) => {
      const { IsAuthorized } = response.data;

      setIsAuthorized(IsAuthorized);
    });
  }

  useEffect(getValidatedIdStatus, []);

  return (
    <div className="table-container">
      <div>

      {ValidatedID.length === 0 && certsNotAvailable}
      {ValidatedID.length > 0 &&
          <ToolkitProvider
            keyField="GUID"
            data={ValidatedID}
            columns={validatedIDColumns}
          >
            {
              props => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                  />
                </div>
              )
            }
          </ToolkitProvider>
      }
      {
        isAuthorized ?  <div className="rt-buttons-container">
        <Button
          type="button"
          id="unauthorizeUT"
          className="btn signature-image reject"
          onClick={onUnauthorizeValidatedIdCertificate}
        >
          {t('general.unauthorize')}
        </Button>
      </div> : <>
      <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="uanataca-user">{t('general.user')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="text"
              aria-label="Default"
              aria-describedby="uanataca-user"
              value={UTuser}
              onChange={e => setUTuser(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="uanataca-password">{t('general.password')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="password"
              aria-label="Default"
              aria-describedby="uanataca-password"
              value={UTpassword}
              onChange={e => setUTpassword(e.target.value)}
            />
          </InputGroup>
          <Button
            type="button"
            id="authorizeUT"
            className="btn btn-primary signature-image"
            onClick={() => onAuthorizeValidatedIdCertificate({UTuser, UTpassword})}
          >
            {t('general.authorize')}
          </Button>
      </>
      }
      </div>
    </div>
  )
}

export default ValidatedIdCertificates