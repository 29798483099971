import React, { useState } from "react";

import blackThunder from './../../assets/icons/black-thunder.png';
import whiteThunder from './../../assets/icons/white-thunder.png';
import background from './../../assets/images/new-login-hero.png';
import x from './../../assets/icons/x.png';
import linkedin from './../../assets/icons/linkedin.png';
import youtube from './../../assets/icons/youtube.png';

import microsoft from './../../assets/icons/microsoft.png';
import vidwallet from './../../assets/icons/vidwallet.png';
import google from './../../assets/icons/google.png';

import validatedIDLogo from "./../../assets/images/new-vid-logo.png";

import './LoginMethods.css';
import LanguageSwitch from "../../components/LanguageSwitch/LanguageSwitch";
import { useTranslation } from "react-i18next";
import { AdvancedOptionsSwitch } from "../VIDChainLogin/AdvancedOptionsSwitch/AdvancedOptionsSwitch";
import { ForgotPassword } from "../VIDChainLogin/ForgotPassword/ForgotPassword";
import { getTargetName, getVidCloudMgmtUrl } from "../../services/environment/environmentService";
import { CLIENT_ID, CLIENT_SECRET } from "../../services/vid-config";
import axios from "axios";
import CustomToast from "../../components/Documents/CustomToast/CustomToast";

export const LoginMethods = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState('');
    const [wantsToResetPassword, setWantsToResetPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { value } = e.target;

        setUser(value); 
    }

    const handleSignInWith = (targetName) => {
        if (loading) return;

        const vidCloudMgmtUrl = getVidCloudMgmtUrl();
    
        const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);
    
        setLoading(true);
    
        axios.get(`${vidCloudMgmtUrl}/auth/url/${targetName}`, {
          headers: { 'Authorization': 'Basic ' + authorizationHeader }
        })
          .then((response) => {
            const url = response?.data?.Url;

            window.location.href = url;
          })
          .catch((error) => {
            setError(error.response.data);
          })
          .finally(() => {
            setLoading(false);
          })
      };

      const handleSignInWithAD = () => {
        const vidCloudMgmtUrl = getVidCloudMgmtUrl();
    
        const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);
    
        setLoading(true);
    
        const body = { 
          Email: user
        };
    
        axios.post(`${vidCloudMgmtUrl}/saml/url`, body, {
          headers: { 'Authorization': 'Basic ' + authorizationHeader }
        })
          .then((response) => {
            const url = response?.data?.Url;

            window.location.href = url;
          })
          .catch((error) => {
            setError(error.response.data);
          })
          .finally(() => {
            setLoading(false);
          })
      };
    
    const currentYear = new Date().getFullYear();

    return (
        <>
        <CustomToast 
                handleClose={() => setError(false)} 
                show={!!error} 
                title={t("general.error")}
                message={error}
                delay={3000} />
            <div className="new-login-container">
                <div className="hero-container" style={{ backgroundImage: `url(${background})` }}>
                    <div className="info-panel panel-1">
                        <div className="thunder-container black-thunder-container">
                            <img src={blackThunder} alt="Thunder" />
                        </div>
                        <span>{t("new-login.any-device")}</span>
                    </div>
                    <div className="info-panel panel-2">
                        <div className="thunder-container white-thunder-container">
                            <img src={whiteThunder} alt="Thunder" />
                        </div>
                        <span>{t("new-login.batches")}</span>
                    </div>
                    <div className="info-panel panel-3">
                        <div className="thunder-container white-thunder-container">
                            <img src={whiteThunder} alt="Thunder" />
                        </div>
                        <span>{t("new-login.secure")}</span>
                    </div>
                    <div className="social-media-container">
                        <a href="https://twitter.com/ValidatedID" target="_blank" rel="noopener noreferrer">
                            <img src={x} alt="X" />
                        </a>
                        <a href="https://es.linkedin.com/company/validated-id" target="_blank" rel="noopener noreferrer">
                            <img src={linkedin} alt="LinkedIn" />
                        </a>
                        <a href="https://www.youtube.com/@Validatedid" target="_blank" rel="noopener noreferrer">
                            <img src={youtube} alt="YouTube" />
                        </a>
                    </div>
                </div>
                <div className="methods-container">
                    <header className="methods-header">
                        <img src={validatedIDLogo} alt="Validated ID" />
                        <LanguageSwitch />
                    </header>
                    <div className="methods-content">
                        <h1 className="welcome-again">{t("new-login.welcome-back")}</h1>
                        <p className="access">{t("new-login.log-in-account")}</p>
                        <div className="methods-grid">
                            <button disabled={loading} onClick={() => handleSignInWith(getTargetName('ms'))} className="method-card">
                                <img src={microsoft} alt="Microsoft" />
                                <span>Microsoft</span>
                            </button>
                            <button disabled={loading} onClick={() => handleSignInWith(getTargetName('google'))} className="method-card">
                                <img src={google} alt="Google" />
                                <span>Google</span>
                            </button>
                            <button disabled={loading} onClick={() => handleSignInWith(getTargetName('ssi'))} className="method-card">
                                <img src={vidwallet} alt="VIDwallet" />
                                <span>VIDwallet</span>
                            </button>
                        
                        </div>
                        <div className="continue-with-container">
                            <hr />
                            <span>{t("new-login.continue-with")} SAML</span>
                            <hr />
                        </div>
                        
                        <input
                            type="email"
                            className='ad-login-input'
                            name="user"
                            placeholder={t("vid-chain-login.user")}
                            value={user}
                            onChange={handleInputChange}
                        />
                        <div className="advanced-options-container">
                        <AdvancedOptionsSwitch /> 
                            <div className="forgot-password-option" onClick={() => setWantsToResetPassword(true)}>{t('login.password-forgot')}</div>
                        </div>
                        <button onClick={handleSignInWithAD} disabled={loading} className="ad-sign-in-button">Iniciar sesión</button>
                        <p className='disclaimer-text'>
                            {t("new-login.this-method")}
                            <a
                                href="mailto:soporte@vidsigner.net"
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                soporte@vidsigner.net
                            </a>
                        </p>
                    </div>
                   
                    <p className="copyright">{t("new-login.copyright", { year: currentYear })}</p>
                </div>
            </div>
            <ForgotPassword wantsToResetPassword={wantsToResetPassword} setWantsToResetPassword={setWantsToResetPassword} />
        </>
    );
};