import { combineReducers } from 'redux';
import approvalReducer from './approvals/approvals.reducer';

import documentsReducer from './documents/documents.reducer';

const rootReducer = combineReducers({
  documents: documentsReducer,
  approvals: approvalReducer,
});

export default rootReducer;
