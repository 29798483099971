import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import checkIcon from './../../assets/icons/security-check.svg';
import xIcon from './../../assets/icons/security-x.svg';

import './SecurityValidation.css';

export const SecurityValidation = ({ password, updateIsPasswordSecure }) => {
  const { t } = useTranslation();
  const [validation, setValidation] = useState({
    hasNumbers: false,
    hasLowerAndUpper: false,
    hasSpecialChars: false,
    hasAtLeast8Chars: false,
    doesNotHaveWhiteSpaces: true
  });

  const { hasNumbers, hasLowerAndUpper, hasSpecialChars, hasAtLeast8Chars, doesNotHaveWhiteSpaces } = validation;

  const CheckIcon = () => <img src={checkIcon} alt="check" />;
  const XIcon = () => <img src={xIcon} alt="x" />;

  useEffect(() => {
    setValidation({
      ...validation,
      hasAtLeast8Chars: password.length >= 8 && password.length <= 20,
      hasNumbers: /.*[0-9].*/.test(password),
      hasLowerAndUpper: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      hasSpecialChars: /(?=.*[@#$])/.test(password),
      doesNotHaveWhiteSpaces: !password.includes(" ")
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    updateIsPasswordSecure(hasNumbers && hasLowerAndUpper && hasSpecialChars && hasAtLeast8Chars && doesNotHaveWhiteSpaces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  return (
    <div className="validation-container">
      <p className="validation-instructions">{t("security.your-new-password")}</p>
      <div className="validation-items-container">
        <div className="validation-item">
          {hasNumbers ? <CheckIcon /> : <XIcon />}
          <p className="validation-text">{t("security.1-number")}</p>
        </div>
        <div className="validation-item">
          {hasLowerAndUpper ? <CheckIcon /> : <XIcon />}
          <p className="validation-text">{t("security.1-uppercase-lowercase")}</p>
        </div>
        <div className="validation-item">
          {hasSpecialChars ? <CheckIcon /> : <XIcon />}
          <p className="validation-text">{t("security.1-special") + ": @ # $"}</p>
        </div>
        <div className="validation-item">
          {hasAtLeast8Chars ? <CheckIcon /> : <XIcon />}
          <p className="validation-text">{t("security.length")}</p>
        </div>
        <div className="validation-item">
          {doesNotHaveWhiteSpaces ? <CheckIcon /> : <XIcon />}
          <p className="validation-text">{t("security.not-white")}</p>
        </div>
      </div>
    </div>
  )
}