import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { generateUanatacaStatusUri, getManagementSignature } from '../../../services/signatureMethods/customServiceSignature';
import Utils from '../../../services/Utils';

const UanatacaCertificates = ({ parentState, onAuthorizeUanatacaCertificate, onUnauthorizeUanatacaCertificate }) => {
  const { t } = useTranslation();
  const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;
  const {
    certificatesOrdered: { Uanataca },
  } = parentState;

  const getColumns = () => {
    let uanatacaColumns = [
      {
        dataField: 'certalias',
        text: t('certificates.certificate'),
        align: 'left',
        sort: true,
        headerAlign: 'left',
        headerClasses: 'headerClass1',
        style: { verticalAlign: 'middle' }
      },
      {
        dataField: 'ExpirationDate',
        text: t('certificates.certificate-date'),
        align: 'left',
        sort: true,
        headerAlign: 'left',
        headerClasses: 'headerClass2',
        style: { verticalAlign: 'middle' }
      }
    ];
    if (Uanataca[0]?.AuthenticationType === 'OTP' || Uanataca[0]?.DN) {
      uanatacaColumns = [
        {
          dataField: 'DN',
          text: t('certificates.certificate'),
          align: 'left',
          sort: true,
          headerAlign: 'left',
          headerClasses: 'headerClass1',
          style: { verticalAlign: 'middle' }
        },
        {
          dataField: 'ExpirationDate',
          text: t('certificates.certificate-date'),
          align: 'left',
          sort: true,
          headerAlign: 'left',
          headerClasses: 'headerClass2',
          style: { verticalAlign: 'middle' }
        }
      ];
    }

    return uanatacaColumns;
  }

  const [UTuser, setUTuser] = useState('');
  const [UTpassword, setUTpassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(Uanataca.length);

  const getUanatacaStatus = () => {
    const oauthTokenB64 = sessionStorage.getItem('oauthTokenB64');
    const vidmobileUserGuid = Utils.getViDMobileUserGUIDFromOauthToken(oauthTokenB64);

    getManagementSignature(generateUanatacaStatusUri(vidmobileUserGuid), "", oauthTokenB64)
    .then((response) => {
      const { IsAuthorized } = response.data;

      setIsAuthorized(IsAuthorized);
    });
  }

  useEffect(getUanatacaStatus, []);

  return (
    <div className="table-container">
        <div>
      {Uanataca.length === 0 &&certsNotAvailable}
      {Uanataca.length > 0 &&
        <div>
          <ToolkitProvider
            keyField="GUID"
            data={Uanataca}
            columns={getColumns()}
          >
            {
              props => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
      }
      {
        isAuthorized ? <div className="rt-buttons-container">
        <Button
          type="button"
          id="unauthorizeUT"
          className="btn signature-image reject"
          onClick={onUnauthorizeUanatacaCertificate}
        >
          {t('general.unauthorize')}
        </Button>
      </div> : 
      <>
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="uanataca-user">{t('general.user')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="text"
              aria-label="Default"
              aria-describedby="uanataca-user"
              value={UTuser}
              onChange={e => setUTuser(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="uanataca-password">{t('general.password')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              type="password"
              aria-label="Default"
              aria-describedby="uanataca-password"
              value={UTpassword}
              onChange={e => setUTpassword(e.target.value)}
            />
          </InputGroup>
          <Button
            type="button"
            id="authorizeUT"
            className="btn btn-primary signature-image"
            onClick={() => onAuthorizeUanatacaCertificate({UTuser, UTpassword})}
          >
            {t('general.authorize')}
          </Button>
      </>
      }
        </div>
    </div>
  )
}

export default UanatacaCertificates