import React, { Component } from 'react';
import './Certificates.css';
import { withTranslation } from 'react-i18next';
import Header from "../Header/Header";
import { Button, Col, Jumbotron, Modal, Nav, Row, Tab, Toast } from "react-bootstrap";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import { MdClose } from "react-icons/md";
import ThemeService from '../../services/ThemeService';
import LoadingCircle from './../LoadingCircle'
import { getVidCloudMgmtUrl } from '../../services/environment/environmentService';
import { FIRMAREMOTA_LOADING_AUTHORIZE_KEY, FIRMAREMOTA_LOADING_UNAUTHORIZE_KEY, KEYVAULT, KEYVAULT_LOADING_DELETING_KEY, KEYVAULT_LOADING_UPLOAD_KEY, LOADING_DELETING_PROCESS_KEY, NEBULA_LOADING_KEY, REDTRUST, REDTRUST_LOADING_AUTHORIZE_KEY, REDTRUST_LOADING_SYNCHRONIZE_KEY, REDTRUST_LOADING_UNAUTHORIZE_KEY, SOFTWARE, UANATACA, UANATACA_LOADING_AUTHORIZE_KEY, UANATACA_LOADING_UNAUTHORIZE_KEY, VALIDATEDID, VALIDATEDID_LOADING_AUTHORIZE_KEY, VALIDATEDID_LOADING_UNAUTHORIZE_KEY } from '../../services/signatureMethods/constants';
import { generateRedTrustAuthUri, 
    postPrivSignature, 
    deleteManagementSignature, 
    generateFirmaRemotaAuthUri, 
    generateFirmaRemotaStatusUri, 
    getManagementSignature, 
    postManagementSignature,
    putPrivSignature,
    generateRedTrustAuthStatusUri, 
    generateDeleteKeyVaultCertificateUri,
    generateuUploadCertificateKeyVaultUri, 
    generateDeleteSoftwareCertificateUri,
    generateUanatacaAuthUri,
    generateNebulaAuthMiddleUri,
    generateNebulaAuthEndUri,
    generateNebulaAuthUri,
    generateValidatedIdAuthUri} from '../../services/signatureMethods/customServiceSignature';
import { VIDMOBILEUSERID } from '../../assets/constants';
import NebulaCertificates from './Signatures/NebulaCertificates.component';
import FirmaRemotaCertificates from './Signatures/FirmaRemotaCertificates.component';
import UanatacaCertificates from './Signatures/UanatacaCertificates.component';
import RedTrustCertificates from './Signatures/RedTrustCertificates.component';
import SoftwareCertificates from './Signatures/SoftwareCertificates.component';
import KeyVaultCertificates from './Signatures/KeyVaultCertificates.component';
import ValidatedIdCertificates from './Signatures/ValidatedIdCertificates.component';

import warning from '../../assets/icons/warning-jumbotron.png';

class Certificates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
            environment: sessionStorage.getItem('environment'),
            language: localStorage.getItem('i18nextLng'),
            time: 0,
            showError: false,
            errorData: '',
            errorType: '',
            certTitleTabs: [],
            certificatesOrdered: {
                FirmaRemota: [],
                KeyVault: [],
                RedTrust: [],
                Software: [],
                Uanataca: [],
                Nebula: [],
                ValidatedID: []
            },
            showDeleteSoftwareModal: false,
            showDeleteKeyVaultModal: false,
            selectedCertificate: {},
            userCertsAllowed: {},
            userCertificateTypes: {},
            certDeleted: false,
            redirect: false,
            loadingCertificates: true,
            loadingDeletingProcess: false,
            keyVaultCertb64: "",
            KValias: "",
            KVpassword: "",
            KVpasswordConfirmation: "",
            KVpwIsInvalid: false,
            errorMessageKVpw: "",
            dataUpdated: false,
            loadingFrcert: false,
            loadingRTcert: false,
            loadingRTsync: false,
            loadingUTcert: false,
            loadingUTsync: false,
            loadingFRUnAuth: false,
            loadingRTUnAuth: false,
            loadingUTUnAuth: false,
            loadingVIcert: false,
            loadingVIUnAuth: false,
            userIsLoggedByAuthCerts: false,
            showJumbotron: true,
            tabKey: "",
            RedTrustIsAuthorized: false,
            FirmaRemotaIsAuthorized: true,
            fileName: "",
            userCertificates: null,
            loadingCertificateTypes: true,
            firstRender: true,
            nebulaStep1: true,
            nebulaStep2: false,
            nebulaStep3: false,
            NBauthenticator: ""
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleAuthenticatorChange = this.handleAuthenticatorChange.bind(this);
        this.sendAuthenticator = this.sendAuthenticator.bind(this);
        this.handleOperationError = this.handleOperationError.bind(this);
        this.unauthorizeNebulaCertificate = this.unauthorizeNebulaCertificate.bind(this);
        this.sendCredentialsAndGetAuthenticators = this.sendCredentialsAndGetAuthenticators.bind(this);
        this.sendChallenge = this.sendChallenge.bind(this);
        this.authorizeFirmaremotaCertificate = this.authorizeFirmaremotaCertificate.bind(this);
        this.unauthorizeFirmaremotaCertificate = this.unauthorizeFirmaremotaCertificate.bind(this);       
        this.authorizeRedTrustCertificate = this.authorizeRedTrustCertificate.bind(this);
        this.unauthorizeRedTrustCertificate = this.unauthorizeRedTrustCertificate.bind(this);
        this.synchronizeRedTrustCertificates = this.synchronizeRedTrustCertificates.bind(this);
        this.authenticators = [];
        this.authorizeUanatacaCertificate = this.authorizeUanatacaCertificate.bind(this);
        this.unauthorizeUanatacaCertificate = this.unauthorizeUanatacaCertificate.bind(this);
        this.authorizeValidatedIdCertificate = this.authorizeValidatedIdCertificate.bind(this);
        this.unauthorizeValidatedIdCertificate = this.unauthorizeValidatedIdCertificate.bind(this);
        this.handleFileSelection = this.handleFileSelection.bind(this);
        this.uploadCertificateKeyVault = this.uploadCertificateKeyVault.bind(this);
    }

    doLogOut() {
        sessionStorage.clear();
        this.stopTimer();
        this.setState({ redirect: true })
    }

    getViDMobileUserGUIDFromOauthToken() {
        const { oauthTokenB64 } = this.state;
        var tokenParts = oauthTokenB64.split(".");
        var jsonTokenInfo = atob(tokenParts[1]);
        var tokenInfo = JSON.parse(jsonTokenInfo);
        return tokenInfo.sub;
    }

    startTimer() {
        this.timer = setInterval(() => this.setState({
            time: this.state.time + 1
        }), 60000);
    }

    stopTimer() {
        clearInterval(this.timer)
    }

    loadUserData() {
        this.setState({ loading: true });
        const _this = this;
        const { oauthTokenB64 } = this.state;


        var config = {
            headers: {
                'Authorization': 'Bearer ' + oauthTokenB64
            }
        };
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    } else {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true
                        });
                    }
                    reject(error);
                });
        });
    }

    getUserCertificates() {
        const _this = this;
        var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };
        const { legalid } = this.state.userData;

        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileusers/' + legalid + '/certs', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    }
                    if (error.response.status === 404) {
                        _this.setState({
                            loadingCertificates: false,
                        });
                        return resolve([]);
                    }

                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true
                    });

                    reject(error);
                });
        });
    }

    loadViDMobileUserCertificateTypes() {
        const _this = this;
        var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };
        _this.setState({ loadingCertificateTypes: true });

        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/certstype', config)
                .then(res => {
                    _this.setState({ loadingCertificateTypes: false });
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    } else {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true
                        });
                    }
                    _this.setState({ loadingCertificateTypes: false });
                    reject(error);
                });
        });
    };

    loadViDMobileUserCertificateList() {
        const _this = this;
        _this.setState({ loadingCertificates: true });
        this.loadUserData()
            .then(res => {
                if (res) {
                    _this.setState({ userData: res });
                    _this.getUserCertificates()
                        .then(res => {
                            _this.setState({ userCertificates: res });
                            _this.loadViDMobileUserCertificateTypes()
                                .then(res => {
                                    _this.setState({ userCertificateTypes: res });
                                    var userCertsAllowed = [];
                                    if (res === null) {
                                        userCertsAllowed.push("Software");
                                    } else {
                                        for (var key1 in res) {
                                            if (typeof res[key1] === "boolean") {
                                                /**User has credentials for that certificate */
                                                if (res[key1] === true) {
                                                    userCertsAllowed.push(key1);
                                                }
                                            }
                                        }

                                        var certTitleTabs = userCertsAllowed.map((certificate, index) => {
                                            if (certificate === "ValidatedID") {
                                                return <Nav.Item key={certificate}>
                                                    <Nav.Link eventKey={certificate}>Validated ID</Nav.Link>
                                                </Nav.Item>
                                            }
                                            if (certificate === "FirmaRemota") {
                                                return <Nav.Item key={certificate}>
                                                    <Nav.Link eventKey={certificate}>itAgile</Nav.Link>
                                                </Nav.Item>
                                            }
                                            else {
                                                return <Nav.Item key={certificate}>
                                                    <Nav.Link eventKey={certificate}>{certificate}</Nav.Link>
                                                </Nav.Item>
                                            }
                                        });

                                        var certificatesOrdered = {
                                            FirmaRemota: [],
                                            KeyVault: [],
                                            RedTrust: [],
                                            Software: [],
                                            Uanataca: [],
                                            Nebula: [],
                                            ValidatedID: []
                                        };

                                        for (var certType in res) {
                                            if (typeof res[certType] === "boolean" && res[certType]) {
                                                for(var i=0; i < _this.state.userCertificates.length; i++){
                                                    var certificate = _this.state.userCertificates[i];
                                                    if (certificate.CertType === certType){
                                                        certificatesOrdered[certType].push(certificate);
                                                    }
                                                }
                                            }
                                        }

                                        _this.setState({
                                            certTitleTabs: certTitleTabs,
                                            certificatesOrdered: certificatesOrdered,
                                            userCertsAllowed: userCertsAllowed,
                                            tabKey: _this.state.tabKey !== "" ? _this.state.tabKey : userCertsAllowed[0],
                                            loadingCertificates: false
                                        });

                                        _this.checkRedTrustStatus()
                                            .then(res => {
                                                _this.setState({ RedTrustIsAuthorized: res.IsAuthorized });
                                                _this.checkFirmaRemotaStatus()
                                                    .then(res => {
                                                        _this.setState({ FirmaRemotaIsAuthorized: res.IsAuthorized });
                                                    })
                                                    .catch(function (error) {
                                                        _this.setState({ loadingCertificates: false });
                                                    });
                                            })
                                            .catch(function (error) {
                                                _this.checkFirmaRemotaStatus()
                                                    .then(res => {
                                                        _this.setState({ FirmaRemotaIsAuthorized: res.IsAuthorized });
                                                    })
                                                    .catch(function (error) {
                                                        _this.setState({ loadingCertificates: false });
                                                    });
                                            });
                                    }
                                })
                                .catch(function (error) {
                                    _this.setState({ loadingCertificates: false });
                                });
                        })
                        .catch(function (error) {

                            /**Aquí va la creación de tabs con solo las que tiene credenciales */
                            _this.loadViDMobileUserCertificateTypes()
                                .then(res => {
                                    _this.setState({ userCertificateTypes: res });
                                    var userCertsAllowed = [];
                                    if (res === null) {
                                        userCertsAllowed.push("Software");
                                    } else {
                                        for (var key1 in res) {
                                            if (typeof res[key1] === "boolean") {
                                                /**User has credentials for that certificate */
                                                if (res[key1] === true) {
                                                    userCertsAllowed.push(key1);
                                                }
                                            }
                                        }

                                        var certTitleTabs = userCertsAllowed.map((certificate, index) => {
                                            return <Nav.Item key={certificate}>
                                                <Nav.Link
                                                    eventKey={certificate}>{certificate}</Nav.Link>
                                            </Nav.Item>
                                        });

                                        var certificatesOrdered = {
                                            FirmaRemota: [],
                                            KeyVault: [],
                                            RedTrust: [],
                                            Software: [],
                                            Uanataca: [],
                                            Nebula: [],
                                            ValidatedID: []
                                        };

                                        _this.setState({
                                            certTitleTabs: certTitleTabs,
                                            certificatesOrdered: certificatesOrdered,
                                            userCertsAllowed: userCertsAllowed,
                                            tabKey: _this.state.tabKey !== "" ? _this.state.tabKey : userCertsAllowed[0],
                                            loadingCertificates: false
                                        });

                                        _this.checkRedTrustStatus()
                                            .then(res => {
                                                _this.setState({ RedTrustIsAuthorized: res.IsAuthorized });
                                                _this.checkFirmaRemotaStatus()
                                                    .then(res => {
                                                        _this.setState({ FirmaRemotaIsAuthorized: res.IsAuthorized });
                                                    })
                                                    .catch(function (error) {
                                                        console.log(error);
                                                        _this.setState({ loadingCertificates: false });
                                                    });
                                            })
                                            .catch(function (error) {
                                                console.log(error)
                                                _this.checkFirmaRemotaStatus()
                                                    .then(res => {
                                                        _this.setState({ FirmaRemotaIsAuthorized: res.IsAuthorized });
                                                    })
                                                    .catch(function (error) {
                                                        console.log(error);
                                                        _this.setState({ loadingCertificates: false });
                                                    });
                                            });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    _this.setState({ loadingCertificates: false });
                                });
                        });
                } else {
                    //no user data
                }
            })
            .catch(function (error) {
                _this.setState({ loadingCertificates: false });
            });
    }

    deleteSoftwareCertificate() {
      const _this = this;
      const { oauthTokenB64, selectedCertificate } = this.state;
      const { legalid } = this.state.userData;
      this.setState({ loadingDeletingProcess: true, showDeleteSoftwareModal: false });

      deleteManagementSignature(generateDeleteSoftwareCertificateUri(legalid, selectedCertificate.GUID), "", oauthTokenB64)
        .then(res => {
            _this.updateCertsTypes();
            _this.handleClose();
            _this.setState({ certDeleted: true });
        })
        .catch(function (error) {
            _this.handleOperationError(error, LOADING_DELETING_PROCESS_KEY); 
        });
    }

    deleteKeyVaultCertificate() {
        const _this = this;
        const { oauthTokenB64, selectedCertificate } = this.state;
        const { legalid } = this.state.userData;
        
        this.setState({ loadingDeletingProcess: true, showDeleteKeyVaultModal: false });

        deleteManagementSignature(generateDeleteKeyVaultCertificateUri(legalid, selectedCertificate.GUID), "", oauthTokenB64)
            .then(res => {
                _this.handleOperationSuccess(res.status, KEYVAULT);
            })
            .catch(function (error) {
                _this.handleOperationError(error, KEYVAULT_LOADING_DELETING_KEY);
            });
    }

    updateCertsTypes() {
        const _this = this;
        const { oauthTokenB64, userCertificateTypes } = this.state;
        const { legalid } = this.state.userData;
        
        for(var certType in userCertificateTypes)
        {
            if(certType.toLowerCase() === VIDMOBILEUSERID)
                userCertificateTypes[certType] = null;
            if(certType.toLowerCase() === SOFTWARE)
                userCertificateTypes[certType] = false;
        }
        axios({
            method: 'put',
            url: getVidCloudMgmtUrl() + '/vidmobileusers/' + legalid + '/certstype',
            data: userCertificateTypes,
            async: true,
            dataType: "json",
            contentType: 'application/json',
            crossDomain: true,
            headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
        })
            .then(res => {
                _this.loadViDMobileUserCertificateList();
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                    _this.setState({ sessionExpired: true });
                    _this.startTimer();
                } else {
                    _this.setState({ errorType: error.response.status, errorData: error.response.data, showError: true });
                }
            });
    }

    handleClose() {
        this.setState({
            showDeleteSoftwareModal: false,
            showDeleteKeyVaultModal: false,
            loadingDeletingProcess: false,
        })
    }

  uploadCertificateKeyVault() {
    const { t } = this.props;
    const _this = this;
    const { oauthTokenB64, KValias, KVpassword, KVpasswordConfirmation, keyVaultCertb64 } = this.state;
    const { legalid } = this.state.userData;
    this.setState({ loadingKVcert: true });

    if ((KVpassword === KVpasswordConfirmation) && KVpassword !== "" && KVpasswordConfirmation !== "") {

      const dataJson = {
        p12: keyVaultCertb64,
        certalias: KValias,
        pin: KVpassword,
        certtype: "KeyVault",
        dynamicpin: false
      };

      postManagementSignature(generateuUploadCertificateKeyVaultUri(legalid), dataJson, oauthTokenB64)
        .then(res => {
            _this.loadViDMobileUserCertificateList();
            _this.setState({
            loadingKVcert: false,
            keyVaultCertb64: '',
            KVpassword: '',
            KVpasswordConfirmation: '',
            KValias: '',
            dataUpdated: true,
            fileName: ""
          });
        })
        .catch(function (error) {
            _this.handleOperationError(error, KEYVAULT_LOADING_UPLOAD_KEY);
        });
    } else {
      _this.setState({ KVpwIsInvalid: true, errorMessageKVpw: t('profile.password-error'), loadingKVcert: false });
    }
  }

    handleFileSelection(e) {
        var _this = this;
        var certificateFile = e.target.files[0];
        var filename = certificateFile.name;
        _this.setState({ fileName: filename });
        var regExpWhiteSpace = new RegExp(" ", "g");
        filename = filename.replace(regExpWhiteSpace, "_");
        var indexExt = filename.lastIndexOf(".");
        var ext = "";
        if (indexExt > 0) {
            ext = filename.substring(indexExt + 1);
        }
        if (ext.toUpperCase() === "P12" || ext.toUpperCase() === "PFX") {
            var reader = new FileReader();
            reader.onload = function () {
                var b64NewCertificate = reader.result;
                //Quitamos el "data:application/x-pkcs12;base64"
                b64NewCertificate = b64NewCertificate.substring((b64NewCertificate.indexOf(',') + 1));
                _this.setState({ keyVaultCertb64: b64NewCertificate });
            };
            reader.readAsDataURL(certificateFile);
        }
    }

    deleteKeyVault(value, data) {
      this.setState({showDeleteKeyVaultModal: value, selectedCertificate: data });
    }

    handleKeyVaultChange(key, value, isInvalid) {
      this.setState({[key]: value});
      if (isInvalid !== null)
        this.setState({KVpwIsInvalid: isInvalid});
    }

    authorizeFirmaremotaCertificate(credentialsData) {
        const _this = this;
        const { oauthTokenB64 } = this.state;
        this.setState({ loadingFRcert: true });

        const dataJson = {
            Username: credentialsData.FRuser,
            Password: credentialsData.FRpassword,
            Otp: ""
        };

        postManagementSignature(generateFirmaRemotaAuthUri(), dataJson, oauthTokenB64)
            .then(res => {
                _this.checkFirmaRemotaStatus()
                    .then(res => {
                        _this.setState({ loadingFRcert: false, dataUpdated: true, FirmaRemotaIsAuthorized: res.IsAuthorized });
                        _this.loadViDMobileUserCertificateList();
                    })
                    .catch(function (error) {
                        _this.handleOperationError(error, FIRMAREMOTA_LOADING_AUTHORIZE_KEY);
                    });
            })
            .catch(function (error) {  
                _this.handleOperationError(error, FIRMAREMOTA_LOADING_AUTHORIZE_KEY);
            })
    }

    unauthorizeFirmaremotaCertificate() {
        const _this = this;
        const { oauthTokenB64 } = this.state;
        this.setState({ loadingFRUnAuth: true });

        deleteManagementSignature(generateFirmaRemotaAuthUri(), "", oauthTokenB64)
            .then(res => {
                _this.loadViDMobileUserCertificateList();
                _this.setState({ loadingFRUnAuth: false, dataUpdated: true });
            })
            .catch(function (error) {
                _this.handleOperationError(error, FIRMAREMOTA_LOADING_UNAUTHORIZE_KEY);
            });
    }

    checkFirmaRemotaStatus() {
        const _this = this;

        const { oauthTokenB64 } = this.state;

        return new Promise(function (resolve, reject) {
            getManagementSignature(generateFirmaRemotaStatusUri(), "", oauthTokenB64)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    } else if (error.response.status !== 409) {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true
                        });
                    }
                    reject(error);
                });
        });
    }

    authorizeRedTrustCertificate(credentials) {
        const _this = this;
        const {oauthTokenB64} = this.state;

        const {RTuser, RTpassword, RTdomain} = credentials;

        var vidMobileUserGuid = this.getViDMobileUserGUIDFromOauthToken();
        
        // TODO: contemplar en algun metodo
        this.setState({ loadingRTcert: true });

        const dataJson = {
            Username: RTuser,
            Password: RTpassword,
            Domain: RTdomain
        };
        
        postPrivSignature(generateRedTrustAuthUri(vidMobileUserGuid), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleOperationSuccess(res.status, REDTRUST);
            })
            .catch(function (error) {
                _this.handleOperationError(error, REDTRUST_LOADING_AUTHORIZE_KEY);                
            });
    }

    handleOperationSuccess(status, signatureName) {
        const _this = this;

        var targetStatus = {
            dataUpdated: true
        };

        if(signatureName === KEYVAULT)
        {
            this.loadViDMobileUserCertificateList();
            this.handleClose();
            this.setState({ certDeleted: true });
        }

        if(signatureName === REDTRUST){
            _this.checkRedTrustStatus()
            .then(res => {
                _this.loadViDMobileUserCertificateList();
                targetStatus.RedTrustIsAuthorized = res.IsAuthorized;
                targetStatus.loadingRTcert = false;
                targetStatus.RTuser = "";
                targetStatus.RTpassword = "";
                targetStatus.RTdomain = "";

                _this.setState(targetStatus);
            })
            .catch(function (error) {
                _this.handleOperationError(error, REDTRUST_LOADING_AUTHORIZE_KEY);
            });
        }

        if(signatureName === UANATACA)
        {
            _this.loadViDMobileUserCertificateList();
            targetStatus.loadingUTcert = false;
            targetStatus.loadingUTUnAuth = false;
            _this.setState(targetStatus);
        }

        if(signatureName === VALIDATEDID)
        {
            _this.loadViDMobileUserCertificateList();
            targetStatus.loadingVIcert = false;
            targetStatus.loadingVIUnAuth = false;
            _this.setState(targetStatus);
        }

    }

    handleOperationError(error, loadingKey) {
        var targetState = {
            [loadingKey]: false
        };

        if (error.response.status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            targetState.sessionExpired = true;
            this.setState(targetState);
            this.startTimer();
            this.handleClose(); //TODO: GESTIONAR MEJOR
            return;
        } 

        targetState.errorType = error.response.status;
        targetState.errorData = error.response.data;
        if (error.response.data.includes('Token not found') || error.response.data.includes('Error connecting to Uanataca')) {
            targetState.errorData = this.props.t('login.invalid-login');
        }
        targetState.showError = true;
        
        this.setState(targetState);
    }

    unauthorizeRedTrustCertificate() {
        const _this = this;
        const { oauthTokenB64 } = this.state;
        var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
        
        this.setState({ loadingRTUnAuth: true });

        deleteManagementSignature(generateRedTrustAuthUri(ViDMobileUserGUID), "", oauthTokenB64)
            .then(res => {
                _this.loadViDMobileUserCertificateList();
                _this.setState({ loadingRTUnAuth: false, dataUpdated: true });
            })
            .catch(function (error) {
                _this.handleOperationError(error, REDTRUST_LOADING_UNAUTHORIZE_KEY);
            });
    }

    synchronizeRedTrustCertificates(credentials) {
        const _this = this;
        const {oauthTokenB64} = this.state;

        const {RTuser, RTpassword, RTdomain} = credentials;

        var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
        this.setState({ loadingRTsync: true });

        const dataJson = {
            Username: RTuser,
            Password: RTpassword,
            Domain: RTdomain
        };

        putPrivSignature(generateRedTrustAuthUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
            .then(res => {
                _this.checkRedTrustStatus()
                    .then(res => {
                        _this.setState({ loadingRTsync: false, dataUpdated: true, RedTrustIsAuthorized: res.IsAuthorized });
                        _this.loadViDMobileUserCertificateList();
                    })
                    .catch(function (error) {
                        console.log(error);
                        _this.setState({ loadingCertificates: false });
                    });
            })
            .catch(function (error) {
                _this.handleOperationError(error, REDTRUST_LOADING_SYNCHRONIZE_KEY);
            });
    }

    checkRedTrustStatus() {
        const _this = this;
        var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
        const { oauthTokenB64 } = this.state;

        return new Promise(function (resolve, reject) {
            getManagementSignature(generateRedTrustAuthStatusUri(ViDMobileUserGUID), null, oauthTokenB64)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    } else if (error.response.status !== 409) {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true
                        });
                    }
                    reject(error);
                });
        });
    }

    authorizeUanatacaCertificate(credencials) {
        var _this = this;
        const { oauthTokenB64 } = this.state;
        var vidMobileUserGuid = this.getViDMobileUserGUIDFromOauthToken();

        this.setState({ loadingUTcert: true });

        const dataJson = {
            Username: credencials.UTuser,
            Password: credencials.UTpassword,
        };
        
        postManagementSignature(generateUanatacaAuthUri(vidMobileUserGuid), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleOperationSuccess(res.status, UANATACA);
            })
            .catch(function (error) {
                _this.handleOperationError(error, UANATACA_LOADING_AUTHORIZE_KEY);                
            });
    }

    authorizeValidatedIdCertificate(credencials) {
        var _this = this;
        const { oauthTokenB64 } = this.state;
        var vidMobileUserGuid = this.getViDMobileUserGUIDFromOauthToken();

        this.setState({ loadingVIcert: true });

        const dataJson = {
            Username: credencials.UTuser,
            Password: credencials.UTpassword,
        };

        postManagementSignature(generateValidatedIdAuthUri(vidMobileUserGuid), dataJson, oauthTokenB64)
            .then(res => {
                _this.handleOperationSuccess(res.status, VALIDATEDID);
            })
            .catch(function (error) {
                _this.handleOperationError(error, VALIDATEDID_LOADING_AUTHORIZE_KEY);
            });
    }

    sendCredentialsAndGetAuthenticators(credentials) {
      const _this = this;
      const { oauthTokenB64 } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
      this.setState({ loadingNB: true });

      const dataJson = {
        Client: credentials.NBClient,
        Username: credentials.NBuser,
        Password: credentials.NBpassword,
      };
  
        postManagementSignature(generateNebulaAuthUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
          .then(res => {
            _this.authenticators = res.data.Authenticators;

              if (this.authenticators.length === 1) {
                _this.setState({
                  nebulaStep1: false,
                  nebulaStep2: false,
                  NBauthenticator: this.authenticators[0]
                });
                _this.sendAuthenticator();
              } else {
                _this.setState({
                  nebulaStep1: false,
                  nebulaStep2: true,
                  loadingNB: false
                });
              }
          })
          .catch(function (error) {
            _this.handleOperationError(error, NEBULA_LOADING_KEY);
          });
    }

    sendAuthenticator() {
      const _this = this;
      const { oauthTokenB64, NBauthenticator } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
      this.setState({ loadingNB: true });

      const dataJson = {
        Authenticator: NBauthenticator,
      };
  
      postManagementSignature(generateNebulaAuthMiddleUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
          .then(res => {
              _this.setState({
                nebulaStep1: false,
                nebulaStep2: false,
                nebulaStep3: true,
                loadingNB: false
              });
          })
          .catch(function (error) {
            _this.handleOperationError(error, NEBULA_LOADING_KEY);
          });
    }

    sendChallenge(credentials) {
      const _this = this;
      const { oauthTokenB64 } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
      this.setState({ loadingNB: true });

      const dataJson = {
        Challenge: credentials.NBChallenge,
        };
  
      postManagementSignature(generateNebulaAuthEndUri(ViDMobileUserGUID), dataJson, oauthTokenB64)
          .then(res => {
            _this.loadViDMobileUserCertificateList();
            _this.setState({
              nebulaStep1: false,
              nebulaStep2: false,
              nebulaStep3: false,
              loadingNB: false
            });
          })
          .catch(function (error) {
              if (error.response.status === 401) {
                  /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                  _this.setState({ sessionExpired: true, loadingNB: false });
                  _this.startTimer();
              } else {
                  _this.setState({
                      errorType: error.response.status,
                      errorData: error.response.data,
                      showError: true,
                      loadingNB: false
                  });
              }
          });
    }

    unauthorizeUanatacaCertificate() {
        var _this = this;
        const { oauthTokenB64 } = this.state;
        var vidMobileUserGuid = this.getViDMobileUserGUIDFromOauthToken();
        
        this.setState({ loadingUTUnAuth: true });

        deleteManagementSignature(generateUanatacaAuthUri(vidMobileUserGuid), "", oauthTokenB64)
            .then(res => {
                _this.handleOperationSuccess(res.status, UANATACA);
            })
            .catch(function (error) {
                _this.handleOperationError(error, UANATACA_LOADING_UNAUTHORIZE_KEY);                
            });
    }

    unauthorizeValidatedIdCertificate() {
        var _this = this;
        const { oauthTokenB64 } = this.state;
        var vidMobileUserGuid = this.getViDMobileUserGUIDFromOauthToken();
        this.setState({ loadingVIUnAuth: true });

        deleteManagementSignature(generateValidatedIdAuthUri(vidMobileUserGuid), "", oauthTokenB64)
            .then(res => {
                _this.handleOperationSuccess(res.status, VALIDATEDID);
            })
            .catch(function (error) {
                _this.handleOperationError(error, VALIDATEDID_LOADING_UNAUTHORIZE_KEY);
            });
    }

    unauthorizeNebulaCertificate() {
      const _this = this;
      const { oauthTokenB64 } = this.state;
      var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();
      this.setState({ loadingNB: true });

      axios({
          method: 'delete',
          url: getVidCloudMgmtUrl() + '/vidmobileuser/' + ViDMobileUserGUID + '/nebulaauth',
          data: '',
          async: true,
          dataType: "json",
          contentType: 'application/json',
          crossDomain: true,
          headers: { 'Authorization': 'Bearer ' + oauthTokenB64 }
      })
          .then(res => {
            _this.loadViDMobileUserCertificateList();
            _this.setState({ loadingNB: false, dataUpdated: true });
          })
          .catch(function (error) {
            _this.handleOperationError(error, NEBULA_LOADING_KEY);
          });
  }

  handleAuthenticatorChange (key, value) {
    this.setState({ [key]: value })
  }

    handleSelect = (key) => {
        const { userCertsAllowed } = this.state;
        const newTabs = userCertsAllowed.map((certificate) => {
            return <Nav.Item key={certificate}>
                <Nav.Link eventKey={certificate}>{certificate === "FirmaRemota" ? "itAgile": certificate}</Nav.Link>
            </Nav.Item>
        })
        this.setState({ tabKey: key, certTitleTabs: newTabs });
    }

    render() {
        const { t } = this.props;
        const {
            certTitleTabs, showDeleteSoftwareModal, showDeleteKeyVaultModal, certDeleted, showError, errorType, tabKey,
            errorData, sessionExpired, redirect, loadingCertificates, loadingDeletingProcess,
            showJumbotron,
            loadingKVcert, dataUpdated, loadingFRcert, loadingRTcert, loadingUTcert, loadingRTsync, loadingFRUnAuth, loadingRTUnAuth, loadingUTUnAuth, language, time, userIsLoggedByAuthCerts,
            userCertificateTypes, loadingCertificateTypes, loadingNB, loadingVIcert, loadingVIUnAuth
        } = this.state;
        var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';
        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }
        if (userIsLoggedByAuthCerts) {
            return <Redirect to={{
                pathname: '/authcerts'
            }}
            />;
        }

        const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;

        return (
            <div>
                <Header />
                {showJumbotron &&
                    <Jumbotron>
                        <MdClose className="close-jumbotron"
                            onClick={() => this.setState({ showJumbotron: !showJumbotron })} />
                        <div className="jumbotron-content">
                            <img src={warning} alt="Warning icon"/>
                            <p>{t('certificates.certificates')}. &nbsp;
                            {
                                !loadingCertificates && !loadingCertificateTypes &&
                                (
                                    !userCertificateTypes ?
                                        certsNotAvailable :
                                            t('certificates.certificates-available')
                                )
                            }
                            </p>
                        </div>
                    </Jumbotron>
                }
                {dataUpdated &&
                    <Toast
                        onClose={() => this.setState({ dataUpdated: false })}
                        show={dataUpdated}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('certificates.task-completed')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('certificates.task-completed-text')}</Toast.Body>
                    </Toast>
                }
                {showError &&
                    <Toast
                        onClose={() => this.setState({ showError: false })}
                        show={showError}
                        animation={true}

                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 11
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                }
                {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                                <small>{timeText}</small>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.doLogOut()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                }
                {certDeleted &&
                    <Toast
                        onClose={() => this.setState({ certDeleted: false })}
                        show={certDeleted}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('certificates.certificate-deleted')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('certificates.certificate-deleted-text')}</Toast.Body>
                    </Toast>
                }
                {(loadingCertificates || loadingCertificateTypes) &&
                    <LoadingCircle centerVertically={true} />
                }
                {!loadingCertificates && !loadingCertificateTypes && userCertificateTypes &&
                    <div className="certificates-container">
                        <Modal show={showDeleteSoftwareModal} onHide={() => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('certificates.sw-delete')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{t('certificates.certificate-delete-text')}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <div>
                                    <Button
                                        className="reject"
                                        onClick={() => this.handleClose()}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => this.deleteSoftwareCertificate()}
                                        style={{ marginLeft: 20 }}
                                    >
                                        {t('general.confirm')}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showDeleteKeyVaultModal} onHide={() => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('certificates.kv-delete')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{t('certificates.certificate-delete-text')}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <div>
                                    <Button
                                        className="reject"
                                        onClick={() => this.handleClose()}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => this.deleteKeyVaultCertificate()}
                                        style={{ marginLeft: 20 }}
                                    >
                                        {t('general.confirm')}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={loadingDeletingProcess || loadingKVcert || loadingFRcert || loadingRTcert || loadingUTcert ||
                                loadingRTsync || loadingFRUnAuth || loadingRTUnAuth || loadingUTUnAuth || loadingNB || loadingVIcert || loadingVIUnAuth}
                            onHide={() => this.handleClose()}>
                            <Modal.Header>
                                {loadingDeletingProcess && <Modal.Title>{t('certificates.delete-process')}</Modal.Title>}
                                {loadingKVcert && <Modal.Title>{t('certificates.kv-create-process')}</Modal.Title>}
                                {loadingFRcert && <Modal.Title>{t('certificates.fr-create-process')}</Modal.Title>}
                                {loadingUTcert && <Modal.Title>{t('certificates.ut-create-process')}</Modal.Title>}
                                {loadingFRUnAuth && <Modal.Title>{t('certificates.fr-delete-process')}</Modal.Title>}
                                {loadingRTcert && <Modal.Title>{t('certificates.rt-create-process')}</Modal.Title>}
                                {loadingRTUnAuth && <Modal.Title>{t('certificates.rt-delete-process')}</Modal.Title>}
                                {loadingUTUnAuth && <Modal.Title>{t('certificates.ut-delete-process')}</Modal.Title>}
                                {loadingRTsync && <Modal.Title>{t('certificates.rt-sync-process')}</Modal.Title>}
                                {loadingNB && <Modal.Title>{t('login.loading')}</Modal.Title>}
                                {loadingVIcert && <Modal.Title>{t('certificates.vi-create-process')}</Modal.Title>}
                                {loadingVIUnAuth && <Modal.Title>{t('certificates.vi-delete-process')}</Modal.Title>}
                            </Modal.Header>
                            <Modal.Body>
                                <LoadingCircle />
                            </Modal.Body>
                        </Modal>
                        <MdRefresh className="back-button2" onClick={() => this.loadViDMobileUserCertificateList()} />

                        <div>
                            <Tab.Container defaultActiveKey={tabKey} onSelect={(key) => this.handleSelect(key)}>
                                <Row>
                                    <Col sm={3} bsPrefix="left-column col">
                                        <Nav variant="pills" className="flex-column">
                                            {certTitleTabs}
                                        </Nav>
                                    </Col>
                                    <Col sm={9} bsPrefix="right-column col">
                                        <Tab.Content bsPrefix="tab-content tab-content2">
                                            <Tab.Pane eventKey="Software">
                                                <SoftwareCertificates 
                                                    parentState={this.state} 
                                                    onCertificateDelete={(row) => this.setState({ showDeleteSoftwareModal: true, selectedCertificate: row })} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="KeyVault">
                                                <KeyVaultCertificates
                                                    parentState={this.state}
                                                    onHandleFileSelection={this.handleFileSelection}
                                                    onUploadCertificateKeyVault={this.uploadCertificateKeyVault}
                                                    onDeleteKeyVault={(value, data) => this.deleteKeyVault(value, data)}
                                                    onFileInput={(ref) => this.fileInput = ref}
                                                    onHandleKeyVaultChange={(key, value, isInvalid) => this.handleKeyVaultChange(key, value, isInvalid)}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="FirmaRemota">
                                                <FirmaRemotaCertificates 
                                                    parentState={this.state}
                                                    onAuthorizeFirmaremotaCertificate={this.authorizeFirmaremotaCertificate}
                                                    onUnauthorizeFirmaremotaCertificate={this.unauthorizeFirmaremotaCertificate} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="RedTrust">
                                            <RedTrustCertificates 
                                                    parentState={this.state} 
                                                    authorizeRedTrustCertificate={this.authorizeRedTrustCertificate}
                                                    unauthorizeRedTrustCertificate={this.unauthorizeRedTrustCertificate}
                                                    synchronizeRedTrustCertificates={this.synchronizeRedTrustCertificates}/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Uanataca">
                                                <UanatacaCertificates
                                                    parentState={this.state}
                                                    onAuthorizeUanatacaCertificate={this.authorizeUanatacaCertificate}
                                                    onUnauthorizeUanatacaCertificate={this.unauthorizeUanatacaCertificate}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ValidatedID">
                                                <ValidatedIdCertificates
                                                    parentState={this.state}
                                                    onAuthorizeValidatedIdCertificate={this.authorizeValidatedIdCertificate}
                                                    onUnauthorizeValidatedIdCertificate={this.unauthorizeValidatedIdCertificate}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Swisscom">
                                                <div className="table-container">
                                                    {t('certificates.certificate-swisscom-available')}
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Nebula">
                                                <NebulaCertificates 
                                                    parentState={this.state} 
                                                    onChangeFormControl={(key, value) => this.handleAuthenticatorChange(key,value)} 
                                                    onSendCredentialsAndGetAuthenticators={this.sendCredentialsAndGetAuthenticators}
                                                    onSendAuthenticator={this.sendAuthenticator}
                                                    onSendChallenge={this.sendChallenge}
                                                    onUnauthorizeNebulaCertificate={this.unauthorizeNebulaCertificate}
                                                    authenticators ={this.authenticators} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                }
            </div>
        );
    }

    componentDidMount() {
        ThemeService.setTheme();

        var authCerts = sessionStorage.getItem('authCerts');
        if (authCerts !== null) {
            this.setState({ userIsLoggedByAuthCerts: true });
        }
        this.loadViDMobileUserCertificateList();
    }
}

export default withTranslation()(Certificates);
