import { LOCAL, PRE, PRO } from "../../assets/constants";

const getEnv = () => sessionStorage.getItem('environment') || "PRE";

export const getVidCloudPrivUrl = (env) => {
    if (!env) env = getEnv();
    const urlPRE = "https://pre-fdgoihnerw.validatedid.com/api";
    const urlPRO = "https://fdgoihnerw.validatedid.com/api";
    return env === PRE ? urlPRE : urlPRO;
}

export const getVidCloudMgmtUrl = (env) => {
    if (!env) env = getEnv();
    const urlPRE = "https://pre-vidsignercloudmgmt.validatedid.com/api";
    const urlPRO = "https://vidsignercloudmgmt.validatedid.com/api";
    return env === PRE ? urlPRE : urlPRO;
}

const getEnvFromClientURL = () => {
    const { host } = window.location;
    if (host.includes("pre")) return PRE;
    if (host.includes('centralized') && !host.includes("pre")) return PRO;
    return LOCAL;
};

export const getTargetName = (type, env) => {
    if (!env) env = getEnvFromClientURL();

    if (type === 'google') return `CENTRALIZED_LOGIN_GOOGLE_${env}`;
    if (type === 'ms') return `CENTRALIZED_LOGIN_MS_${env}`;
    if (type === 'ssi') return `CENTRALIZED_LOGIN_VIDWALLET_${env}`;
}