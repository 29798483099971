import React, {Component} from 'react';
import './Profile.css';
import {withTranslation} from 'react-i18next';
import Header from "../Header/Header";
import {Button, Form, FormControl, InputGroup, Jumbotron, Modal, Tab, Tabs, Toast} from "react-bootstrap";
import axios from "axios";
import SignatureCanvas from 'react-signature-canvas'
import {Redirect} from "react-router-dom";
import {PulseLoader} from "react-spinners";
import {MdClose} from "react-icons/md";
import ThemeService from '../../services/ThemeService';
import LoadingCircle from './../LoadingCircle'
import { getVidCloudMgmtUrl } from '../../services/environment/environmentService';
import { SecurityValidation } from '../SecurityValidation/SecurityValidation';

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
            environment: sessionStorage.getItem('environment'),
            user: sessionStorage.getItem('user'),
            language: localStorage.getItem('i18nextLng'),
            userData: {},
            newPassword: '',
            newPasswordConfirmation: '',
            loading: false,
            signatureImage: '',
            sessionExpired: false,
            redirect: false,
            time: 0,
            imageError: false,
            imageErrorText: '',
            dataUpdated: false,
            imageFile: '',
            newPasswordError: false,
            loadingSavingDataProcess: false,
            showError: false,
            errorData: '',
            errorType: '',
            userIsLoggedByAuthCerts: false,
            canvasParentWidth: 0,
            canvasParentHeight: 200,
            newSignature: false,
            windowWidth: window.innerWidth,
            showJumbotron: true,
            tabKey: 'file',
            fileName: "",
            isPasswordSecure: false
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.sendSignatureImageToViDSigner = this.sendSignatureImageToViDSigner.bind(this);
        this.resizeCanvas = this.resizeCanvas.bind(this);
    }

    doLogOut() {
        sessionStorage.clear();
        this.stopTimer();
        this.setState({redirect: true})
    }

    startTimer() {
        this.timer = setInterval(() => this.setState({
            time: this.state.time + 1
        }), 60000);
    }

    stopTimer() {
        clearInterval(this.timer)
    }

    loadUserData() {
        this.setState({loading: true});
        const _this = this;
        const {oauthTokenB64} = this.state;


        var config = {
            headers: {
                'Authorization': 'Bearer ' + oauthTokenB64
            }
        };
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({sessionExpired: true});
                        _this.startTimer();
                    }
                    reject(error);
                });
        });
    }

    getSignatureImage() {
        const {oauthTokenB64} = this.state;
        const {legalid} = this.state.userData;

        return new Promise(function (resolve, reject) {
            axios({
                method: 'get',
                url: getVidCloudMgmtUrl() + '/vidmobileusers/' + legalid + '/signatureimage',
                data: '',
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {'Authorization': 'Bearer ' + oauthTokenB64}
            })
                .then(res => {
                    resolve(res.data.SignatureImage);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    clearSignatureImage(e) {
        this.fileInput.value = "";
        this.setState({signatureImage: '', fileName: ""});
    }

    getUserData() {
        var _this = this;
        this.loadUserData()
            .then(res => {
                if (res) {
                    _this.setState({userData: res, loading: false, loadingSignatureImage: true});
                    _this.getSignatureImage()
                        .then(res => {
                            if (res.SignatureImage !== "") {
                                _this.setState({signatureImage: res, loadingSignatureImage: false});
                            } else {
                                //no signature image
                                _this.setState({loadingSignatureImage: false});
                            }
                        })
                        .catch(function (error) {
                            _this.setState({loadingSignatureImage: false});
                        });
                } else {} //no user data
            })
            .catch(function (error) {});
    }

    clear = () => {
        this.sigPad.clear()
        this.setState({
            trimmedDataURL: null
        })
    };

    trim = () => {
        const {tabKey} = this.state;
        var _this = this;
        if (typeof this.sigPad !== 'undefined' && tabKey === 'canvas'){
            /**Hay garabato dibujado y se hacen las comprobaciones para guardarlo en signatureImage*/
            let signatureb64str = this.sigPad.toDataURL().split(","),
                signatureb64str1 = signatureb64str[0].split(":"),
                signatureb64str2 = signatureb64str1[1].split(";"),
                signatureName = "sg" + Math.random().toString(36).substring(7) + ".png",
                signatureSizeBytes = (signatureb64str[1].length * (3/4));

            this.setState({
                trimmedDataURL: this.sigPad.getTrimmedCanvas()
                    .toDataURL('image/png'),
                signatureImage: signatureb64str[1],
                imageFile: {
                    lastModified: new Date().getTime(),
                    lastModifiedDate: new Date(),
                    name: signatureName,
                    size: signatureSizeBytes,
                    type: signatureb64str2[0],
                    webkitRelativePath: ""
                }
            }, function() {
                _this.saveUserData();
            });
        }
        else {
            /**No hay garabato dibujado */
            _this.saveUserData();
        }
    };

    resizeCanvas(e) {
        let canvas = document.getElementsByClassName('sigCanvas')[0];
        if (typeof canvas !== "undefined"){
            let canvasParentWidth = document.getElementsByClassName("signatureCanvas-container")[0].offsetWidth;
            /**Width según el width del padre (-30px para que quede dentro del container) y altura fija */
            this.setState({canvasParentWidth: canvasParentWidth-30, canvasParentHeight: 200});
            this.sigPad.clear();
        }
    }

    handleFileSelection(e) {
        var _this = this;
        var imageFile = e.target.files[0];
        
        _this.setState({fileName: imageFile.name});

        if (typeof imageFile !== "undefined" && imageFile !== "") {
            var reader = new FileReader();
            reader.onload = function () {
                var b64SignatureImage = reader.result;
                b64SignatureImage = b64SignatureImage.substring((b64SignatureImage.indexOf(',') + 1));
                _this.setState({imageFile: imageFile, signatureImage: b64SignatureImage});
            };
            reader.readAsDataURL(imageFile);
        }
    }

    discardChanges(){
        this.setState({fileName:""});
        this.getUserData();
    }

    saveUserData() {
        const {imageFile, signatureImage, tabKey} = this.state;
        const {t} = this.props;

        var _this = this;
        if (typeof imageFile !== "undefined" && imageFile !== "" && tabKey === "file") {
            this.setState({loadingSavingDataProcess: true});
            var filename = imageFile.name;
            var regExpWhiteSpace = new RegExp(" ", "g");
            filename = filename.replace(regExpWhiteSpace, "_");
            var indexExt = filename.lastIndexOf(".");
            var ext = "";
            if (indexExt > 0) {
                ext = filename.substring(indexExt + 1);
            }
            if (ext.toUpperCase() === "PNG" || ext.toUpperCase() === "JPEG" || ext.toUpperCase() === "JPG") {
                //good file extension
                _this.handlePasswordChange();
            } else {
                console.log('File selected invalid: wrong extension')
            }
        } else {
            if (signatureImage !== "") {
                /**No hay imagen seleccionada pero HAY imagen de firma cargada*/
                _this.handlePasswordChange();
            } else {
                /**No hay imagen seleccionada y NO HAY imagen de firma cargada*/
                _this.setState({imageErrorText: t('profile.image-error'), imageError: true});
            }
            this.setState({loadingSavingDataProcess: false});
        }
    }

    sendSignatureImageToViDSigner(b64SignatureImage) {
        var signatureImageDTO = {};
        const {oauthTokenB64} = this.state;
        const {legalid} = this.state.userData;
        signatureImageDTO.SignatureImage = b64SignatureImage;

        return new Promise(function (resolve, reject) {
            axios({
                method: 'put',
                url: getVidCloudMgmtUrl() + '/vidmobileusers/' + legalid + '/signatureimage',
                data: signatureImageDTO,
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: {'Authorization': 'Bearer ' + oauthTokenB64}
            })
                .then(res => {
                    resolve(res);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    handlePasswordChange() {
        const _this = this;
        const {newPassword, newPasswordConfirmation, signatureImage} = this.state;
        const {t} = this.props;
        if (newPassword !== "") {
            //quiere cambiar la contraseña
            if (newPassword !== newPasswordConfirmation) {
                this.setState({newPasswordErrorText: t('profile.password-error'), newPasswordError: true, loadingSavingDataProcess: false});
            } else {
                this.sendSignatureImageToViDSigner(signatureImage)
                    .then(res => {
                        if (res) {
                            _this.setState({imageError: false, fileName:""});
                            this.updateUserData(newPassword);
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 400) {
                                /**IMAGEN DEMASIADO GRANDE*/
                                _this.setState({imageErrorText: error.response.data, imageError: true});
                            }
                            if (error.response.status === 401) {
                                /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                                _this.setState({sessionExpired: true});
                                _this.startTimer();
                            }
                        }
                        _this.setState({loadingSavingDataProcess: false});
                    });
            }
        } else {
            /**No quiere cambiar la contraseña*/
            this.sendSignatureImageToViDSigner(signatureImage)
                .then(res => {
                    if (res) {
                        _this.setState({imageError: false});
                        this.updateUserData(null);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 400) {
                            /**IMAGEN DEMASIADO GRANDE*/
                            _this.setState({imageErrorText: error.response.data, imageError: true});
                        }
                        if (error.response.status === 401) {
                            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                            _this.setState({sessionExpired: true});
                            _this.startTimer();
                        }
                    }
                    _this.setState({loadingSavingDataProcess: false});
                });
        }
    }

    updateUserData(newPassword) {
        var _this = this;
        const {signatureImage, oauthTokenB64} = this.state;
        const {legalid, email, userid, username} = this.state.userData;

        axios({
            method: 'put',
            url: getVidCloudMgmtUrl() + '/vidmobileuser/',
            data: {
                "email": email,
                "legalid": legalid,
                "password": newPassword,
                "userid": userid,
                "username": username,
                "signatureimage": signatureImage,
                "active": "true"
            },
            async: true,
            dataType: "json",
            contentType: 'application/json',
            crossDomain: true,
            headers: {'Authorization': 'Bearer ' + oauthTokenB64}
        })
            .then(res => {
                _this.setState({
                    dataUpdated: true,
                    newPasswordError: false,
                    loadingSavingDataProcess: false,
                    newPassword: '',
                    newPasswordConfirmation: '',
                });
                _this.getUserData();
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                    _this.setState({sessionExpired: true, loadingSavingDataProcess: false});
                    _this.startTimer();
                } else {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        loadingSavingDataProcess: false
                    });
                }
            });
    }

    handleClose() {
        this.setState({
            loadingSavingDataProcess: false,
            newSignature: !this.state.newSignature
        })
    }

    render() {
        const {t} = this.props;
        const {language, loading, signatureImage, sessionExpired, time, redirect, imageError, imageErrorText, dataUpdated, newPasswordErrorText,
            newPasswordError, loadingSavingDataProcess, loadingSignatureImage, showError, errorType, errorData, userIsLoggedByAuthCerts, newSignature, showJumbotron, tabKey, 
            newPassword, isPasswordSecure} = this.state;
        var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';
        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }
        if (userIsLoggedByAuthCerts) {
            return <Redirect to={{
                pathname: '/authcerts'
            }}
            />;
        }

        return (
            <div>
                {dataUpdated &&
                <Toast
                    onClose={() => this.setState({dataUpdated: false})}
                    show={dataUpdated}
                    delay={3000}
                    autohide
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        margin: '0 auto',
                        backgroundColor: 'rgba(255,255,255,1)',
                        border: 'none',
                        zIndex: 10
                    }}
                >
                    <Toast.Header
                        closeButton={false}
                    >
                        <strong className="mr-auto">{t('profile.data-updated')}</strong>
                    </Toast.Header>
                    <Toast.Body>{t('profile.data-updated-text')}</Toast.Body>
                </Toast>
                }
                {sessionExpired &&
                <div className="toast-container">
                    <Toast
                        show={sessionExpired}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.notification')}</strong>
                            <small>{timeText}</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p>{t('general.session-expired')}</p>
                            <Button
                                type="button"
                                className="btn btn-primary"
                                id="sign-all-docs"
                                onClick={() => this.doLogOut()}
                            >
                                {t('general.accept')}
                            </Button>
                        </Toast.Body>
                    </Toast>
                </div>
                }
                {showError &&
                <Toast
                    onClose={() => this.setState({showError: false})}
                    show={showError}
                    animation={true}

                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        margin: '0 auto',
                        backgroundColor: 'rgba(255,255,255,1)',
                        border: 'none',
                        zIndex: 11
                    }}
                >
                    <Toast.Header
                        closeButton={true}
                    >
                        <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                    </Toast.Header>
                    <Toast.Body>{errorData}</Toast.Body>
                </Toast>
                }
                <Modal show={loadingSavingDataProcess} onHide={() => this.handleClose()}>
                    <Modal.Header>
                        <Modal.Title>{t('profile.update-process')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingCircle />
                    </Modal.Body>
                </Modal>
                <Header/>
                {showJumbotron &&
                <Jumbotron>
                    <MdClose className="close-jumbotron"
                             onClick={() => this.setState({showJumbotron: !showJumbotron})}/>
                    <div className="jumbotron-content">
                        <h1>{t('profile.update')}</h1>
                        <p>
                            {t('profile.welcome')}
                        </p>
                    </div>
                </Jumbotron>
                }
                {loading &&
                    <LoadingCircle centerVertically={true} />
                }
                {!loading &&
                <div style={{paddingBottom: '2rem', paddingTop: '2rem'}}>
                    <div className="profile-container">
                        {(newPasswordError) &&
                        <div className="error-container">
                            {newPasswordErrorText}
                        </div>
                        }
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">{t('profile.user-id')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                readOnly
                                defaultValue={this.state.userData.userid || ''}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="inputGroup-sizing-default">{t('profile.user-name')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                readOnly
                                defaultValue={this.state.userData.username || ''}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="inputGroup-sizing-default">{t('profile.user-legalId')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                readOnly
                                defaultValue={this.state.userData.legalid || ''}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="inputGroup-sizing-default">{t('profile.user-email')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="email"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.userData.email || ''}
                                onChange={e => this.setState({
                                    userData: {
                                        ...this.state.userData,
                                        email: e.target.value
                                    }
                                })}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="inputGroup-sizing-default">{t('profile.new-password')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                required
                                type="password"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.newPassword}
                                onChange={e => this.setState({newPassword: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="inputGroup-sizing-default">{t('profile.repeat-password')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                required
                                type="password"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.newPasswordConfirmation}
                                onChange={e => this.setState({newPasswordConfirmation: e.target.value})}
                            />
                        </InputGroup>
                        {
                            newPassword !== "" && <SecurityValidation password={this.state.newPassword} updateIsPasswordSecure={(isSecure) => this.setState({
                            isPasswordSecure: isSecure
                        })} />
                        }
                         <Button
                            type="button"
                            id="newSignatureButton"
                            className="btn btn-primary"
                            onClick={() => this.setState({newSignature: !this.state.newSignature})}
                        >
                            {t('profile.new-signature')}
                        </Button>
                        </div>
                        {newSignature &&
                        <div className="signature-tabs">
                            <Tabs id="new-signature-tab" defaultActiveKey={tabKey} onSelect={k => this.setState({tabKey: k})}>
                                <Tab eventKey="file" title={t('profile.from-file')}>
                                    <Form.Group
                                        controlId="formFile"
                                        style={{marginTop: 20}}
                                    >
                                        <Form.File 
                                            accept="image/png,image/jpg,image/jpeg"
                                            onChange={(e) => this.handleFileSelection(e)}
                                            ref={ref => this.fileInput = ref}
                                            label={this.state.fileName || t('general.form-file-label')}
                                            data-browse={t('general.form-file-browse')}
                                            custom
                                        />
                                        <Form.Text className="text-muted">
                                            {t('profile.select-file')}
                                        </Form.Text>
                                    </Form.Group>
                                    {(imageError) &&
                                    <div className="error-container">
                                        {imageErrorText}
                                    </div>
                                    }
                                    {loadingSignatureImage &&
                                    <div style={{marginTop: 20}}>
                                        <PulseLoader
                                            color={'#00bf71'}
                                            loading={true}
                                        />
                                    </div>
                                    }
                                    {!loadingSignatureImage && signatureImage !== '' &&
                                    <div className="signature-image-container">
                                        <img style={{maxWidth: '75%'}} alt="signatureImage"
                                             src={`data:image/jpeg;base64,${signatureImage}`}/>
                                        <Button
                                            type="button"
                                            id="profileImageDeleteButton"
                                            className="signature-image reject"
                                            onClick={() => this.clearSignatureImage()}
                                        >
                                            {t('profile.image-delete')}
                                        </Button>
                                    </div>
                                    }
                                </Tab>
                                <Tab eventKey="canvas" title={t('profile.from-canvas')}>
                                    <div className="signatureCanvas-container">
                                        <SignatureCanvas
                                            backgroundColor={'rgba(255,255,255,1)'}
                                            penColor={'rgb(0,0,255)'}
                                            canvasProps={{
                                                width: this.state.canvasParentWidth,
                                                height: this.state.canvasParentHeight,
                                                className: 'sigCanvas'
                                            }}
                                            clearOnResize={true}
                                            ref={(ref) => {
                                                this.sigPad = ref
                                            }}
                                        />
                                    </div>
                                    <div className="button-container">
                                        <Button
                                            type="button"
                                            id="signatureDeleteButton"
                                            className="btn reject"
                                            onClick={this.clear}
                                            style={{marginLeft: '10px'}}
                                        >
                                            {t('profile.signature-delete')}
                                        </Button>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                        }
                    <div className="button-container">
                        <Button
                            disabled={(newPassword !== "" && !isPasswordSecure)}
                            type="submit"
                            id="submitButtonSaveUserData"
                            className="btn btn-primary"
                            onClick={this.trim}
                        >
                            {t('profile.save')}
                        </Button>
                        <Button
                            type="submit"
                            id="submitButtonDiscardChanges"
                            className="btn reject"
                            onClick={() => this.discardChanges()}
                            style={{marginLeft:20}}
                        >
                            {t('profile.discard')}
                        </Button>
                    </div>
                </div>
                }
            </div>
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeCanvas);
    }

    componentDidMount() {
        ThemeService.setTheme();
        
        /**Load user data*/
        var authCerts = sessionStorage.getItem('authCerts');
        if (authCerts !== null){
            this.setState({userIsLoggedByAuthCerts: true});
        }
        this.getUserData();
        /**Set canvas width from profile container. Resize canvas function activates on window resizing event.*/
        this.setState({canvasParentWidth: document.getElementsByClassName("profile-container")[0].offsetWidth - (this.state.windowWidth > 414 ? 160 : 60)});
        this.resizeCanvas();
        window.addEventListener("resize", this.resizeCanvas);
    }

}

export default withTranslation()(Profile);