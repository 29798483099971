import React, { Component } from 'react';
import './AuthCerts.css';
import { withTranslation } from 'react-i18next';
import { Button, Jumbotron, ListGroup, Navbar, Toast } from 'react-bootstrap';
import axios from "axios";
import { Redirect } from "react-router-dom";
import ThemeService from '../../services/ThemeService';
import Utils from '../../services/Utils';
import SigningProcessModal from '../Modals/SigningProcessModal';
import LoadingCircle from '../LoadingCircle';
import { getVidCloudMgmtUrl } from '../../services/environment/environmentService';
import { generateKeyVaultSendOTPUri, postPrivSignature, generateFirmaRemotaOTPUri, generateFirmaRemotaSessionUri, putManagementSignature, putPrivSignature, generateKeyVaultOpenSessionUri, generateSwisscomSignHashUri, generateSwisscomCompleteSignatureHashUri } from '../../services/signatureMethods/customServiceSignature';
import KeyVaultSignature from '../KeyVaultSignature/KeyVaultSignature.component';
import SwisscomSignature from '../SwisscomSignature/SwisscomSignature.component';
import FirmaRemotaSignature from '../FirmaRemota/FirmaRemota.component';


class AuthCerts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
            environment: sessionStorage.getItem('environment'),
            user: sessionStorage.getItem('user'),
            language: localStorage.getItem('i18nextLng'),
            time: 0,
            userAuth: sessionStorage.getItem('userAuth'),
            responseType: sessionStorage.getItem('responseType'),
            clientId: sessionStorage.getItem('clientId'),
            redirectUri: sessionStorage.getItem('redirectUri'),
            scope: sessionStorage.getItem('scope'),
            lang: sessionStorage.getItem('lang'),
            state: sessionStorage.getItem('state'),
            hash: sessionStorage.getItem('hash'),
            hashalgo: sessionStorage.getItem('hashalgo'),
            token: sessionStorage.getItem('token'),
            loading: false,
            showKeyVaultModal: false,
            showFirmaRemotaModal: false,
            certificateSelected: {},
            sessionExpired: false,
            showError: false,
            errorData: '',
            errorType: '',
            redirect: false,
            sessionOpened: false,
            loadingSignatureProcess: false,
            showSwisscomModal: false,
            showSwisscomConfirmationModal: false,
            swisscomRedirectURL: "",
            swisscomRequestID: "",
            loadingSwisscomCheck: false,
            swisscomError: '',
            loadingSwisscomRequest: false,
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.handleGenericError = this.handleGenericError.bind(this);
        this.handleSessionExpiredError = this.handleSessionExpiredError.bind(this);
        this.keyVaultOpenSession = this.keyVaultOpenSession.bind(this);
        this.firmaRemotaOpenSession = this.firmaRemotaOpenSession.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    startTimer() {
        this.timer = setInterval(() => this.setState({
            time: this.state.time + 1
        }), 60000);
    }

    stopTimer() {
        clearInterval(this.timer)
    }

    loadViDMobileUserCertificateList() {
        const { t } = this.props;
        this.setState({ loading: true });
        const _this = this;
        this.loadUserData()
            .then(res => {
                if (res) {
                    _this.setState({ userData: res });
                    _this.loadViDMobileUserCertificateTypes()
                        .then(res => {
                            _this.setState({ userCertificatesAuth: res });
                            _this.getUserCertificates()
                                .then(res => {
                                    if (_this.state.userCertificatesAuth) {
                                        var ViDMobileUserAllowFirmaRemoraCerts = _this.state.userCertificatesAuth.FirmaRemota;
                                        var ViDMobileUserAllowKeyVaultCerts = _this.state.userCertificatesAuth.KeyVault;
                                        var ViDMobileUserAllowSwisscomCerts = _this.state.userCertificatesAuth.Swisscom;
                                    }
                                    var userCertsFiltered = res.reduce(function (userCertsAllowed, certificate) {
                                        if ((certificate.CertType === "FirmaRemota" && ViDMobileUserAllowFirmaRemoraCerts) ||
                                            (certificate.CertType === "KeyVault" && ViDMobileUserAllowKeyVaultCerts)) {
                                            userCertsAllowed.push(certificate);
                                        }
                                        return userCertsAllowed;
                                    }, []);

                                    if (ViDMobileUserAllowSwisscomCerts) {
                                        userCertsFiltered.push({
                                            CertType: "Swisscom",
                                            ExpirationDate: "-",
                                            certalias: "Swisscom"
                                        });
                                    }

                                    var certificatesList = userCertsFiltered.map(certificate => {
                                        if (certificate.certalias !== "Swisscom") {
                                            return <ListGroup.Item
                                                key={certificate.GUID + certificate.certalias}
                                                action
                                                variant="light"
                                                onClick={() => this.handleCertSelection(certificate)}
                                            >
                                                <ul style={{ listStyleType: "none" }}>
                                                    <li><b>{t('general.alias')}</b>: {certificate.certalias}</li>
                                                    <li><b>{t('general.type')}</b>: {certificate.CertType}</li>
                                                    <li><b>{t('general.expiration-date')}</b>: {certificate.ExpirationDate}</li>
                                                </ul>
                                            </ListGroup.Item>
                                        }
                                        else {
                                            return <ListGroup.Item
                                                key={certificate.GUID + certificate.certalias}
                                                action
                                                variant="light"
                                                onClick={() => this.handleCertSelection(certificate)}
                                            >
                                                <ul style={{ listStyleType: "none" }} className="list-container-2">
                                                    <li>{certificate.certalias}</li>
                                                </ul>
                                            </ListGroup.Item>
                                        }
                                    });
                                    _this.setState({ userCertificates: res, certificatesList: certificatesList, loading: false });
                                })
                                .catch(function (error) {
                                    _this.setState({
                                        errorType: error.response.status,
                                        errorData: error.response.data,
                                        showError: true,
                                        loading: false
                                    });
                                });
                        })
                        .catch(function (error) {
                            _this.setState({
                                errorType: error.response.status,
                                errorData: error.response.data,
                                showError: true,
                                loading: false
                            });
                        });
                } else {
                    _this.setState({
                        errorType: 'Exception',
                        errorData: t('general.no-user-data'),
                        showError: true,
                        loading: false
                    });
                }
            })
            .catch(function (error) {
                _this.setState({
                    errorType: error.response.status,
                    errorData: error.response.data,
                    showError: true,
                    loading: false
                });
            });
    }

    loadUserData() {
        const _this = this;
        const { oauthTokenB64 } = this.state;


        var config = {
            headers: {
                'Authorization': 'Bearer ' + oauthTokenB64
            }
        };
        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true, loading: false });
                        _this.startTimer();
                    } else {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true,
                            loading: false
                        });
                    }
                    reject(error);
                });
        });
    }

    getUserCertificates() {
        const _this = this;
        const { t } = this.props;
        var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };
        const { userData, userCertificatesAuth } = this.state;

        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileusers/' + userData.legalid + '/certs', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({
                            sessionExpired: true,
                            loading: false
                        });
                        _this.startTimer();
                    }
                    if (error.response.status === 404) {
                        if(!userCertificatesAuth || (!userCertificatesAuth.Swisscom && !userCertificatesAuth.Autofirma)){
                            _this.setState({certificatesList: <p>{t('documents.cert-type-selection-empty')}</p>});
                            return;
                        }
                        if(userCertificatesAuth.Swisscom || userCertificatesAuth.Autofirma)
                            return resolve([]);
                    }

                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        loading: false
                    });

                    reject(error);
                });
        });
    }

    loadViDMobileUserCertificateTypes() {
        const _this = this;
        var config = { headers: { 'Authorization': 'Bearer ' + this.state.oauthTokenB64 } };

        return new Promise(function (resolve, reject) {
            axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/certstype', config)
                .then(res => {
                    resolve(res.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({
                            sessionExpired: true,
                            loading: false
                        });
                        _this.startTimer();
                    } else {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true,
                            loading: false
                        });
                    }
                    reject(error);
                });
        });
    };

    handleCertSelection(certificate) {
        var showKeyVaultModal = false;
        var showFirmaRemotaModal = false;
        var showSwisscomModal = false;

        if (certificate.CertType === "KeyVault" && certificate.AuthenticationType === "PIN") {
            showKeyVaultModal = true;
        }
        if (certificate.CertType === "KeyVault" && certificate.AuthenticationType === "SMS") {
            showKeyVaultModal = true;
            this.keyVaultSendOTP(certificate);
        }
        if (certificate.CertType === "FirmaRemota" && certificate.AuthenticationType === "APP") {
            showFirmaRemotaModal = true;
        }
        if (certificate.CertType === "FirmaRemota" && certificate.AuthenticationType === "SMS") {
            showFirmaRemotaModal = true;
            this.firmaRemotaSendOTP();
        }
        if (certificate.CertType === "Swisscom") {
            showSwisscomModal = true;
        }

        this.setState({
            showKeyVaultModal: showKeyVaultModal,
            showFirmaRemotaModal: showFirmaRemotaModal,
            showSignModal: false,
            certificateSelected: certificate,
            showSwisscomModal: showSwisscomModal
        });
    }

    getViDMobileUserGUIDFromOauthToken() {
        const { oauthTokenB64 } = this.state;
        var tokenParts = oauthTokenB64.split(".");
        var jsonTokenInfo = atob(tokenParts[1]);
        var tokenInfo = JSON.parse(jsonTokenInfo);
        return tokenInfo.sub;
    }

    handleSessionExpiredError(status) {
        if (status === 401) {
            /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
            this.setState({ sessionExpired: true });
            this.startTimer();
        }
    }

    handleGenericError(error) {
        this.handleSessionExpiredError(error.response.status);

        if (error.response.status !== 401) {
            this.setState({
                errorType: error.response.status,
                errorData: error.response.data,
                showError: true
            });
        }
    }

    firmaRemotaSendOTP() {
        var _this = this;
        const { oauthTokenB64 } = this.state;
        var ViDMobileUserGUID = this.getViDMobileUserGUIDFromOauthToken();

        putPrivSignature(generateFirmaRemotaOTPUri(ViDMobileUserGUID), "", oauthTokenB64)
            .then(res => {
                //firmaRemota SMS OTP sent
            })
            .catch(function (error) {
                _this.handleGenericError(error);
            });
    }

    keyVaultSendOTP(certificate) {
        var _this = this;
      const { oauthTokenB64 } = this.state;

      postPrivSignature(generateKeyVaultSendOTPUri(certificate.GUID), '', oauthTokenB64)
        .then(res => {
          //keyVault SMS OTP sent
        })
        .catch(function (error) {
            _this.handleGenericError(error);
        });
    }

    handleClose() {
        this.setState({
            showSignModal: false,
            showRejectModal: false,
            showKeyVaultModal: false,
            showFirmaRemotaModal: false,
            showRedTrustModal: false,
            showSoftwareModal: false,
            loadingSignatureProcess: false,
            certificateSoftwarePin: '',
            certificateKeyVaultPin: '',
            certificateFirmaRemotaPin: '',
            certificateRedTrustPin: '',
            showSwisscomModal: false,
            showSwisscomConfirmationModal: false,
            loadingSwisscomCheck: false,
            loadingSwisscomRequest: false
        })
    }

    getOauthViDMobileCode() {
        const {certificateSelected, user, clientId} = this.state;
        const {location} = this.props;
        var _this = this;
        var currentScope = certificateSelected.CertType === "KeyVault" ? 'vidmobilecert\\keyvault' : 'vidmobilecert\\firmaremota';
        var authorizationHeader = btoa(clientId + ':');

        return new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                url: getVidCloudMgmtUrl() + '/oauth/code',
                data: {
                    username: user + '\\' + certificateSelected.GUID,
                    password: location.state.password,
                    grant_type: "authorization_code",
                    scope: currentScope
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: { 'Authorization': 'Basic ' + authorizationHeader }
            })
                .then(res => {
                    resolve(res);
                })
                .catch(function (error) {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        showKeyVaultModal: false,
                        showFirmaRemotaModal: false,
                        loadingSignatureProcess: false
                    });
                    reject(error);
                });
        });
    }

    getOauthViDMobileCodeSwisscom() {
        const {user, clientId, swisscomRequestID} = this.state;
        const {location} = this.props;
        var _this = this;
        var authorizationHeader = btoa(clientId + ':');

        return new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                url: getVidCloudMgmtUrl() + '/oauth/code',
                data: {
                    username: user + '\\' + swisscomRequestID,
                    password: location.state.password,
                    grant_type: "authorization_code",
                    scope: "vidmobilecert\\swisscom"
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                crossDomain: true,
                headers: { 'Authorization': 'Basic ' + authorizationHeader }
            })
                .then(res => {
                    resolve(res);
                })
                .catch(function (error) {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        showKeyVaultModal: false,
                        showFirmaRemotaModal: false,
                        loadingSignatureProcess: false
                    });
                    reject(error);
                });
        });
    }


    firmaRemotaOpenSession(_, __, ___, certificatePin) {
        const _this = this;
        const { certificateSelected, oauthTokenB64, redirectUri, state } = this.state;
        _this.setState({ loadingSignatureProcess: true, showFirmaRemotaModal: false });

        const dataJson = {
            OTP: certificatePin
        };

        this.getOauthViDMobileCode()
            .then(res => {
                if (res) {
                    var accessToken = res.data.access_token;
                    putManagementSignature(generateFirmaRemotaSessionUri(), dataJson, oauthTokenB64)
                        .then(res => {
                            _this.setState({
                                sessionOpened: true,
                                certificateFirmaRemotaPin: '',
                                loadingSignatureProcess: false
                            });
                            var redirectURL = redirectUri + '?code=' + accessToken + '&state=' + state + '&certguid=' + certificateSelected.GUID;
                            setTimeout(() => {
                                window.location.href = redirectURL;
                            }, 3000);
                            _this.cleanStorage();
                        })
                        .catch(function (error) {
                            if (error.response) {
                                _this.setState({
                                    errorType: error.response.status,
                                    errorData: error.response.data,
                                    showError: true,
                                    certificateFirmaRemotaPin: '',
                                    loadingSignatureProcess: false
                                });
                            } else {
                                _this.setState({
                                    errorType: 'Undefined',
                                    errorData: 'Network error',
                                    showError: true,
                                    certificateFirmaRemotaPin: '',
                                    loadingSignatureProcess: false
                                });
                            }

                        });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        certificateFirmaRemotaPin: '',
                        loadingSignatureProcess: false
                    });
                } else {
                    _this.setState({
                        errorType: 'Undefined',
                        errorData: 'Network error',
                        showError: true,
                        certificateFirmaRemotaPin: '',
                        loadingSignatureProcess: false
                    });
                }
            });
    }

    keyVaultOpenSession(_, __, ___, certificatePin) {
        const _this = this;
        const { certificateSelected, oauthTokenB64, redirectUri, state } = this.state;
        _this.setState({ loadingSignatureProcess: true, showKeyVaultModal: false });

        this.getOauthViDMobileCode()
            .then(res => {
                if (res) {
                    const dataJson = {
                      OTP: certificatePin,
                    };
                    var accessToken = res.data.access_token;
                    putManagementSignature(generateKeyVaultOpenSessionUri(certificateSelected.GUID), dataJson, oauthTokenB64)
                      .then(res => {
                          _this.setState({
                            sessionOpened: true,
                            certificateKeyVaultPin: '',
                            loadingSignatureProcess: false
                          });
                          var redirectURL = redirectUri + '?code=' + accessToken + '&state=' + state + '&certguid=' + certificateSelected.GUID;
                          setTimeout(() => {
                              window.location.href = redirectURL;
                          }, 3000);
                          _this.cleanStorage();
                      })
                      .catch(function (error) {
                          if (error.response) {
                            _this.setState({
                                errorType: error.response.status,
                                errorData: error.response.data,
                                showError: true,
                                certificateKeyVaultPin: '',
                                loadingSignatureProcess: false
                            });
                          } else {
                              _this.setState({
                                  errorType: 'Undefined',
                                  errorData: 'Network error',
                                  showError: true,
                                  certificateKeyVaultPin: '',
                                  loadingSignatureProcess: false
                              });
                          }
                      });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    _this.setState({
                        errorType: error.response.status,
                        errorData: error.response.data,
                        showError: true,
                        certificateKeyVaultPin: '',
                        loadingSignatureProcess: false
                    });
                } else {
                    _this.setState({
                        errorType: 'Undefined',
                        errorData: 'Network error',
                        showError: true,
                        certificateKeyVaultPin: '',
                        loadingSignatureProcess: false
                    });
                }
            });
    };

    signDocSwisscom() {
        const _this = this;
        const { oauthTokenB64, hash, hashalgo } = this.state;

        const data= {
            Hash: hash,
            HashAlgorithm: hashalgo
        };

        postPrivSignature(generateSwisscomSignHashUri(),data, oauthTokenB64)
            .then(res => {
                var swisscomReturnObj = res.data;
                var isUrlDefined = swisscomReturnObj.RedirectURL ? true : false;

                if (isUrlDefined) {
                    Utils.openTab(swisscomReturnObj.RedirectURL);
                }

                _this.setState({ swisscomRedirectURL: swisscomReturnObj.RedirectURL, swisscomRequestID: swisscomReturnObj.RequestID, loadingSwisscomRequest: false });
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    } else {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true,
                            showSwisscomConfirmationModal: false,
                            showSwisscomModal: false,
                            loadingSwisscomCheck: false,
                            loadingSwisscomRequest: false
                        });
                    }
                }
                else {
                    _this.setState({
                        errorType: 'Undefined',
                        errorData: 'Network error',
                        showError: true,
                        showSwisscomConfirmationModal: false,
                        showSwisscomModal: false,
                        loadingSwisscomCheck: false
                    });
                }

            });
    }

    completeSwisscomSignature() {
        const _this = this;
        const { t } = this.props;
        const { oauthTokenB64, redirectUri, state, swisscomRequestID } = this.state;
        
        postPrivSignature(generateSwisscomCompleteSignatureHashUri(swisscomRequestID), "", oauthTokenB64)
            .then(res => {
                this.getOauthViDMobileCodeSwisscom()
                    .then(res => {
                        if (res) {
                            var accessToken = res.data.access_token;
                            _this.setState({ loadingSwisscomCheck: false, showSwisscomConfirmationModal: false });
                            var redirectURL = redirectUri + '?code=' + accessToken + '&state=' + state + '&requestid=' + swisscomRequestID;
                            setTimeout(() => {
                                window.location.href = redirectURL;
                            }, 3000);
                            _this.cleanStorage();
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            _this.setState({
                                errorType: error.response.status,
                                errorData: error.response.data,
                                showError: true,
                                showSwisscomConfirmationModal: false,
                                showSwisscomModal: false,
                                loadingSwisscomCheck: false
                            });
                        }
                        else {
                            _this.setState({
                                errorType: 'Undefined',
                                errorData: 'Network error',
                                showError: true,
                                showSwisscomConfirmationModal: false,
                                showSwisscomModal: false,
                                loadingSwisscomCheck: false
                            });
                        }
                    });

            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                        _this.setState({ sessionExpired: true });
                        _this.startTimer();
                    } else if (error.response.status === 409) {
                        _this.setState({
                            loadingSwisscomCheck: false,
                            swisscomError: t('documents.swisscom-error')
                        });
                    } else {
                        _this.setState({
                            errorType: error.response.status,
                            errorData: error.response.data,
                            showError: true,
                            showSwisscomConfirmationModal: false,
                            showSwisscomModal: false,
                            loadingSwisscomCheck: false
                        });
                    }
                }
                else {
                    _this.setState({
                        errorType: 'Undefined',
                        errorData: 'Network error',
                        showError: true,
                        showSwisscomConfirmationModal: false,
                        showSwisscomModal: false,
                        loadingSwisscomCheck: false
                    });
                }
            });
    }
    
    doLogOut() {
        this.stopTimer();
        this.setState({ redirect: true })
    }

    cleanStorage() {
        sessionStorage.clear();
    }

    render() {
        const { t } = this.props;
        const { loading, certificatesList, showKeyVaultModal, showFirmaRemotaModal, certificateSelected, showError,
            errorType, errorData, sessionExpired, redirect, sessionOpened, loadingSignatureProcess, language, time } = this.state;
        var timeText = language === 'es' ? 'Hace ' + time + ' minutos' : language === 'en' ? time + ' minutes ago' : language === 'ca' ? 'Fa ' + time + ' minuts' : 'Hace ' + time + ' minutos';

        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        return (
            <div>
                <header className="App-header">
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
                        <Navbar.Brand>
                            <img
                                src={require('../../assets/images/logo_vidsigner_white.png')}
                                height="35"
                                width="auto"
                                alt="Validated ID logo"
                            />
                        </Navbar.Brand>
                    </Navbar>
                </header>
                {sessionOpened &&
                    <Toast
                        onClose={() => this.setState({ sessionOpened: false })}
                        show={sessionOpened}
                        animation={true}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.exit')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.exit-text')}</Toast.Body>
                    </Toast>
                }
                {showError &&
                    <Toast
                        onClose={() => this.setState({ showError: false })}
                        show={showError}
                        animation={true}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 11
                        }}
                    >
                        <Toast.Header
                            closeButton={true}
                        >
                            <strong className="mr-auto">{t('general.error')} {errorType}</strong>
                        </Toast.Header>
                        <Toast.Body>{errorData}</Toast.Body>
                    </Toast>
                }
                {sessionExpired &&
                    <div className="toast-container">
                        <Toast
                            show={sessionExpired}
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                margin: '0 auto',
                                backgroundColor: 'rgba(255,255,255,1)',
                                border: 'none',
                                zIndex: 10
                            }}
                        >
                            <Toast.Header
                                closeButton={false}
                            >
                                <strong className="mr-auto">{t('general.notification')}</strong>
                                <small>{timeText}</small>
                            </Toast.Header>
                            <Toast.Body>
                                <p>{t('general.session-expired')}</p>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    id="sign-all-docs"
                                    onClick={() => this.doLogOut()}
                                >
                                    {t('general.accept')}
                                </Button>
                            </Toast.Body>
                        </Toast>
                    </div>
                }
                <Jumbotron>
                    <h1 style={{ paddingTop: '2rem', paddingBottom: '0.5rem' }}>{t('certificates.certificate-selection')}</h1>
                    <p style={{ paddingBottom: '2rem', paddingTop: '0.5rem' }}>
                        {t('certificates.certificate-selection-text')}
                    </p>
                </Jumbotron>
                {loading &&
                    <LoadingCircle centerVertically={true} />
                }
                {!loading &&
                    <div className="certificates-auth-container">
                        {certificatesList}
                        <SigningProcessModal
                            showModal={loadingSignatureProcess}
                            showProcess={false} />

                        <KeyVaultSignature
                            show={showKeyVaultModal}
                            onClose={this.handleClose}
                            onSuccess={this.keyVaultOpenSession}
                            certificateSelected={certificateSelected} />
                        
                        <FirmaRemotaSignature
                            show={showFirmaRemotaModal}
                            onClose={this.handleClose}
                            onSuccess={this.firmaRemotaOpenSession}
                            certificateSelected={certificateSelected} />

                        <SwisscomSignature
                            parentState={this.state}
                            onClose={this.handleClose}
                            onSuccessSignDocSelection={() => this.setState({
                                showSwisscomModal: false,
                                showSwisscomConfirmationModal: true,
                                loadingSwisscomRequest: true
                            }, () => this.signDocSwisscom())}
                            onSuccess={() => this.setState({
                                loadingSwisscomCheck: true,
                                swisscomError: ''
                            },
                                () => this.completeSwisscomSignature())} />
                    </div>
                }
            </div>
        );
    }

    componentDidMount() {
        ThemeService.setTheme();

        sessionStorage.setItem('authCerts', "true");
        this.loadViDMobileUserCertificateList();
    }

}

export default withTranslation()(AuthCerts);