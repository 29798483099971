import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as EmptyDocsIcon } from '../../assets/icons/empty-docs-icon.svg';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './EmptyDocsPanel.css';

const EmptyDocsPanel = ({ onClickGetPendingDocuments, isApprovals }) => {
    const { t } = useTranslation('translation');
    return (
        <div className="icon-container">
            <EmptyDocsIcon />
            <h1 className="empty-docs-title">{isApprovals ? t('approvals.noPending') : t('documents.noPending')}</h1>
            <Button
                type="button"
                onClick={onClickGetPendingDocuments}
            >
                {t('documents.reload')}
            </Button>
        </div>
    )
}

EmptyDocsPanel.propTypes = {
    onClickGetPendingDocuments: PropTypes.func.isRequired,
    isApprovals: PropTypes.bool
}

export default EmptyDocsPanel;