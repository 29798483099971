import React, { useState, useEffect } from 'react';
import './AdvancedSearch.css';
import { useTranslation } from "react-i18next";
import FilterCertificateMsg from "../../FilterCertificateMsg/FilterCertificateMsg";

import { MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import DocumentsActionTypes from '../../../../redux/documents/documents.types';
import { INITIAL_FILTERS } from '../../../../redux/documents/documents.reducer';

export const AdvancedSearch = ({ updateState, previousCertSelected, showCertificate, showSenderNameColumn, showTag }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { documents: signatures, filters } = useSelector(state => state.documents);

  const { Tag: tag, CertificateGuid: certificate, From: from, To: to, FileName: text, Sender: senderName } = filters;
  const [searchText, setSearchText] = useState(text);

  const emptyFilters = senderName.length === 0 && tag.length === 0 && certificate.length === 0 && from.length === 0 && to.length === 0 && text.length === 0;

  const setFilters = (newFilters) => {
    dispatch({ 
      type: DocumentsActionTypes.MODIFY_FILTERS, 
      payload: newFilters
    });
  }

  const formatDate = (value) => {
    const dateComponents = value.split("-");
    const parsedDate = `${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]}`;

    return parsedDate;
  }
  
  const reverseFormatDate = (value) => {
    const dateComponents = value.split("/");
    const parsedDate = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`;

    return parsedDate;
  }

  useEffect(() => {
    if (!searchText || searchText === text) return;
    const delayDebounceFn = setTimeout(() => {
      setFilters({
        ...filters,
        FileName: searchText
      });
    }, 750);

    return () => clearTimeout(delayDebounceFn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const resetTableState = (clearCertificate = false) => {
    const isAdvancedSearching = tag.length || certificate.length || from.length || to.length;

    let newTableState = {
      currentPageTable: 1,
      pendingSignatures: signatures,
      inputSearch: "",
      isAdvancedSearching,
    };

    if (clearCertificate) newTableState.docCertSelected = null;
    updateState(newTableState);
  };

  const clearSelectedDocs = () => {
    let newTableState = {};
    newTableState.docsSelected = [];
    newTableState.docsSelectedGUI = [];
    sessionStorage.removeItem('docsSelectedGUIString');

    updateState(newTableState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === "To" || name === "From") {
      newValue = formatDate(value);
    }

    if (name === "certificate") updateState({ docCertSelected: value });

    if (name !== "FileName") {
      setFilters({
        ...filters,
        [name]: newValue
      });
      return;
    }
  };

  const handleClear = () => {
    setFilters(INITIAL_FILTERS);
    resetTableState(true);
  };

  const getTagsList = () => {
    const tagsList = [];
    signatures.forEach((doc) => {
      const certGUID = doc.SignerDTO?.CentralizedCertificateInfo?.Guid;
      if (doc.Tag !== "") {
        if ((certificate && certificate === certGUID) || certificate === "") {
          const splittedTags = doc.Tag.split(",").filter((el) => {
            return !tagsList.includes(el);
          });
          tagsList.push(...splittedTags);
        }
      }
    });
    return tagsList;
  }
  
  const getSendersList = () => {
    const senders = [];
    signatures.forEach((doc) => {
      const currentSenderName = doc.SenderName;
      if (currentSenderName !== "" && !senders.includes(currentSenderName)) {
        senders.push(currentSenderName);
      }
    });

    return senders;
  }

  const getCertificatesList = () => {
    const certList = [];
    signatures.forEach((doc) => {
      const cert = doc.SignerDTO?.CentralizedCertificateInfo;
      if (cert && !certList.find(previousEl => previousEl.Guid === cert.Guid) && (cert.Guid && cert.Alias)) {
        certList.push(cert);
      }
    });
    return certList;
  }

  const tags = getTagsList();
  const certificates = getCertificatesList();
  const senders = getSendersList();

  const getCertificateAlias = (guid) => {
    const certificateObject = certificates.find((certificateEl) => certificateEl.Guid === guid);

    return certificateObject?.Alias || t('documents.document-cert-undef');
  };

  useEffect(() => {
    sessionStorage.setItem("advancedFilters", JSON.stringify(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const today = new Date();
  const maxDate = today.toISOString().split('T')[0];

  let sixtyFiveDaysBefore = new Date();
  sixtyFiveDaysBefore.setDate(today.getDate() - 65);
  const minDate = sixtyFiveDaysBefore.toISOString().split('T')[0];

  return (
    <div className="advanced-search-container">
      <div className="search-container">
        <MdSearch className="search-icon" />
        <input onChange={(e) => setSearchText(e.target.value)} name="FileName" type="text" placeholder={t('documents.search')} value={searchText} />
      </div>
      <div className="advanced-form">
        <div className="inputs-container">
          {
            showCertificate && <div className="input-container">
              <label htmlFor="CertificateGuid" className="input-label">{t('documents.cert-type')}:</label>
              <select value={certificate} disabled={certificate.length > 0} name="CertificateGuid" onChange={handleChange}>
                <option value="">{t('documents.select-one')}</option>
                <option value="">{t('documents.document-cert-undef')}</option>
                {
                  certificates.map((cert, i) => {
                    return <option value={cert.Guid} key={i}>{cert.Alias}</option>;
                  })
                }
              </select>
            </div>}
          {showTag && <div className="input-container">
            <label htmlFor="tag" className="input-label">{t('documents.tag')}:</label>
            <select value={tag} name="Tag" onChange={handleChange}>
              <option value="">{t('documents.select-one')}</option>
              <option value="">{t('documents.document-cert-undef')}</option>
              {
                tags.map((tag, i) => {
                  return <option value={tag} key={i}>{tag}</option>;
                })
              }
            </select>
          </div>}
          {showSenderNameColumn && <div className="input-container">
            <label htmlFor="senderName" className="input-label">{t('documents.sender')}:</label>
            <select value={senderName} name="Sender" onChange={handleChange}>
              <option value="">{t('documents.select-one')}</option>
              <option value="">{t('documents.document-cert-undef')}</option>
              {
                senders.map((sender, i) => {
                  return <option value={sender} key={i}>{sender}</option>;
                })
              }
            </select>
          </div>}
          <div className="input-container">
            <label htmlFor="from">{t('documents.from')}:</label>
            <input type="date" name="From" min={minDate} max={to === "" ? maxDate : to} onChange={handleChange} value={reverseFormatDate(from)} />
          </div>
          <div className="input-container">
            <label htmlFor="to">{t('documents.to')}:</label>
            <input type="date" name="To" min={from === "" ? minDate : from} max={maxDate} onChange={handleChange} value={reverseFormatDate(to)} />
          </div>
        </div>
      </div>
      <div className="search-buttons-container">
        <button onClick={handleClear}>{t('documents.clear')}</button>
      </div>
      {
        !emptyFilters && <>
          <hr />
          <div className="filter-list-container">
            {
              text.length > 0 && <FilterCertificateMsg close={() => {
                setFilters({
                  ...filters,
                  FileName: ""
                });
              }} filter={t('documents.text') + ": " + text} />
            }
            {
              certificate.length > 0 && <FilterCertificateMsg close={() => {
                setFilters({
                  ...filters,
                  CertificateGuid: ""
                });
                updateState({
                  docCertSelected: null
                });
                clearSelectedDocs();
              }} filter={t('documents.cert-type') + ": " + getCertificateAlias(certificate)} />
            }
            {
              tag.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                Tag: ""
              })} filter={`${t('documents.tag')}: ${tag === "Unspecified" ? t('documents.document-cert-undef') : tag}`} />
            }
            {
              senderName.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                Sender: ""
              })} filter={`${t('documents.sender')}: ${senderName === "Unspecified" ? t('documents.document-cert-undef') : senderName}`} />
            }
            {
              from.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                From: ""
              })} filter={t('documents.from') + ": " + from} />
            }
            {
              to.length > 0 && <FilterCertificateMsg close={() => setFilters({
                ...filters,
                To: ""
              })} filter={t('documents.to') + ": " + to} />
            }
          </div>
        </>
      }

    </div>
  )
};
