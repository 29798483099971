const Utils = {
    openTab: function(url) {
        if(this.isSafari()){
            // Create link in memory
            var a = window.document.createElement("a");
            a.target = '_blank';
            a.href = url;

            // Dispatch fake click
            var e = window.document.createEvent("MouseEvents");
            e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        }else{
            window.open(url, "_blank");
        }
    },
    appendScript: function (scriptToAppend) {
        const script = document.createElement("script");
        script.src = scriptToAppend;
        script.async = true;
        document.body.appendChild(script);
    },
    /**
     * This will work with any version of Safari across all devices: Mac, iPhone, iPod, iPad.
     */
    isSafari: function(){
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') === -1 &&
               navigator.userAgent.indexOf('FxiOS') === -1;
    },

    removeItemFromSelectedDocsInSessionByGuid: function(signerGuid) {
        const docsSelectedSignerGuidsString = sessionStorage.getItem('docsSelectedSignerGUIString');
        
        if (docsSelectedSignerGuidsString !== null && docsSelectedSignerGuidsString !== "" )    {
            let docsSelectedSignerGuid = docsSelectedSignerGuidsString.split(",");
            
            docsSelectedSignerGuid = docsSelectedSignerGuid.filter(function(docSignerGuid) {
                return docSignerGuid !== signerGuid
            })
        
            sessionStorage.setItem('docsSelectedSignerGUIString', docsSelectedSignerGuid.toString());
        }
    },

    getViDMobileUserGUIDFromOauthToken: function(oauthTokenB64) {
        const tokenParts = oauthTokenB64.split(".");
        const jsonTokenInfo = atob(tokenParts[1]);
        const tokenInfo = JSON.parse(jsonTokenInfo);
        return tokenInfo.sub;
    }
};

export default Utils;
