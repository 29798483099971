import React from 'react'
import { Modal, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingCircle from '../LoadingCircle';

const AutofirmaSignature = ({ show, onClose, loadingSignatureProcessAutofirma, currentDataAutofirma, docsSelected, autofirmaPercentage  }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('documents.autofirma-title')}</Modal.Title>
            </Modal.Header>
            {
                loadingSignatureProcessAutofirma ? <>
                    <p style={{ textAlign: 'center' }}>{t('general.sign-process')} <span>{currentDataAutofirma.index != null ? (currentDataAutofirma.index + 1) : 1}/{docsSelected.length}</span></p>
                    <ProgressBar variant="success" now={autofirmaPercentage} label={`${autofirmaPercentage}%`} />
                </> : <>
                    <p>{t('documents.autofirma-text')}</p>
                    <LoadingCircle />
                </>
            }
        </Modal>
    )
}

export default AutofirmaSignature