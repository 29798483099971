import React, { useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

const FirmaRemotaCertificates = ({ parentState, onAuthorizeFirmaremotaCertificate, onUnauthorizeFirmaremotaCertificate }) => {
    const { t } = useTranslation();
    const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;
    const {
        certificatesOrdered: { FirmaRemota },
        FirmaRemotaIsAuthorized
    } = parentState;

    const firmaRemotaColumns = [
        {
            dataField: 'certalias',
            text: t('certificates.certificate'),
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerClass1',
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'ExpirationDate',
            text: t('certificates.certificate-date'),
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerClass2',
            style: { verticalAlign: 'middle' }
        }
    ];

    const [FRuser, setFRuser] = useState('');
    const [FRpassword, setFRpassword] = useState('');
    
    return (
        <div className="table-container">
            {!FirmaRemotaIsAuthorized &&
                <div>
                    {certsNotAvailable}
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="firma-remota-user">{t('general.user')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="text"
                            aria-label="Default"
                            aria-describedby="firma-remota-user"
                            value={FRuser}
                            onChange={e => setFRuser(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="firma-remota-password">{t('general.password')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="password"
                            aria-label="Default"
                            aria-describedby="firma-remota-password"
                            value={FRpassword}
                            onChange={e => setFRpassword(e.target.value)}
                        />
                    </InputGroup>
                    <Button
                        type="button"
                        id="authorizeFR"
                        className="btn btn-primary signature-image"
                        onClick={() => onAuthorizeFirmaremotaCertificate({FRuser, FRpassword})}
                    >
                        {t('general.authorize')}
                    </Button>
                </div>
            }
            {FirmaRemotaIsAuthorized &&
                <div>
                    <ToolkitProvider
                        keyField="GUID"
                        data={FirmaRemota}
                        columns={firmaRemotaColumns}
                    >
                        {
                            props => (
                                <div>
                                    <BootstrapTable
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                    <Button
                        type="button"
                        id="unauthorizeFR"
                        className="btn signature-image reject"
                        onClick={onUnauthorizeFirmaremotaCertificate}
                    >
                        {t('general.unauthorize')}
                    </Button>
                </div>
            }
        </div>
    )
}

export default FirmaRemotaCertificates