import React from 'react';

import { Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import warningIcon from '../../assets/icons/warning.svg';
import checkIcon from '../../assets/icons/check.svg';
import cancelIcon from '../../assets/icons/cancel.svg';

import './CustomModal.css';

export const CustomModal = ({ text, show, handleHide, handleConfirm, children, title, noIcon, disabledButton }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        {!noIcon && <img src={warningIcon} alt="Warning" />}
        <Modal.Title>{title || t('documents.warning')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-disclaimer">
        <p>{text}</p>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <div className="centered-buttons-disclaimer">
          <Button
            onClick={handleHide}
          >
            <img src={cancelIcon} alt="Cancel" />
            {t('general.cancel')}
          </Button>
          <Button
            disabled={disabledButton}
            onClick={handleConfirm}
            style={{ marginLeft: 20 }}
          >
            <img src={checkIcon} alt="Confirm" />
            {t('general.confirm')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}