import axios from 'axios';
import DocumentsActionTypes from './documents.types';
import { getVidCloudPrivUrl } from '../../services/environment/environmentService';

export const addDocumentsToList = (docsList) => ({
	type: DocumentsActionTypes.ADD_DOCUMENTS_TO_LIST,
	payload: docsList
});

const getQuerystring = object => {
	const querystring = Object.keys(object).map(function(key) {
	  return key + '=' + encodeURIComponent(object[key]);
	}).join('&');
  
	return querystring;
  }
  
export const getPendingDocumentsRequest = (filters, paginationMeta, ordering) => {
	const oauthTokenB64 = sessionStorage.getItem('oauthTokenB64');
  
	const config = { headers: { 'Authorization': 'Bearer ' + oauthTokenB64 } };
  
	const composedParams = {
	  ...filters,
	  ...paginationMeta,
	  ...ordering,
	};
  
	const requestQuerystring = getQuerystring(composedParams);
  
	return axios.get(`${getVidCloudPrivUrl()}/pendingsignatures/paginated?${requestQuerystring}`, config);
}