import React from "react";
import { Toast } from "react-bootstrap";

const CustomToast = ({ handleClose, show, title, message, delay }) => {
  return (
    <Toast
      onClose={handleClose}
      show={show}
      animation={true}
      delay={delay}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        margin: '0 auto',
        backgroundColor: 'rgba(255,255,255,1)',
        border: 'none',
        zIndex: 15
      }}
    >
      <Toast.Header
        closeButton={true}
      >
        <strong className="mr-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  )
};

export default CustomToast;