import React, { useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

const NebulaCertificates = ({ parentState, onChangeFormControl, onSendCredentialsAndGetAuthenticators, onSendAuthenticator, onSendChallenge, onUnauthorizeNebulaCertificate, authenticators }) => {
    const { t } = useTranslation();
    const certsNotAvailable = <p>{t('certificates.certificate-not-available')}</p>;
    const {
        certificatesOrdered: { Nebula },
        nebulaStep1,
        nebulaStep2,
        NBauthenticator,
        nebulaStep3
    } = parentState;

    const nebulaColumns = [
        {
            dataField: 'certalias',
            text: t('certificates.certificate'),
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerClass1',
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'ExpirationDate',
            text: t('certificates.certificate-date'),
            align: 'left',
            sort: true,
            headerAlign: 'left',
            headerClasses: 'headerClass2',
            style: { verticalAlign: 'middle' }
        }
    ];

    const [NBuser, setNBuser] = useState('');
    const [NBpassword, setNBpassword] = useState('');
    const [NBClient, setNBClient] = useState('');
    const [NBChallenge, setNBChallenge] = useState('');

    return (
        <div className="table-container">
            {Nebula.length === 0 && nebulaStep1 &&
                <div>
                    {certsNotAvailable}
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="nebula-user">{t('general.user')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="text"
                            aria-label="Default"
                            aria-describedby="nebula-user"
                            value={NBuser}
                            onChange={e => setNBuser(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="nebula-password">{t('general.password')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="password"
                            aria-label="Default"
                            aria-describedby="nebula-password"
                            value={NBpassword}
                            onChange={e => setNBpassword(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="nebula-clientId">{t('general.clientId')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="text"
                            aria-label="Default"
                            aria-describedby="nebula-clientId"
                            value={NBClient}
                            onChange={e => setNBClient(e.target.value)}
                        />
                    </InputGroup>
                    <Button
                        disabled={!NBuser || !NBpassword || !NBClient}
                        type="button"
                        className="btn btn-primary signature-image"
                        onClick={() => onSendCredentialsAndGetAuthenticators({NBuser, NBpassword, NBClient})}
                    >
                        {t('general.authorize')}
                    </Button>
                </div>
            }
            {Nebula.length === 0 && nebulaStep2 &&
                <div>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>{t('documents.nebula-authenticator')}</Form.Label>
                            <Form.Control
                                as="select"
                                value={NBauthenticator}
                                onChange={e => onChangeFormControl("NBauthenticator", e.target.value)}>
                                <option value="">{t('documents.select-one-authenticator')}</option>
                                {
                                    authenticators.map((auth, i) => {
                                        return <option value={auth} key={i}>{auth}</option>;
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Button
                            disabled={!NBauthenticator}
                            type="button"
                            className="btn btn-primary signature-image"
                            onClick={onSendAuthenticator}
                        >
                            {t('general.authorize')}
                        </Button>
                    </Form>
                </div>
            }
            {Nebula.length === 0 && nebulaStep3 &&
                <div>
                    {t('documents.nebula-otp')} {NBauthenticator}
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                id="nebula-user">{t('general.otp')}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            required
                            type="text"
                            aria-label="Default"
                            aria-describedby="nebula-challenge"
                            value={NBChallenge}
                            onChange={e => setNBChallenge(e.target.value)}
                        />
                    </InputGroup>
                    <Button
                        disabled={!NBChallenge}
                        type="button"
                        id="authorizeNB"
                        className="btn btn-primary signature-image"
                        onClick={() => onSendChallenge({NBChallenge})}
                    >
                        {t('general.authorize')}
                    </Button>
                </div>
            }
            {Nebula.length > 0 &&
                <div>
                    <ToolkitProvider
                        keyField="GUID"
                        data={Nebula}
                        columns={nebulaColumns}
                    >
                        {
                            props => (
                                <div>
                                    <BootstrapTable
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                    <div className="rt-buttons-container">
                        <Button
                            type="button"
                            id="unauthorizeNB"
                            className="btn signature-image reject"
                            onClick={onUnauthorizeNebulaCertificate}
                        >
                            {t('general.unauthorize')}
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default NebulaCertificates