import React, {Component} from 'react';
import './Header.css';
import {Nav, Navbar, NavDropdown, Toast} from 'react-bootstrap';
import {Link, Redirect} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import i18n from "../../config/i18n";
import {MdAccountCircle} from "react-icons/md";
import LanguageSwitch from './../LanguageSwitch/LanguageSwitch';
import { getVidCloudMgmtUrl, getVidCloudPrivUrl } from '../../services/environment/environmentService';
import axios from 'axios';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oauthTokenB64: sessionStorage.getItem('oauthTokenB64'),
            userToken: sessionStorage.getItem('userToken'),
            urlReturn: sessionStorage.getItem('urlReturn'),
            user: '',
            redirect: false,
            redirecting: false,
            showApprovals: false,
        };
    }

    doLogOut(){
        var _this = this;
        const {userToken, urlReturn} = this.state;
        if(typeof userToken !== "undefined" && userToken === "true" && typeof urlReturn !== "undefined" && urlReturn !== "null" && urlReturn !== ""){
            _this.setState({
                redirecting: true
            });
            setTimeout(() => {
                window.location.href = atob(urlReturn);
            }, 3000);
        }
        else {
            this.setState({redirect: true});
        }
        sessionStorage.clear();
    }

    loadUserData() {
      var _this = this;
        const { oauthTokenB64 } = this.state;

        var config = {
            headers: {
                'Authorization': 'Bearer ' + oauthTokenB64
            }
        };

        axios.get(getVidCloudMgmtUrl() + '/vidmobileuser/', config)
        .then(res => {
            const { userid } = res.data;
            _this.setState({ user: userid });
        })
        .catch(function (error) {
            if (error?.response?.status === 401) {
                /**SESIÓN CADUCADA REDIRECCIONAR AL LOGIN CON UN ALERT*/
                _this.setState({ sessionExpired: true });
            }
        });
    }

    changeLanguageHandler(event) {
        i18n.changeLanguage(event);
        if (window.location.pathname === "/documents"){
            window.location.reload();
        }
    }

    getViDMobileUserGUIDFromOauthToken() {
        const { oauthTokenB64 } = this.state;
        var tokenParts = oauthTokenB64.split(".");
        var jsonTokenInfo = atob(tokenParts[1]);
        var tokenInfo = JSON.parse(jsonTokenInfo);
        return tokenInfo.sub;
    }

    getApprovals() {
        const config = {
            headers: {
                'Authorization': 'Bearer ' + this.state.oauthTokenB64
            }
        };

        const body = {
            Terms: { vidmobileuser: this.getViDMobileUserGUIDFromOauthToken() }
        };

        axios.post(getVidCloudPrivUrl() + '/approvaldocuments/searches/', body, config)
        .then((documentsResponse) => {
            this.setState({
                showApprovals: documentsResponse.data.length > 0,
            });
        })
        .catch((error) => console.log)
    }

    render() {
        const {redirect, redirecting, user, showApprovals} = this.state;
        const {t} = this.props;
        if (redirect) {
            return <Redirect to={{
                pathname: '/'
            }}
            />;
        }

        const inDocumentsScreen = window.location.pathname === "/documents";
        const inApprovalsScreen = window.location.pathname === "/approvals";
      
        return (
            <>
                <header className="App-header">
                    {redirecting &&
                    <Toast
                        onClose={() => this.setState({redirecting: false})}
                        show={redirecting}
                        delay={3000}
                        autohide
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            margin: '0 auto',
                            backgroundColor: 'rgba(255,255,255,1)',
                            border: 'none',
                            zIndex: 10
                        }}
                    >
                        <Toast.Header
                            closeButton={false}
                        >
                            <strong className="mr-auto">{t('general.exit')}</strong>
                        </Toast.Header>
                        <Toast.Body>{t('general.exit-text')}</Toast.Body>
                    </Toast>
                    }
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
                        <Navbar.Brand
                            href="/documents"
                            className="custom-img"
                        >                      
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto"></Nav>
                            <Nav>
                                <Nav.Link
                                    as={Link}
                                    onClick={() => window.location.replace('/documents')}
                                    className={inDocumentsScreen ? 'active-option' : ' '}
                                    to={{
                                        pathname: "/documents",
                                        state: {
                                            oauthTokenB64: this.state.oauthTokenB64
                                        }
                                    }}
                                >
                                    {t('documents.pending')}
                                </Nav.Link>
                            {  showApprovals &&  <Nav.Link
                                    as={Link}
                                    onClick={() => window.location.replace('/approvals')}
                                    className={inApprovalsScreen ? 'active-option' : ' '}
                                    to={{
                                        pathname: "/approvals",
                                        state: {
                                            oauthTokenB64: this.state.oauthTokenB64
                                        }
                                    }}
                                >
                                    {t('approvals.pending')}
                                </Nav.Link>}
                                <NavDropdown title={<div style={{display:'inline'}}><MdAccountCircle className="user-icon ml-5"/> {user}</div>} id="collasible-nav-dropdown" drop="down">
                                    <NavDropdown.Item
                                        as={Link}
                                        to={{
                                            pathname: "/profile",
                                            state: {
                                                oauthTokenB64: this.state.oauthTokenB64
                                            }
                                        }}
                                    >
                                        {t('header.profile-update')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={{
                                            pathname: "/certificates",
                                            state: {
                                                oauthTokenB64: this.state.oauthTokenB64
                                            }
                                        }}
                                    >
                                        {t('header.profile-certificates')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item
                                        onClick={()=>this.doLogOut()}
                                    >
                                        {t('header.profile-logout')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <LanguageSwitch/>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </header>
                <div className='header-extension'>
                    <span>Powered by Validated ID</span>
                </div>
            </>
        );
    }

    componentDidMount() {
        this.loadUserData();
        this.getApprovals();
    }
}

export default withTranslation()(Header);