import React, { useEffect, useState } from 'react';
import { getVidCloudMgmtUrl, getVidCloudPrivUrl } from "../../services/environment/environmentService";

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { CLIENT_ID, CLIENT_SECRET } from '../../services/vid-config';
import { DEFAULT_APPEARANCE_CONFIG } from '../../config/appearance';
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';

export const Auth = () => {
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const getAuthMethod = () => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);

    let state = querystring.get("state");

    if (!state) {
      setError(t("vid-chain-login.not-found-code") + ". URL: " + window.location.href);
      return;
    }

    state = state.split("%3d")[0];
    const decodedState = atob(state);

    return decodedState;
  }

  useEffect(() => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);
    const code = querystring.get("code");
    const errorDescription = querystring.get("error_description");

    if (!code) {
      if (errorDescription.includes('The request is not allowed.')) {
        setError(t("vid-chain-login.request-failed") + ". URL: " + window.location.href);
        return;
      }
      setError(t("vid-chain-login.not-found-code") + ". URL: " + window.location.href);
      return;
    }

    const authMethod = getAuthMethod();

    const env = "PRO";
    sessionStorage.setItem("environment", env);
    localStorage.setItem("environment", env);

    const vidCloudMgmtUrl = getVidCloudMgmtUrl(env);
    const vidCloudPrivUrl = getVidCloudPrivUrl(env);
    const authorizationHeader = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

    axios.post(`${vidCloudMgmtUrl}/auth/code/${authMethod} `, { Code: code }, {
      headers: { 'Authorization': 'Basic ' + authorizationHeader }
    })
      .then((response) => {
        let accessMethod;
        if (authMethod.includes("GOOGLE")) accessMethod = "GOOGLE_ACCOUNT"; 
        if (authMethod.includes("MS")) accessMethod = "MS_ACCOUNT"; 
        if (authMethod.includes("VIDWALLET")) accessMethod = "VID_WALLET"; 
        sessionStorage.setItem("AccessMethod", accessMethod);
        const token = response?.data.Token;
        sessionStorage.setItem("oauthTokenB64", token);
        const tokenParts = token.split(".");
        const jsonTokenInfo = atob(tokenParts[1]);
        const tokenInfo = JSON.parse(jsonTokenInfo);

        const userGUID = tokenInfo.sub;

        const appearanceReq = `${vidCloudPrivUrl}/vidmobileuser/${userGUID}/appearance`;

        const config = {
          headers: {
            Authorization: "Bearer " + token
          }
        }

        axios.get(appearanceReq, config)
          .then(resAppearance => {
            sessionStorage.setItem("appearanceConf", btoa(JSON.stringify(resAppearance.data)));
            window.location.href = '/documents';

            localStorage.removeItem('AUTH');

          })
          .catch((error) => {
            // If error 409, apply default appearance config
            if (error.response.status === 409 && error.response.data.includes("is assigned to multiple subscriptions with different appearance configuration")) {
              sessionStorage.setItem("appearanceConf", btoa(JSON.stringify(DEFAULT_APPEARANCE_CONFIG)));
              window.location.href = '/documents';

              localStorage.removeItem('AUTH');
            }
          })
      })
      .catch((e) => {
        const statusCode = e?.response?.status;
        if (statusCode === 400) {
          setError(t("vid-chain-login.invalid-code"));
          return;
        }
        if (statusCode === 401) {
          setError(t("vid-chain-login.inexistent-user"));
          return;
        }
        setError(t("vid-chain-login.uncontrolled-error"));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        const authMethod = getAuthMethod();

        if (authMethod === "CENTRALIZED_LOGIN") {
          window.location.href = '/';
          return;
        }
        
        window.location.href = '/';
      }, 3000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <>
    {error ? error : <LoadingCircle centerVertically />}
  </>;
}