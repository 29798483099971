import ApprovalsActionTypes from './approvals.types';

const INITIAL_STATE = {
  filteredApprovals: [],
  approvals: [],
};

const approvalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ApprovalsActionTypes.ADD_APPROVALS_TO_LIST:
      if (!action.payload) return state;
      return {
        approvals: action.payload,
        filteredApprovals: action.payload,
      };
    case ApprovalsActionTypes.FILTER_APPROVALS:
      if (!action.payload) return state;
      return {
        ...state,
        filteredApprovals: action.payload,
      };
    case ApprovalsActionTypes.DELETE_APPROVAL_FROM_LIST:
      const approvalGuid = action.payload;

      const newApprovals = state.approvals.filter((approval) => {
        return approval.DocGuid !== approvalGuid;
      });

      return {
        filteredApprovals: newApprovals,
        approvals: newApprovals,
      };
    case ApprovalsActionTypes.DELETE_MULTIPLE_APPROVALS:
      const approvalGuids = action.payload;

      const newApprovalsList = state.approvals.filter((approval) => {
        return !approvalGuids.includes(approval.DocGuid);
      });

      return {
        ...state,
        filteredApprovals: newApprovalsList,
      };
    case ApprovalsActionTypes.RESET_FILTERS:
      return {
        ...state,
        filteredDocuments: state.documents,
      };
    default:
      return state;
  }
};

export default approvalReducer;
